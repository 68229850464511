import axios from "../services/axiosService.js";
import CommonService from "./commonService.js";

class MemberService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = process.env.REACT_APP_WEB_API_ENDPOINT + process.env.REACT_APP_WEB_API_FOLDER + '/';
    this.apiUrlUS = process.env.REACT_APP_WEB_API_ENDPOINT_US + process.env.REACT_APP_WEB_API_FOLDER + '/';
    this.apiUrlEU = process.env.REACT_APP_WEB_API_ENDPOINT_EU + process.env.REACT_APP_WEB_API_FOLDER + '/';
  }
  // Api call to Get all the members based on a projectId
  // Input: Project UUID
  //OutPut: Members added to the project
  GetProjectDetailById = async (ProjectiId = String, dataHostingLocation = String) => {
    try {
      if (dataHostingLocation === "1") {
        // US location
        return await axios
          .get(
            this.apiUrlUS +
            "Member/GetMembersDetailsByProject?project_UUID=" +
            ProjectiId +
            "",
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Accept: "application/json",
              },
            }
          )
          .then((data) => {
            const datareturn = data?.data;
            return datareturn;
          });
      }
      else if (dataHostingLocation === "2") {
        // EU location
        return await axios
          .get(
            this.apiUrlEU +
            "Member/GetMembersDetailsByProject?project_UUID=" +
            ProjectiId +
            "",
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Accept: "application/json",
              },
            }
          )
          .then((data) => {
            const datareturn = data?.data;
            return datareturn;
          });
      }
      else {
        return await axios
          .get(
            this.apiUrl +
            "Member/GetMembersDetailsByProject?project_UUID=" +
            ProjectiId +
            "",
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Accept: "application/json",
              },
            }
          )
          .then((data) => {
            const datareturn = data?.data;
            return datareturn;
          });
      }
    } catch (error) {
      this.commonService.logException(
        error,
        "Member Component",
        "GetMembersDetailsByProject"
      );

    }
  };

}

export default MemberService;
