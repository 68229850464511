import PropTypes from 'prop-types';
import "../../../App.scss";
import MotifProgressLoader from "@ey-xd/motif-react/ProgressLoader";

const LoaderComponent = ({show, fullscreen=true}) => {
  return <MotifProgressLoader show={show} fullscreen={fullscreen} />;
};

LoaderComponent.propTypes = {
  show: PropTypes.bool,
  fullscreen: PropTypes.bool
};

export default LoaderComponent;
