import React from "react";
import PropTypes from "prop-types";
import MotifButton from '@ey-xd/motif-react/Button';
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import "../modals/GenericModal.scss";

export default function GenericModal(props) {

    const { title, body, customCloseButtonTitle, rightButtonVariant, leftButtonVariant, rightButtonSize, leftButtonSize, leftButtonText, rightButtonText, show, closeHandler, leftButtonHandler, rightButtonHandler, footerCssClass, headerIcon, rightButtonDisabled, modalType } = props.modalProperties;


    return (
        <MotifModal
            show={show}
            onClose={() => closeHandler()}
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Custom Close Button aria-label',
                    title: customCloseButtonTitle
                }}
                className={modalType && modalType === "alert" ? "alert-header" : ""}
            >
                {
                    (modalType && modalType === "basic") ?
                        <>
                            {headerIcon || ''}{title}
                        </> :
                        <div className='closeSymbolContainer'>
                            {headerIcon || ''}<div className="alert-title">{title}</div>
                        </div>
                }
            </MotifModalHeader>
            <MotifModalBody>
                <React.Fragment key=".0">
                    {body}
                </React.Fragment>
            </MotifModalBody>
            <MotifModalFooter className={footerCssClass}>
                <React.Fragment key=".0">
                    <MotifButton
                        onClick={() => leftButtonHandler()}
                        size={leftButtonSize}
                        type="button"
                        variant={leftButtonVariant}
                        style={{ marginRight: '10px' }}
                    >
                        {leftButtonText}
                    </MotifButton>
                    <MotifButton
                        onClick={() => rightButtonHandler()}
                        size={rightButtonSize}
                        type="button"
                        variant={rightButtonVariant}
                        disabled={rightButtonDisabled}
                    >
                        {rightButtonText}
                    </MotifButton>
                </React.Fragment>
            </MotifModalFooter>
        </MotifModal>
    )
}

GenericModal.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    customCloseButtonTitle: PropTypes.string.isRequired,
    rightButtonVariant: PropTypes.string.isRequired,
    leftButtonVariant: PropTypes.string.isRequired,
    rightButtonSize: PropTypes.string.isRequired,
    leftButtonSize: PropTypes.string.isRequired,
    leftButtonText: PropTypes.string,
    rightButtonText: PropTypes.string,
    show: PropTypes.bool,
    closeHandler: PropTypes.func,
    leftButtonHandler: PropTypes.func,
    rightButtonHandler: PropTypes.func,
    footerCssClass: PropTypes.string,
    headerIcon: PropTypes.object
}