import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PromptLibraryService from '../../../services/promptLibraryService'; 
import LoaderComponent from '../../molecules/loader/LoaderComponent';
import './PersonalPromptList.scss';
import { setSystemPromptsClient as setSystemPromptsClientToStore, setSystemPromptsTest as setSystemPromptsTestToStore } from "../../../auth/promptSlice";
import { MotifButton } from "@ey-xd/motif-react";
import MotifToast from '@ey-xd/motif-react/Toast';
import iconCheckCircleSuccess from '../../../assets/images/check-circle.svg';
import EditSystemPrompt from '../../molecules/forms/editSystemPrompt/EditSystemPrompt';

function SystemTab() {
    const [systemPrompts, setSystemPrompts] = useState();
    const [showEditSystemPrompt, setShowEditSystemPrompt] = useState(false);
    const [showPromptSaveSuccessToast, setShowPromptSaveSuccessToast] = useState(false);
    const [systemPromptsClient, setSystemPromptsClient] = useState([]);
    const [systemPromptsTest, setSystemPromptsTest] = useState([]);
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    
    const promptLibraryService = new PromptLibraryService();

    useEffect(() => {
        if(user) {
            getSystemPrompt(user.username)
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Function to get System prompts associated with user
     */
    

    const getSystemPrompt = async (userId) => {
        setIsLoadingInProgress(true);
        const vSystemPrompts = await promptLibraryService.getSystemPrompt(userId); 
        setSystemPrompts(vSystemPrompts);
        if((vSystemPrompts===undefined)||(vSystemPrompts===null)||(vSystemPrompts===""))
        {
            setSystemPromptsClient("No Prompts avilable!");
            dispatch(setSystemPromptsClientToStore({
                systemPromptsClient: "No Prompts avilable!"
            }));
            setSystemPromptsTest("No Prompts avilable!");
            dispatch(setSystemPromptsTestToStore({
                systemPromptsTest: "No Prompts avilable!"
            }));

            setIsLoadingInProgress(false);
            return;
        }
        

        setSystemPromptsClient(vSystemPrompts[0].promptBody);
        setSystemPromptsTest(vSystemPrompts[1].promptBody);
        dispatch(setSystemPromptsClientToStore({
            systemPromptsClient: vSystemPrompts[0].promptBody
        }));
        dispatch(setSystemPromptsTestToStore({
            systemPromptsTest: vSystemPrompts[1].promptBody
        }));
        setIsLoadingInProgress(false);
    }

    const handlePromptSave = async (updatedPrompt) => {
        setIsLoadingInProgress(true);
        const response = await promptLibraryService.saveSystemPrompt(updatedPrompt);
        if(user) {
            getSystemPrompt(user.username);
        }
        setIsLoadingInProgress(false);
        setShowEditSystemPrompt(false);
        if(response?.status === 200) {
            setShowPromptSaveSuccessToast(true);
        }
    }

    return (
        <React.Fragment>
            <LoaderComponent show={isLoadingInProgress} />
            <div className="prompt-details">
              
                <div className="system-prompt-container">
                {
                    showEditSystemPrompt ?
                    (<EditSystemPrompt 
                        systemPrompts={systemPrompts}
                        setShowEditSystemPrompt={setShowEditSystemPrompt}
                        handlePromptSave={handlePromptSave}
                    />) :
                    <React.Fragment>
                        <div className="view-edit-prompt">
                            <div className="field-label">Client projects</div>
                            <div className="field-value">{systemPromptsClient}</div>
                        </div>

                        <div className="view-edit-prompt">
                            <div className="field-label">Testing projects</div>
                            <div className="field-value">{systemPromptsTest}</div>
                        </div>
                            
                        <div className='genericFooter'>
                            <div className='footerInner alignEnd'>
                                <MotifButton onClick={() => setShowEditSystemPrompt(true)} className='' type="button" variant="secondary" disabled={!systemPrompts}>
                                    Edit
                                </MotifButton>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    showPromptSaveSuccessToast &&
                    <div className="systemToast">
                    <MotifToast
                        variant="success"
                        onClose={() => {
                            setShowPromptSaveSuccessToast(false);
                        }}
                        icons={{toast:<img src={iconCheckCircleSuccess} alt="Success" />}}
                        className='systemNotifyToast'
                    >
                        Prompt saved successfully
                    </MotifToast>
                    </div>
                }
                </div>
            </div>
        </React.Fragment>
    );
}

export default SystemTab;