export const DefaultNewCentralPromptModel = {
    promptName: "",
    promptType: "central",
    promptCategory: null,
    tableType: "",
    prompts: [
        {
            promptMethod: "lineDescription",
            promptMethodName: "Line Description",
            promptBody: ""
        },
        {
            promptMethod: "lineQuestions",
            promptMethodName: "Line Questions",
            promptBody: ""
        },
        {
            promptMethod: "answerLineQuestions",
            promptMethodName: "Line Answers",
            promptBody: ""
        },
        {
            promptMethod: "lineCommentary",
            promptMethodName: "Line Commentary",
            promptBody: ""
        },
        {
            promptMethod: "tableCommentary",
            promptMethodName: "Table Commentary",
            promptBody: ""
        }
    ],
    advancedSetting: {
        additionalContext: [
            {
                name: "fullTable",
                disable: true
            }
        ],
        vectorstore: {},
        pipelineSettings: {}
    },
    createdBy: ""
}