import React, { useEffect, useState } from "react";
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/TabNavigation';
import { useNavigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
    

export default function PromptLibrary() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("");
    const { userGroupNamePlatform } = useSelector(state => state.user);
    const systemTab = {
        "id": "systemTab",
        "Title": "System"
    };
    const centralTab = {
        "id": "centralTab",
        "Title": "Central"
    };
    const personalTab = {
        "id": "personalTab",
        "Title": "Personal" 
    };
    
    let promptLibraryTabs = [];



    

    
        //Check for Platform Admin User
        if(userGroupNamePlatform === "PlatFormAdmin")
            {
                promptLibraryTabs = [systemTab, centralTab, personalTab];
            }
            else
            {
                promptLibraryTabs = [centralTab, personalTab];
            }

            useEffect(() => {

                if(userGroupNamePlatform === "PlatFormAdmin")
                {
                    navigate("system");
                    setActiveTab(systemTab.id);
                }
                else
                {
                    navigate("central");
                    setActiveTab(centralTab.id);
                }
                // eslint-disable-next-line
            }, []);
        

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);

        switch(tabName) {
            case systemTab.id:
                navigate("system");
                break;
            case centralTab.id:
                navigate("central");
                break;
            case personalTab.id:
                navigate("personal");
                break;
            default:
                navigate("central");
                break;
        }
    }

    return (
        <React.Fragment>
            <div className="tab-section" style={{
                    "width":"100%",
                    "flex":"1",
                    "border-top": "0px",
                    "border-right": "0px",
                    "border-left": "0px"
                }}>
                <MotifTabNavigation defaultActiveKey={0} className="tab-nav" style={{"flex":"1"}} variant="alt">
                    {
                        promptLibraryTabs.map((plTab) => 
                            <MotifTabControl style={{"width":"100%", "flex":"1"}} key={plTab.id} id={plTab.id} onClick={() => handleTabClick(plTab.id)}>
                                <span className={activeTab === plTab.id ? "tab-button tab-active" : "tab-button"}>
                                    {plTab.Title}
                                </span>
                            </MotifTabControl>
                        )
                    }
                </MotifTabNavigation>
            </div>
            <div>
                <Outlet />
            </div>
        </React.Fragment>
    )
}