import React from "react";
import { Outlet } from "react-router-dom";

/**
 * Internal imports
 */
import Header from '../../molecules/Header/Header'
import './Layout.scss';
import Toast from "../../molecules/Toast/Toast";

export default function Layout() {

    return (
        <React.Fragment>
            <div>
                <Header />
            </div>
            <div className="individual-page-section">
                <Outlet />
            </div>
            <div>
                <Toast />
            </div>
        </React.Fragment>
    )
}