import store from '../store';
import { setAuthenticated } from '../auth/authSlice';
import { setUser } from '../auth/userSlice';

export const acquireTokens = async (msalInstance) => {
    
    const request = {
        scopes: [process.env.APP_API_CLIENTID ? process.env.APP_API_CLIENTID : process.env.REACT_APP_API_CLIENTID],
        account: msalInstance.getActiveAccount(),
    };

    let tokenResponse = ""
    if (msalInstance.getActiveAccount()) {
        tokenResponse = await msalInstance.acquireTokenSilent(request);
    }

    msalInstance.setActiveAccount(tokenResponse.account);

    store.dispatch(setAuthenticated());

    store.dispatch(setUser({
        user: tokenResponse.account
    }))

    return {
        activeAccount: msalInstance.getActiveAccount()
    }
};
