import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifDropdown, { MotifDropdownItem } from '@ey-xd/motif-react/Dropdown';
import { MotifTooltip } from "@ey-xd/motif-react";
import MotifToast from '@ey-xd/motif-react/Toast';
import MotifInlineMessage from '@ey-xd/motif-react/InlineMessage';

import TableService from '../../../services/tableService';
import sparksIcon from '../../../assets/images/sparks.svg';
import thumbsUpIcon from '../../../assets/images/thumbs-up.svg';
import thumbsDownIcon from '../../../assets/images/thumbs-down.svg';
import thumbsUpFilledIcon from '../../../assets/images/thumbs-up-filled.svg';
import thumbsDownFilledIcon from '../../../assets/images/thumbs-down-filled.svg';
import ellipsesIcon from '../../../assets/images/more-horiz.svg';
import copyIcon from '../../../assets/images/copy-icon.svg';
import arrowDownIcon from '../../../assets/images/nav-arrow-down.svg';
import iconArrowUp from '../../../assets/images/nav-arrow-up.svg';
import iconSuccess from "../../../assets/images/check-circle.svg";
import brokenLink from '../../../assets/images/broken-link.svg';
import iconWarningInfoCircle from '../../../assets/images/warning_info_circle.svg';

import './Commentry.scss';
import Utils from "../../../utils/Utils";

export default function Commentary(props) {

    const [isCopyDropDownOpen, setIsCopyDropDownOpen] = useState(false);
    const [isDocsDropDownOpen, setIsDocsDropDownOpen] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState(false);
    const [commentaryLiked, setCommentaryLiked] = useState(0);
    const { tableAnalysis } = useSelector(state => state.analysis);
    const tableService = new TableService();
    const { activeProject } = useSelector(state => state.user);
    const vExcelFileFormats = ["xls", "xlsx", "xlsm", "xlsb"];
    const utils = new Utils();

    useEffect(() => {
        setCommentaryLiked(tableAnalysis?.feedback);
    }, [tableAnalysis])

    const copyText = () => {
        const text = document.getElementById("commentary-para").innerText;
        navigator.clipboard.writeText(text).then(() => {
            setShowToastMessage(true);
        }).catch(err => {
            console.error("Could not copy text: ", err);
        });
        setIsCopyDropDownOpen(false);
    }

    const handleLikeClick = async () => {
        const data = {
            "projectId": activeProject.project_UUID,
            "tableId": props.selectedTableData.id,
            "rowId": "NA",
            "queueMethod": "tableAnalysis",
            "status": "finished",
            "feedback": commentaryLiked === 1 ? 0 : 1
        };
        const feedback = await tableService.submitFeedback(data);
        if (feedback) {
            if (commentaryLiked === 1) {
                setCommentaryLiked(0);
            }
            else {
                setCommentaryLiked(1);
            }
        }
        else {
            // Case of error.
        }
    }

    const handleDisLikeClick = async () => {
        const data = {
            "projectId": activeProject.project_UUID,
            "tableId": props.selectedTableData.id,
            "rowId": "NA",
            "queueMethod": "tableAnalysis",
            "status": "finished",
            "feedback": commentaryLiked === -1 ? 0 : -1
        };
        const feedback = await tableService.submitFeedback(data);
        if (feedback) {
            if (commentaryLiked === -1) {
                setCommentaryLiked(0);
            }
            else {
                setCommentaryLiked(-1);
            }
        }
        else {
            // Case of error.
        }
    }

    const toggleDocsDropdown = () => {
        isDocsDropDownOpen ? setIsDocsDropDownOpen(false) : setIsDocsDropDownOpen(true);
    }

    const toggleCopyDropdown = () => {
        isCopyDropDownOpen ? setIsCopyDropDownOpen(false) : setIsCopyDropDownOpen(true);
    }

    /**
 * Function to get source reference
 * @param {*} vDocRef
 */
    const getSourceReference = (vDocRef) => {
        if (isExcelFileFormat(vDocRef.source)) {
            return getExcelSourceReference(vDocRef);
        } else {
            return getNonExcelSourceReference(vDocRef);
        }
    };

    /**
* Function to check if excel file format or not
* @param {*} vSource
* @returns
*/
    const isExcelFileFormat = (vSource) => {
        let vIsExcelFileFormat = false;
        if (vSource) {
            const isFileFormatFound = vExcelFileFormats.find(
                (element) => vSource.toLowerCase().indexOf(element) !== -1
            );
            if (isFileFormatFound) {
                vIsExcelFileFormat = true;
            }
        }
        return vIsExcelFileFormat;
    };

    /**
 * Function to get excel source reference
 * @param {*} vDocRef
 */
    const getExcelSourceReference = (vDocRef) => {
        let vSourceRef = "";
        if (vDocRef?.sheet_name) {
            vSourceRef = vDocRef.source + ", Sheet Name - " + vDocRef.sheet_name;
        } else {
            vSourceRef = vDocRef.source;
        }
        return vSourceRef;
    };

    /**
* Function to get Non excel source references
* @param {*} vDocRef
* @returns
*/
    const getNonExcelSourceReference = (vDocRef) => {
        let vSourceRef = "";
        if (vDocRef?.page) {
            vSourceRef = vDocRef.source + ", Page Number - " + vDocRef.page;
        } else {
            vSourceRef = vDocRef.source;
        }
        return vSourceRef;
    };

    return (
        <>
            <MotifInlineMessage
                variant="warning"
                icons={
                    {
                        message: <img src={iconWarningInfoCircle} className="iconWarningInfoCircle" alt="Warning" />
                    }
                }
                className="msgAIContainer"
            >
                <span className="msgAI">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
            </MotifInlineMessage>
            <div className="container">
                <div className="content">
                    <div className="top-bar">
                        <div><span className="page-title">Commentary</span></div>
                        <div>
                            <MotifIconButton className="top-icons btnGenAI">
                                <MotifTooltip placement="bottom" contentClassName="tooltipWithoutClose" hideCloseButton={true} allowClick={false} trigger={
                                    <img src={sparksIcon} alt='AI' />
                                }>
                                    AI Generated
                                </MotifTooltip>
                            </MotifIconButton>
                            <MotifIconButton className="top-icons btnLike" onClick={handleLikeClick}>
                                <MotifTooltip placement="bottom" contentClassName="tooltipWithoutClose" hideCloseButton={true} allowClick={false} trigger={
                                    commentaryLiked === 1 ? <img src={thumbsUpFilledIcon} alt='Liked' /> : <img src={thumbsUpIcon} alt='Like' />
                                }>
                                    Like
                                </MotifTooltip>
                            </MotifIconButton>
                            <MotifIconButton className="top-icons btnDislike" onClick={handleDisLikeClick}>
                                <MotifTooltip placement="bottom" contentClassName="tooltipWithoutClose" hideCloseButton={true} allowClick={false} trigger={
                                    commentaryLiked === -1 ? <img src={thumbsDownFilledIcon} alt='Disliked' /> : <img src={thumbsDownIcon} alt='Dislike' />
                                }>
                                    Dislike
                                </MotifTooltip>
                            </MotifIconButton>
                            <MotifDropdown
                                open={isCopyDropDownOpen}
                                placement="bottom-right"
                                handleClickOutside={() => setIsCopyDropDownOpen(false)}
                                trigger={
                                    <MotifIconButton onClick={toggleCopyDropdown} className="top-icons btnMore">
                                        <img src={ellipsesIcon} alt='more' />
                                    </MotifIconButton>
                                }
                                className="dropdownMore"
                            >
                                <div style={{ display: 'flex' }}>
                                    <img src={copyIcon} className="copy-icon" alt='copy' />
                                    <MotifDropdownItem onClick={copyText}>Copy</MotifDropdownItem>
                                </div>
                            </MotifDropdown>
                        </div>
                    </div>
                    {tableAnalysis?.answer && 
                        <p id="commentary-para"
                            dangerouslySetInnerHTML={{
                                __html: utils.markdownToHtml(tableAnalysis.answer),
                            }}
                        ></p>
                    }
                    {
                        showToastMessage &&
                        <div className="toast-div-commentry">
                        <MotifToast
                            onClose={function noRefCheck() { setShowToastMessage(false); }}
                            variant="success"
                            icons={{ toast: <img src={iconSuccess} alt="Success" /> }}
                        >
                            Copied
                        </MotifToast>
                    </div>
                    }
                    <div className="view-docs-div">
                        <MotifDropdown
                            className="dropdownViewSrcDocs"
                            open={isDocsDropDownOpen}
                            placement="top-placement"
                            handleClickOutside={() => setIsDocsDropDownOpen(false)}
                            trigger={
                                <MotifButton className='view-docs-btn' onClick={toggleDocsDropdown}
                                    disabled={!(
                                        tableAnalysis?.doc_reference &&
                                        (tableAnalysis?.doc_reference.length > 0)
                                    )}
                                >
                                    <span className='viewSrcDocsText'>View Source Documents</span>
                                    <img className="iconViewSrcDocs" src={isDocsDropDownOpen ? iconArrowUp : arrowDownIcon} alt='View Source Dcouments' />
                                </MotifButton>
                            }
                        >
                            {tableAnalysis?.doc_reference && tableAnalysis?.doc_reference.map((vRef, vIndex) =>
                                <div key={vIndex} className={`sourceDocList ${vRef.IsDeleted ? "deleted" : "active"}`} >
                                    <MotifDropdownItem onClick={() => { }}>{getSourceReference(vRef)}</MotifDropdownItem>
                                    {vRef.IsDeleted && <MotifTooltip key={vIndex + 1}
                                        trigger={<img src={brokenLink} alt="Deleted" />}
                                        placement="left"
                                        contentClassName=""
                                        allowHover={true}
                                        allowClick={false}
                                        hideCloseButton={true}
                                    >
                                        Document deleted
                                    </MotifTooltip>}
                                </div>
                            )}
                        </MotifDropdown>
                    </div>
                </div>
            </div>
        </>
    )
}