import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../auth/authConfig';
import { setShowToast } from "../auth/toastSlice";
import store from "../store";

let msalInstance;
if (!msalInstance) {
   msalInstance = new PublicClientApplication(msalConfig);
   (async () => { await msalInstance.initialize(); })()
}

axios.interceptors.request.use(

     async (config) =>{
         const tokenResponse = await msalInstance.acquireTokenSilent({
            scopes: [ process.env.REACT_APP_API_CLIENTID ],
         });
         if (tokenResponse && tokenResponse.accessToken) {
            config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
         }
         return config;
     },
     (error)=>{
        return Promise.reject(error);
     }

);

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    store.dispatch(
      setShowToast({
        showToast: true,
        toastVariant: "error",
      })
    );
    return Promise.reject(error);
  }
);
export default axios;