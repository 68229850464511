/**
 * Redux slice for toast reducers with actions
 * 1. 
 * Created on 17-09-2024
 */
import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
    name: "toast",
    initialState: {
        showToast: false,
        toastVariant: null,
        toastDismissed: false,
    },
    reducers: {
        setShowToast: (state, action) => {
            state.showToast = action.payload.showToast;
            state.toastVariant = action.payload.toastVariant;
            state.toastDismissed=false;
        },
        dismissToast : (state)=>{
            state.showToast=false;
            state.toastDismissed=true;
        },
    },
});

export const { setShowToast } = toastSlice.actions;
export default toastSlice.reducer;