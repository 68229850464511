/**
 * Common Service class for common functions
 * Modified on 09-08-2024
 */

import { appInsights } from "./appInsights.js";
import axios from './axiosService.js';

import tableTypesData from '../assets/data/tableTypes.json';

class CommonService {
  constructor() {
    this.apiPointer = process.env.REACT_APP_PLATFORM_API_POINTER;
    this.apiEndpoint = process.env.REACT_APP_PLATFORM_API_ENDPOINT;
    this.apiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;

    //  Headers
    this.headers = { "Content-type": "application/json; charset=UTF-8" };

    //  API url methods
    this.apiUrl = {
      getReferences: '/getGroupKey'
    }

    //  List of API endpoints to point to local mock data
    this.mockApiUrl = [
      // 
    ]
  }
  logException(error, componentName, methodName, requestObject=null, userId=null) {
    appInsights.trackException({
      error: error,
      properties: {
        Component: componentName,
        Method: methodName,
        UserId: userId,
        payload: requestObject,
        errorData:error?.response?.data
      },
    });
  }

  /**
   * Function to get Table type references
   * @returns 
   */
  async getReferences() {
      if (this.apiPointer.toLowerCase() === 'local') {
          return Promise.resolve(tableTypesData);
      } else {
          const vUrl = this.apiEndpoint + this.apiFolder + this.apiUrl.getReferences;
          const vParams = {
              method: "get",
              url: vUrl,
              headers: this.headers,
              data: JSON.stringify({})
          };
          const response = await axios.request(vParams).catch((error) => {
              this.commonService.logException(
                  error,
                  "Common Service",
                  "Get References"
              );
          });
          return response?.data;
      }
  }
}
export default CommonService;
