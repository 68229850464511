import { createSlice } from "@reduxjs/toolkit";

const analysisSlice = createSlice({
    name: "analysis",
    initialState: {
        lineAnalysis: null,
        tableAnalysis: null,
    },
    reducers: {
        setLineAnalysis: (state, action) => {
            state.lineAnalysis = action.payload.lineAnalysis;
        },
        setTableAnalysis: (state, action) => {
            state.tableAnalysis = action.payload.tableAnalysis;
        }
    }
})

export const { setLineAnalysis,setTableAnalysis } = analysisSlice.actions;
export default analysisSlice.reducer;