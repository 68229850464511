import axios from "../services/axiosService.js";
import CommonService from "../services/commonService.js";

class ProjectService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = process.env.REACT_APP_WEB_API_ENDPOINT + process.env.REACT_APP_WEB_API_FOLDER + '/';
    this.apiEndpoint = process.env.REACT_APP_PLATFORM_API_ENDPOINT;
    this.apiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;
  }

  /**
   * This method gets all the projects  to.
   * @returns Array of projects
   */
  GetProjectDetails = async () => {
    try {
      return await axios
        .get(
          this.apiUrl +
          "Project/GetProjectDetails?project_UUID=ALL",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "projectService",
        "GetProjectDetails"
      );

    }
  }

  /**
   * This method gets all the projects user has access to.
   * @returns Array of projects
   */
  GetProjectMasterData = async (email, country) => {
    const payload = {
      email: email,
      country: country
    }
    try {
      return await axios
        .post(
          this.apiUrl +
          "Project/GetProjectMasterData",
          payload
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "projectService",
        "GetProjectMasterData"
      );
    }
  }

  /**
   * This method gets all the projects user has access to.
   * @returns Array of projects
   */
  GetProjectThresholdList = async (userId) => {
    const payload = {
      userId: userId
    }
    try {
      return await axios
        .post(
          this.apiEndpoint + this.apiFolder +
          "/userprojectlist",
          payload
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "projectService",
        "GetProjectThresholdList"
      );
    }
  }

}

export default ProjectService