import axios from "axios";
import CommonService from "./commonService.js";
class PaansService {
  commonService = new CommonService();
  constructor() {
    this.PAANSEndPoint = process.env.REACT_APP_PAANS_CHECK_ENDPOINT;
    this.PAANSAppId = process.env.REACT_APP_PAANSAPPID;
    this.apiUrl =
      process.env.REACT_APP_WEB_API_ENDPOINT +
      process.env.REACT_APP_WEB_API_FOLDER +
      "/";
  }

  /**
     * Function will get PAANS secret from keyvault
     * @param {*} refresh 
     */
  async getPaansSecret() {
    try {
      return await axios
        .get(
          this.apiUrl + "Project/GetKeyVaultValue?keyVaultName=PaansSecret",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Home Component",
        "Get PAANS Secrete in Get project page"
      );
    }
  }

/**
     * Function will get PAANS acknowledgment Status from DDA
     * @param {*} refresh 
*/
  async getPaansAcknowledgeStatus(email) {
    const PAANSAppIdSecret = await this.getPaansSecret();

    return await fetch(this.PAANSEndPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: PAANSAppIdSecret,
      },
      body: JSON.stringify({
        region: "Global",
        language: "EN",
        appId: this.PAANSAppId,
        policyType: 1,
        userId: email,
        includeContent: false,
        getContent: false,
      }),
    }).then((res) => res.json());
  }
}
export default PaansService;
