import PropTypes from "prop-types";
import React, { useState } from "react";
import { MotifLabel, MotifTextArea, MotifButton } from "@ey-xd/motif-react";
import MotifFormField from '@ey-xd/motif-react/FormField';
import "./EditSystemPrompt.scss";

function EditSystemPrompt ({
    systemPrompts,
    setShowEditSystemPrompt,
    handlePromptSave
}) {
    const [saveDisabled] = useState(false);
    const [clientProjectPrompt, setClientProjectPrompt] = useState(systemPrompts[0]?.promptBody);
    const [testingProjectPrompt, setTestingProjectPrompt] = useState(systemPrompts[1]?.promptBody);

    const onClientProjectPromptChange = (event) => {
        setClientProjectPrompt(event.target.value);
    }

    const onTestingProjectPromptChange = (event) => {
        setTestingProjectPrompt(event.target.value);
    }

    const onSaveClick = () => {
        const updatedSystemPrompts = systemPrompts.map((sp) => {
            delete sp.promptName;
            delete sp.promptType;

            return {
                ...sp,
                promptBody: sp.promptCategory === "client" ? clientProjectPrompt : testingProjectPrompt,
                promptMethod: "all"
            }
        });

        let updatedPrompt = {
            promptName: "System Prompts",
            promptType: "system",
            prompts: updatedSystemPrompts
        }
        
        handlePromptSave(updatedPrompt);
    }

    return (
        <div className="editSystemPrompt">
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel'>
                        Client projects
                    </MotifLabel>
                    <MotifTextArea
                        name="clientProjectPrompt"
                        value={clientProjectPrompt}   
                        onChange={(e) => onClientProjectPromptChange(e)} 
                        rows={5}
                    />
                </MotifFormField>  
            </div>
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel'>
                        Testing projects
                    </MotifLabel>
                    <MotifTextArea
                        name="testingProjectPrompt"
                        value={testingProjectPrompt}   
                        onChange={(e) => onTestingProjectPromptChange(e)}  
                        rows={5}
                    />
                </MotifFormField>  
            </div>

            <div className='genericFooter'>
                <div className='footerInner alignEnd'>
                    <MotifButton onClick={onSaveClick} className='' type="button" variant="primary" disabled={saveDisabled}>
                        Save
                    </MotifButton>
                    <MotifButton onClick={()=>{setShowEditSystemPrompt(false)}} className='' type="button" variant="secondary">
                        Cancel
                    </MotifButton>
                </div>
            </div>
        </div>
    );
}

EditSystemPrompt.propTypes = {
    systemPrompts: PropTypes.array,
    setShowEditPersonalPrompt: PropTypes.func, 
    handlePromptSave: PropTypes.func 
}

export default EditSystemPrompt;