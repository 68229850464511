import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        userData: null,
        projects: null,
        searchProjectValue: null,
        activeProject: null,
        activeProjectUserRole: null,
        userGroupName: null,
        userGroupNamePlatform: null,
        thresholdProjects: null,
        platformAdminUsers: null
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user;
        },
        setUserData: (state, action) => {
            state.userData = action.payload.userData;
        },
        setUserProjects: (state, action) => {
            state.projects = action.payload.projects;
        },
        setSearchProjectValue: (state, action) => {
            state.searchProjectValue = action.payload.searchProjectValue;
        },
        setActiveProject: (state, action) => {
            state.activeProject = action.payload.activeProject;
        },
        setActiveProjectUserRole: (state, action) => {
            state.activeProjectUserRole = action.payload.activeProjectUserRole;
        },
        setUserGroupName: (state, action) => {
            state.userGroupName = action.payload.userGroupName;
        },
        setUserGroupNamePlatform: (state, action) => {
            state.userGroupNamePlatform = action.payload.userGroupNamePlatform;
        },
        setProjectThresholds: (state, action) => {
            state.thresholdProjects = action.payload.thresholdProjects;
        },
        setPlatformAdminUsers: (state, action) => {
            state.platformAdminUsers = action.payload.platformAdminUsers;
        },
    }
})

export const {
    setUser,
    setUserData,
    setUserProjects,
    setSearchProjectValue,
    setActiveProject,
    setActiveProjectUserRole,
    setUserGroupName,
    setUserGroupNamePlatform,
    setProjectThresholds,
    setPlatformAdminUsers
} = userSlice.actions;

export default userSlice.reducer;