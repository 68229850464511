import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../auth/authConfig.js";
import CommonService from "./commonService.js";

let msalInstance;
if (!msalInstance) {
  msalInstance = new PublicClientApplication(msalConfig);
  (async () => {
    await msalInstance.initialize();
  })();
}
const allowedDomain = ["graph.microsoft.com"];
class GraphService {
  commonService = new CommonService();
  constructor() {
    this.graphApiBaseUrl = process.env.REACT_APP_GRAPH_API_URL;
    this.accessToken = "";
  }

  // Get latest token from Msal
  async getToken() {
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: [
        "user.read",
        "openid",
        "profile",
        "email",
        "Sites.Read.All",
        "Files.Read.All"
      ],
    });

    this.accessToken = tokenResponse.accessToken;

    return tokenResponse.accessToken;
  }
  
  // Get logged in user Groups
  async getUserDetails() {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/me`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(graphApiEndPoint, requestOptions);
      if (!response.ok) {
        return null;
      }
      return await response.json();
    } catch (error) {
      this.commonService.logException(
        error,
        "Application",
        "Get User Details"
      );
    }
  }
  //Gets user details based on email from Azure
  async getUserDetailsByEmail(emailid) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/users?$filter=mail eq '${emailid}'`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(graphApiEndPoint, requestOptions);
    if (!response.ok) {
      return null;
    }
    return await response.json();
  }

  //Gets user details based on email from Azure
  async getUserDetailsByInput(inputdata) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/users?$filter=mail eq '${inputdata}'`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(graphApiEndPoint, requestOptions);
    if (!response.ok && (response.status === 401)) { return null; }
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    return data;
  }
  
  // Get user group by User id
  async getUserGroupByMail(userId) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/users/${userId}/memberOf`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(graphApiEndPoint, requestOptions);
      if (!response.ok) {
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
  // Get user avatar by User id
  async getUserAvatar(userId) {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/users/${userId}/photo/$value`;
    const requestOptions = {  
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      if (allowedDomain.indexOf(graphApiEndPoint) > -1) {
        return null;
      }
      const Photoresponse = await fetch(graphApiEndPoint, requestOptions);
      if (!Photoresponse.ok) {
        return null;
      }
      return await Photoresponse.blob();
    } catch (error) {
      this.commonService.logException(
        error,
        "Add Member",
        "Get User Avatar"
      );
    }
  }

  // Get platform admin list
  async getPlatformAdminList() {
    await this.getToken();
    const graphApiEndPoint = `${this.graphApiBaseUrl}/groups/${process.env.REACT_APP_GRAPH_API_GROUP_ID}/members`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(graphApiEndPoint, requestOptions);
      if (!response.ok) {
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }

  // Add Other Methods
}

export default GraphService;
