import { configureStore } from "@reduxjs/toolkit";

import axiosMiddleware from "./middlewares/axiosMiddleware";
import authReducer from "./auth/authSlice";
import userReducer from "./auth/userSlice";
import tableReducer from "./auth/tableSlice";
import analysisReducer from "./auth/analysisSlice";
import promptReducer from "./auth/promptSlice";
import toastReducer from "./auth/toastSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        table: tableReducer,
        analysis: analysisReducer,
        prompt: promptReducer,
        toast: toastReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(axiosMiddleware)
})

export default store;