/**
 * Project page of DaaS application
 * opens when project is selected for first time from Dashboard page
 * Created Date: 20/06/2024
 */
import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

/**
 * Motif controls
 */
import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/Button';
import { MotifInput, MotifFormField, MotifErrorMessage, MotifTooltip } from "@ey-xd/motif-react";

/**
 * Internal imports
 */
import './projectThreshold.scss';
import arrow_back from '../../../assets/images/arrow_back.svg';
import help from '../../../assets/images/help-circle.svg';
import ThresholdService from "../../../services/thresholdService.js";
import { setActiveProject } from "../../../auth/userSlice";

const ProjectThreshold = (props) => {

    /**
     * Custom constants
     */

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navToHome = () => {
        navigate('/');
    }

    const [absoluteThreshold, setAbsoluteThreshold] = useState("");
    const [percentageThreshold, setPercentageThreshold] = useState("");
    const [isProceedDisable, setIsProceedDisable] = useState(true);
    const [isMaxChar, setIsMaxChar] = useState(false);
    const { user, activeProject } = useSelector(state => state.user);
    const thresholdService = new ThresholdService();
    let projectId = activeProject.project_UUID;
    let projectName = activeProject.projectName;
    let projectCategory = activeProject.category;
    const projectThreshold = require("../../../model/projectthresholddata.js");
    let projectThresholdData = projectThreshold.ProjectThresholdData;



    function onAbsoluteThresholdChange(e) {
        const regex = /^[0-9\b]+$/;

        if (e.target.value === "") {
            setIsProceedDisable(true);
            setAbsoluteThreshold("");
        }
        else {
            if (regex.test(e.target.value)) {
                setAbsoluteThreshold(e.target.value);
                if (percentageThreshold !== "" && isMaxChar===false) {
                    setIsProceedDisable(false);
                }
            }
            else {
                setAbsoluteThreshold("");
            }
        }
    }

    function onPercentageThresholdChange(e) {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "") {
            setIsProceedDisable(true);
            setPercentageThreshold("");
        }
        else {
            if (regex.test(e.target.value)) {
                setPercentageThreshold(e.target.value);
               
                    if (e.target.value < 5) {
                        setIsMaxChar(true);
                    }
                    else {
                        setIsMaxChar(false);
                        if (absoluteThreshold !== "") {
                        setIsProceedDisable(false);
                        }
                    }
                
            }
            else {
                setPercentageThreshold("");
            }
        }
    }

    async function saveThreshold() {
        const userList = await thresholdService.getProjectDetailUserById(projectId);
        let result = userList?.map((x => ({ 'userId': x.email, 'userRole': x.groupName ? x.groupName : "" })));

        if (result.length > 0) {
            projectThresholdData.name = projectName;
            projectThresholdData.projectId = projectId;
            projectThresholdData.globalThresholdValue = absoluteThreshold;
            projectThresholdData.globalThresholdPercent = percentageThreshold;
            projectThresholdData.createdBy = user?.username;
            projectThresholdData.users = result;
            if (projectCategory === "1") {
                projectThresholdData.projectCategory = "client";
            } else if (projectCategory === "2") {
                projectThresholdData.projectCategory = "testing";
            } else {
                projectThresholdData.projectCategory = "";
            }
            const response = await thresholdService.AddThresholdToProject(projectThresholdData);
            if (response === 201) {
                let vActiveProject = JSON.parse(JSON.stringify(activeProject));
                vActiveProject['globalThresholdValue'] = absoluteThreshold;
                vActiveProject['globalThresholdPercent'] = percentageThreshold;
                dispatch(setActiveProject({
                    activeProject: vActiveProject
                }));
                navToProject();
            }
        }
    }
    const navToProject = () => {
        navigate('/projectDetails');
    }
    return (
        <div className="threshold-content outerSpace">
            <div className="breadcrumbLayer">
                <MotifIconButton
                    aria-label="Click here to go back to Project Dashboard"
                    onClick={() => navToHome()}
                    className='btnBack'
                >
                    <img src={arrow_back} alt='Previous' />
                </MotifIconButton>
                <span className='projectThresholdName'>{projectName}</span>
            </div>
            <div className='threshold-container'>
                <div className="threshold-title">
                    Set Threshold settings for all Tables
                </div>
                <div className="threshold-content">
                    The Threshold set here will be applied across all tables identified in the data
                    book when running the functionality with the intention to exclude rows that
                    are less significant in terms of absolute value or movement.
                </div>
                <div className="threshold-default-title">
                    Default Threshold settings
                </div>
                <div className="threshold-mandatory">
                    (All fields mandatory)
                </div>
                <div>
                    Absolute threshold
                </div>
                <div id="absolutethreshold">
                    <span>
                        <MotifFormField className='field-group'>
                            <MotifInput
                                className="inputThreshold"
                                label="inputabsolutethreshold"
                                onChange={onAbsoluteThresholdChange}
                                type='text'
                                placeholder="Enter Threshold"
                                value={absoluteThreshold}
                            />
                            <MotifTooltip
                                trigger={
                                    <img className="helpTooltipIcon" src={help} alt='icon' />
                                }
                            >
                                <div className='tooltipAbsThresholdTitle'>Absolute Threshold</div>
                                <div className='tooltipAbsThrehsoldBody'>
                                    The number should be written as a whole value <br />
                                    and will apply in absolute terms, e.g. if <br />
                                    100,000 is set as the threshold, this will apply <br />
                                    to both positive and negative values in a table. <br /><br />
                                    The threshold will be set across all periods <br />
                                    presented in a table, e.g. if 100,000 is set as <br />
                                    the threshold, then any rows in the table where <br />
                                    the value in each period is less than 100,000 <br />
                                    will be excluded.
                                </div>
                            </MotifTooltip>
                        </MotifFormField>
                    </span>
                </div>
                <div>
                    Percentage threshold
                </div>
                <div id="percentagethreshold">
                    <span>
                        <MotifFormField className='field-group'>
                            <MotifInput
                                className="inputThreshold"
                                label="inputpercentagethreshold"
                                onChange={onPercentageThresholdChange}
                                type='text'
                                placeholder="Enter Percentage"
                                value={percentageThreshold}
                            />
                            <MotifTooltip
                                trigger={
                                    <img className="helpTooltipIcon" src={help} alt='icon' />
                                }
                            >
                                <div className='tooltipAbsThresholdTitle'>Percentage Threshold</div>
                                <div className='tooltipAbsThrehsoldBody'>
                                    Minimum 5%
                                    <br /><br />
                                    The value may reflect an increase or <br />
                                    decrease in value presentation. This can be <br />
                                    adjusted for specific tables at a later stage in <br />
                                    the project.
                                </div>
                            </MotifTooltip>
                        </MotifFormField>
                        {isMaxChar &&
                            <MotifErrorMessage>
                                Percentage threshold setting too low, please enter minimum 5%
                            </MotifErrorMessage>
                        }
                    </span>
                </div>
            </div>
            <div className='genericFooter'>
                <div className='footerInner alignEnd'>
                    <MotifButton
                        onClick={saveThreshold}
                        disabled={isProceedDisable}
                    >
                        Proceed
                    </MotifButton>
                </div>
            </div>
        </div>
    )
}

export default ProjectThreshold;