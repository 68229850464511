/**
 * Table Details page of DaaS application
 * opens when table is selected from Project Details page
 * Created Date: 19/07/2024
 */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import brokenLink from '../../../assets/images/broken-link.svg';
import IconDataTransfer from '../../../assets/images/IconDataTransfer.svg';
import iconSuccess from '../../../assets/images/check-circle.svg';

/**
 * Motif controls
 */
import MotifButton, { MotifIconButton } from "@ey-xd/motif-react/Button";
import MotifTabNavigation, {
    MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import { MotifLabel, MotifTooltip, MotifTable, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifInlineMessage, MotifFormField, MotifSearch } from "@ey-xd/motif-react";
import MotifAccordion, {
    MotifAccordionTrigger,
    MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import MotifDropdown, { MotifDropdownItem } from '@ey-xd/motif-react/Dropdown';
import MotifToast from '@ey-xd/motif-react/Toast';

/**
 * Internal imports
 */
import './TableDetails.scss';
import TableService from '../../../services/tableService';
import Utils from '../../../utils/Utils';
import Commentary from "../commentary/Commentry";
import ExportQnA from "../../molecules/modals/exportQnA/ExportQnA";

//  SVG Icons imports
import iconArrowBack from '../../../assets/images/arrow_back.svg';
import iconDocument from '../../../assets/images/page.svg';
import iconRelationship from '../../../assets/images/network-alt.svg';
import iconThreshold from '../../../assets/images/ease-curve-control-points.svg';
import iconGenAI from '../../../assets/images/sparks.svg';
import iconThumbsUp from '../../../assets/images/thumbs-up.svg';
import iconThumbsUpFilled from '../../../assets/images/thumbs-up-filled.svg';
import iconThumbsDown from '../../../assets/images/thumbs-down.svg';
import iconThumbsDownFilled from '../../../assets/images/thumbs-down-filled.svg';
import iconMore from '../../../assets/images/more-horiz.svg';
import iconCopy from '../../../assets/images/copy.svg';
import iconArrowUp from '../../../assets/images/nav-arrow-up.svg';
import iconArrowDown from '../../../assets/images/nav-arrow-down.svg';
import iconCheckCircle from "../../../assets/images/check-circle.svg";
import iconProgressLoader from "../../../assets/images/progress-loader.svg";
import iconInfo from "../../../assets/images/info-empty.svg";
import infoIcon from "../../../assets/images/information.svg";
import iconProgressLoaderCircle from "../../../assets/images/Loading_indicator_circles.svg";
import iconAdditionalContext from '../../../assets/images/stats-up-square.svg';
import warning_error from "../../../assets/images/warning_error.svg";
import iconError from '../../../assets/images/error.svg';
import iconWhiteCheckCircle from "../../../assets/images/white-check-circle.svg";
import iconWarning from "../../../assets/images/warning-triangle.svg";
import iconUser from '../../../assets/images/user.svg';
import expandButton from '../../../assets/images/expand-button.svg'
import iconWarningInfoCircle from '../../../assets/images/warning-circle.svg';
import featured_icon from '../../../assets/images/featured_icon.svg'

// import LineCommentry from "../../molecules/modals/lineCommentary/LineCommentary";
// import LineAnalysisService from "../../../services/lineAnalysisService";
import TableRelationship from '../../molecules/modals/tableRelationship/TableRelationship';
import { setTableAnalysis } from "../../../auth/analysisSlice";
import { setShowToast } from "../../../auth/toastSlice";
import TableThreshold from "../../molecules/modals/tableThreshold/TableThreshold";
import UploadQA from '../../molecules/modals/uploadQA/UploadQA';
import AdditionalContext from "../../molecules/modals/additionalContext/AdditionalContext";
import Documents from '../../molecules/modals/documents/Documents';
import AnalysisSettings from '../../molecules/modals/analysisSettings/AnalysisSettings';
import AnalysisService from "../../../services/analysisService";

import iconUpload from '../../../assets/images/uploadIcon.svg';
import iconDownload from '../../../assets/images/downloadIcon.svg';
import iconUploadDisabled from '../../../assets/images/upload.svg';
import iconDownloadDisabled from '../../../assets/images/download.svg';
import iconWarningCircle from '../../../assets/images/warning-circle.svg';
import relationship_red from '../../../assets/images/relationship_red.svg';
import additinal_context_red from '../../../assets/images/additinal_context_red.svg';
import document_red from '../../../assets/images/document_red.svg';
import line_circle_red from '../../../assets/images/line_circle_red.svg';
import searchIcon from '../../../assets/images/search.svg';


const TableDetails = ({
    tableLAStat,
    onCloseSelectedTable,
    tables
}) => {

    //  Custom constants
    //  Tab (Table details & Line Analysis) details
    const vTabs = {
        tableDetails: [
            {
                id: 1,
                elementId: "idTabContentLineAnalysis",
                title: "Line Analysis",
            },
            {
                id: 2,
                elementId: "idTabContentTableAnalysis",
                title: "Table Analysis",
            },
        ],
        lineAnalysis: [
            {
                id: 1,
                elementId: "idTabContentDescription",
                title: "Description",
            },
            {
                id: 2,
                elementId: "idTabContentQA",
                title: "Q&A",
            },
            {
                id: 3,
                elementId: "idTabContentCommentary",
                title: "Commentary",
            },
        ],
    };
    const vTabIds = {
        tableDetails: {
            lineAnalysis: "idTabContentLineAnalysis",
            tableAnalysis: "idTabContentTableAnalysis",
        },
        lineAnalysis: {
            description: "idTabContentDescription",
            qa: "idTabContentQA",
            commentary: "idTabContentCommentary",
        },
    };
    const vExcelFileFormats = ["xls", "xlsx", "xlsm", "xlsb"];

    //  State variables
    const { user, activeProject } = useSelector(state => state.user);
    const { selectedTable } = useSelector(state => state.table);
    const [selectedTableData, setSelectedTableData] = useState(null);
    const [displayTableData, setDisplayTableData] = useState(null);
    const [selectedTableDetailsTab, setSelectedTableDetailsTab] = useState(
        vTabIds.tableDetails.lineAnalysis
    );
    const [selectedLineAnalysisTab, setSelectedLineAnalysisTab] = useState(
        vTabIds.lineAnalysis.description
    );
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [reapplySelections, setReapplySelections] = useState(false);
    const [lineAnalysisData, setLineAnalysisData] = useState();
    const [tableAnalysisData, setTableAnalysisData] = useState([]);
    const [showLineCommentaryModal, setShowLineCommentaryModal] = useState(false);
    const [checkRowIds, setCheckRowIds] = useState([]);
    const [isProceedBtnDisabled, setIsProceedBtnDisabled] = useState(false);
    const [rowStatusData, setRowStatusData] = useState([]);
    const [lineAnalysisStatus, setLineAnalysisStatus] = useState("");
    const [exportButtonDisabledStatus, setExportButtonDisabledStatus] = useState(true);
    const [usedDocumentList, setUsedDocumentList] = useState([]);
    const [deletedDocumentList, setDeletedDocumentList] = useState([]);
    const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState();
    const [notificationMessage, setNotificationMessage] = useState();
    const [lineItemsState, setLineItemsState] = useState([]);
    const [isCallForTableAnalysis, setIsCallForTableAnalysis] = useState(false);
    const [isTableCommentaryInitiated, setIsTableCommentaryInitiated] = useState(false);
    const [isTableCommentaryGenerated, setIsTableCommentaryGenerated] = useState(false);
    const [showExportQnAModal, setShowExportQnAModal] = useState(false);
    const [showTableThresholdModal, setShowTableThresholdModal] = useState(false);
    const [exportImportDetails, setExportImportDetails] = useState(null);
    const [isAllLineAnalysisNotGenerated, setIsAllLineAnalysisNotGenerated] = useState(false);
    const [isAllLineAnalysisInProgress, setIsAllLineAnalysisInProgress] = useState(false);
    const [isTableAnalysisGenerated, setIsTableAnalysisGenerated] = useState(false);
    const [displayAdditionalContextModal, setDisplayAdditionalContextModal] = useState(false);
    const [isRelationshipEnabled, setIsRelationshipEnabled] = useState(false);
    const [displayUploadQAModal, setDisplayUploadQAModal] = useState(false);
    const [displayDocumentsModal, setDisplayDocumentsModal] = useState(false);
    const [vwSrcDocsDropdownId, setVwSrcDocsDropdownId] = useState(null);
    const [isLineCommentaryInitiated, setIsLineCommentaryInitiated] = useState(false);
    const [showDocumentIcon, setShowDocumentIcon] = useState(false);
    const [showRelationshipIcon, setShowRelationshipIcon] = useState(false);
    const [showKpiIndIcon, setShowKpiIndIcon] = useState(false);
    const [showLineAnalysisIcon, setShowLineAnalysisIcon] = useState(false);
    const [showTableAnalysisIcon, setShowTableAnalysisIcon] = useState(false);
    const [fromUploadProcess, setFromUploadProcess] = useState(false);
    const [showUploadQnALoader, setShowUploadQnALoader] = useState(false);
    const [isSomeLineAnalysisGenerated, setIsSomeLineAnalysisGenerated] = useState(false);
    const [isRegenTableAnalysis, setIsRegenTableAnalysis] = useState(false);
    const [lastOperationStatus, setLastOperationStatus] = useState("");
    const [showLAPopover, setShowLAPopover] = useState(false);
    const [currentVLineItem, setCurrentVLineItem] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [showTransferToastLineAnalysis, setShowTransferToastLineAnalysis] = useState(false);
    const [showTransferToastTableAnalysis, setShowTransferToastTableAnalysis] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { showToast, toastDismissed } = useSelector((state) => state.toast)
    const tableService = new TableService();
    const analysisService = new AnalysisService();
    const utils = new Utils();
    const dispatch = useDispatch();
    const commentaryTabId = vTabs.lineAnalysis.find(tab => tab.title === "Commentary")?.id;

    //  Navigate to project details
    const navToProjectDetails = () => {
        dispatch(setTableAnalysis({
            tableAnalysis: null
        }));
        onCloseSelectedTable(true);
    };

    //  Open threshold popover.
    const openThresholdPopover = () => {
        setShowTableThresholdModal(true);
    };
    const [displayTableRelationshipModal, setDisplayTableRelationshipModal] = useState(false);

    /**
     * Useeffect hook to populate selected table data from parent page
     */
    useEffect(() => {
        if (selectedTable) {
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTable));
            for (const element of vSelectedTableData.tableContent) {
                element['open'] = false;
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setDisplayTableData(vSelectedTableData);
            getLineItemsState(selectedTable);
            getExportImportDetails(selectedTable);
        }
        if (tables.length >= 2) {
            setIsRelationshipEnabled(true);
        }
        else {
            setIsRelationshipEnabled(false);
        }
        // eslint-disable-next-line
    }, [selectedTable]);

    useEffect(() => {
        if (
            notificationMessage === "Q&A exported successfully" ||
            notificationMessage === "Export failure"
        ) {
            getExportImportDetails(selectedTable);
        }
        // eslint-disable-next-line
    }, [notificationMessage]);

    useEffect(() => {
        if (showUploadQnALoader) {
            setIsLineCommentaryInitiated(true);
        }
        else {
            if (notificationMessage !== '') {
                setIsLineCommentaryInitiated(false);
                getExportImportDetails(selectedTable);
            }
        }
        // eslint-disable-next-line
    }, [showUploadQnALoader]);

    useEffect(() => {
        let vSelectedTable = JSON.parse(JSON.stringify(displayTableData));
        if (vSelectedTable != null) {
            if (isSelectAll) {
                let checkboxes = document.querySelectorAll('input[type="checkbox"]');
                let rowIds = [];

                for (const element of vSelectedTable.tableContent) {
                    for (const checkbox of checkboxes) {
                        const ChckID = checkbox.id.replace('lineItemCheckbox', '');
                        if (ChckID === element.rowId) {
                            if (checkbox.disabled) {
                                element.selected = false;
                            }
                            else {
                                element.selected = true;
                                rowIds.push(element.rowId);
                            }
                        }
                    }

                }
                if (rowIds.length > 0) {
                    setCheckRowIds(rowIds);
                }
            } else {
                // for (const element of vSelectedTable.tableContent) {
                //     element.selected = false;
                // }
                // setCheckRowIds([]);
            }
            setDisplayTableData(vSelectedTable);
        }
    }, [reapplySelections])

    useEffect(() => {
        if (toastDismissed) {
            setIsButtonDisabled(false);
        }
    }, [toastDismissed])



    /**
     * Function to switch tabs
     * @param {*} tab
     */
    const handleTabClick = (vTabType, tab, vRowId) => {
        setActiveTab(tab.id)
        if (vTabType === "Table Details") {
            setSelectedTableDetailsTab(tab.elementId);
            switch (tab.elementId) {
                case vTabIds.tableDetails.lineAnalysis:
                    document.getElementById(vTabIds.tableDetails.lineAnalysis).style.display = "block";
                    document.getElementById(vTabIds.tableDetails.tableAnalysis).style.display = "none";
                    setIsCallForTableAnalysis(false);
                    break;
                case vTabIds.tableDetails.tableAnalysis:
                    document.getElementById(vTabIds.tableDetails.lineAnalysis).style.display = "none";
                    document.getElementById(vTabIds.tableDetails.tableAnalysis).style.display = "block";
                    setIsCallForTableAnalysis(true);
                    if (isProceedBtnDisabled) {
                        setIsProceedBtnDisabled(false);
                    }
                    break;
                default:
            }
        } else {
            setSelectedLineAnalysisTab(tab.elementId);
            switch (tab.elementId) {
                case vTabIds.lineAnalysis.description:
                    document.getElementById(
                        vTabIds.lineAnalysis.description + "~" + vRowId
                    ).style.display = "block";
                    document.getElementById(
                        vTabIds.lineAnalysis.qa + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.commentary + "~" + vRowId
                    ).style.display = "none";
                    break;
                case vTabIds.lineAnalysis.qa:
                    document.getElementById(
                        vTabIds.lineAnalysis.description + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.qa + "~" + vRowId
                    ).style.display = "block";
                    document.getElementById(
                        vTabIds.lineAnalysis.commentary + "~" + vRowId
                    ).style.display = "none";
                    break;
                case vTabIds.lineAnalysis.commentary:
                    document.getElementById(
                        vTabIds.lineAnalysis.description + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.qa + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.commentary + "~" + vRowId
                    ).style.display = "block";
                    break;
                default:
            }
        }
    };
    /**
     * Function for  Transfer commentary to PowerPoint addin
     * 
     */

    const handleTransferToPPTClick = async (isLineAnalysis) => {
        setIsButtonDisabled(true);
        const vData = {
            projectId: projectId,
            resultId: isLineAnalysis ? lineAnalysisData.results[2].resultId : tableAnalysisData[0].resultId,
            sourceSystem: isLineAnalysis ? "DaaS_LineCommentary" : "DaaS_TableAnalysis",
            answer: isLineAnalysis ? lineAnalysisData.results[2].answer : tableAnalysisData[0].answer,
            userId: userId
        }
        try {
            const response = await analysisService.transferToPPT(
                vData.projectId,
                vData.resultId,
                vData.sourceSystem,
                vData.answer,
                vData.userId
            );
            if (response) {
                if (isLineAnalysis) {
                    setShowTransferToastLineAnalysis(true)
                } else {
                    setShowTransferToastTableAnalysis(true)
                }

            } else {
                dispatch(setShowToast({ showToast: true, toastVariant: "error" }));
            }
        } catch (error) {
            dispatch(setShowToast({ showToast: true, toastVariant: "error" }));
        } finally {
            setIsButtonDisabled(false)
        }
    };
    //  Proceed button functionality to initiate line analysis from backend
    const proceed = () => {
        setShowLineCommentaryModal(true);
    };

    /**
     * Function to set select all selection
     * @param {*} event : Identify the check-uncheck
     */
    const onChangeSelectAll = (event) => {
        setIsSelectAll(event.target.checked);
        let vSelectedTable = JSON.parse(JSON.stringify(displayTableData));

        if (event.target.checked) {
            let checkboxes = document.querySelectorAll('input[type="checkbox"]');
            let rowIds = [];

            for (const element of vSelectedTable.tableContent) {
                for (const checkbox of checkboxes) {
                    const ChckID = checkbox.id.replace('lineItemCheckbox', '');
                    if (ChckID === element.rowId) {
                        if (checkbox.disabled) {
                            element.selected = false;
                        }
                        else {
                            element.selected = true;
                            rowIds.push(element.rowId);
                        }
                    }
                }

            }
            if (rowIds.length > 0) {
                setCheckRowIds(rowIds);
            }
        } else {
            for (const element of vSelectedTable.tableContent) {
                element.selected = false;
            }
            setCheckRowIds([]);
        }
        Object.preventExtensions(vSelectedTable);
        adjustSelectedTableData(event);
        //setSelectedTableData(vSelectedTable);
        setDisplayTableData(vSelectedTable);
    };

    const adjustSelectedTableData = (event) => {
        let vSelectedTable = JSON.parse(JSON.stringify(selectedTableData));

        if (event.target.checked) {
            let checkboxes = document.querySelectorAll('input[type="checkbox"]');
            let rowIds = [];

            for (const element of vSelectedTable.tableContent) {
                for (const checkbox of checkboxes) {
                    const ChckID = checkbox.id.replace('lineItemCheckbox', '');
                    if (ChckID === element.rowId) {
                        if (checkbox.disabled) {
                            element.selected = false;
                        }
                        else {
                            element.selected = true;
                            rowIds.push(element.rowId);
                        }
                    }
                }

            }
            if (rowIds.length > 0) {
                setCheckRowIds(rowIds);
            }
        } else {
            for (const element of vSelectedTable.tableContent) {
                element.selected = false;
            }
            setCheckRowIds([]);
        }
        Object.preventExtensions(vSelectedTable);
        setSelectedTableData(vSelectedTable);
    }

    /**
     * Function to toggle more dropdown menu
     * @param {*} vDropdownMenuId 
     * @param {*} vToggle 
     */
    const toggleDropdownMenuMore = (vDropdownMenuId, vToggle) => {
        const vDropdown = document.getElementById('more-dropdown-' + vDropdownMenuId);
        if (vDropdown && (vToggle === 'Open')) {
            vDropdown.style.display = 'block';
        } else if (vDropdown && (vToggle === 'Close')) {
            vDropdown.style.display = 'none';
        }
    };


    /**
     * Function for Copy line commentary
     * @param {*} vLineItemId 
     */
    const onCopy = (vLineItemId) => {
        let r = document.createRange();
        r.selectNode(document.getElementsByClassName('lineCommentary-' + vLineItemId)[0]);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        displayNotification('success', 'Copied');
        toggleDropdownMenuMore(vLineItemId, 'Close');
    };

    /**
     * Function to get line analysis details for opened line item
     * @param {*} vLineItemId 
     */
    const getLineAnalysisDetails = async (vLineItemId) => {
        const userId = user.username;
        const vData = {
            projectId: projectId,
            tableId: selectedTableData.id,
            rowId: vLineItemId,
            userId: userId,
        };
        const vLineAnalysisData = await tableService.getLineAnalysisDetails(
            vData
        );
        setLineAnalysisData(vLineAnalysisData);
    };

    /**
     * Function to extract line analysis data
     * @param {*} vType 
     * @returns 
     */
    const extractLineAnalysisData = (vType, vField) => {
        let vLineAnalysisData;
        const vLineAnalysis = lineAnalysisData?.results;
        if (vLineAnalysis.length > 0) {
            const tmpLineAnalysisData = vLineAnalysis.filter(element => (element.queueMethod === vType))[0];
            if (tmpLineAnalysisData && tmpLineAnalysisData[vField]) {
                vLineAnalysisData = tmpLineAnalysisData[vField];
            }
        }

        return vLineAnalysisData;
    };

    /**
     * Function to Submit feedback as Like/Dislike
     * @param {*} vFeedbackType 
     * @param {*} vLineItem 
     */
    const onSubmitFeedabck = async (vFeedbackType, vLineItem, vFeedback) => {
        let userFeedback;
        if (vFeedbackType === 'Like') {
            if (vLineItem.feedback === 1) {
                userFeedback = 0;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = 1;
            } else if (vFeedback === 1) {
                userFeedback = 0;
            } else if (vFeedback === 0) {
                userFeedback = 1;
            } else {
                userFeedback = 1;
            }
        } else {
            if (vLineItem.feedback === -1) {
                userFeedback = 0;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = -1;
            } else if (vFeedback === -1) {
                userFeedback = 0;
            } else if (vFeedback === 0) {
                userFeedback = -1;
            } else {
                userFeedback = -1;
            }
        }
        const vData = {
            "projectId": projectId,
            "tableId": selectedTableData.id,
            "rowId": vLineItem.rowId,
            "queueMethod": "lineCommentary",
            "status": "finished",
            "feedback": userFeedback
        };
        const response = await tableService.submitFeedback(vData);
        if (response) {
            vLineItem.feedback = response.feedback;
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
            for (const element of vSelectedTableData.tableContent) {
                if (element.rowId === vLineItem.rowId) {
                    element.feedback = response.feedback;
                }
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setDisplayTableData(vSelectedTableData);
            displayNotification('success', 'Successfully sent feedback.');
        } else {
            displayNotification('error', 'Failed to send feedback.');
        }
    };

    const getFeedback = (vFeedbackType, vLineItem, vFeedback) => {
        let userFeedback;
        if (vFeedbackType === 'Like') {
            if (vLineItem.feedback === 1) {
                userFeedback = 1;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = 0;
            } else if (vFeedback === 1) {
                userFeedback = 1;
            } else if (vFeedback === 0) {
                userFeedback = 0;
            } else {
                userFeedback = 0;
            }
        } else {
            if (vLineItem.feedback === -1) {
                userFeedback = -1;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = 0;
            } else if (vFeedback === -1) {
                userFeedback = -1;
            } else if (vFeedback === 0) {
                userFeedback = 0;
            } else {
                userFeedback = 0;
            }
        }
        return userFeedback;
    };

    const handleCloseModal = () => {
        setShowLineCommentaryModal(false);
        setFromUploadProcess(false);
    };

    /**
    * Function to open Documents Modal
    */
    const showDocuments = () => {
        setDisplayDocumentsModal(true);
    }

    const projectId = activeProject.project_UUID;
    const userId = user.username;
    const tableId = selectedTable.id;
    // const lineAnalysis = new LineAnalysisService();

    /**
     * This async function used to initiate Line Analysis process once click on Confirm button
     * @param {Array} data :  List of selected documents and Line commentary Excel check value
     */
    const handleConfirmClick = async (data) => {
        if (isCallForTableAnalysis) {
            setIsTableCommentaryInitiated(true);
            setShowLineCommentaryModal(false);
            setIsProceedBtnDisabled(true);
            setIsTableCommentaryGenerated(false);
            const vData = {
                tableId: tableId,
                projectId: projectId,
                userId: userId,
                isCommentaryExcel: data.isCommentaryExcel
            }
            const tableAnalysisStatus = await analysisService.initialiseTableAnalysis(vData);
            if (tableAnalysisStatus === 200) {
                //  Do nothing, handover to polling
            }
        } else {
            setSelectedAccordion(null);
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
            for (const element of vSelectedTableData.tableContent) {
                element.open = false;
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setCheckRowIds([]);
            setIsLineCommentaryInitiated(true);
            setShowLineCommentaryModal(false);
            setIsProceedBtnDisabled(true);
            const vData = {
                tableId: tableId,
                projectId: projectId,
                checkRowIds: checkRowIds,
                documents: data.documents,
                qnaFlag: data.qnaFlag,
                fulltableFlag: data.fulltableFlag,
                promptIds: data.promptIds,
                userId: userId,
                isCommentaryExcel: data.isCommentaryExcel
            };
            const lineAnalysisStatus = await analysisService.initLineAnalysis(vData)
            if (lineAnalysisStatus === 200) {
                setIsLineCommentaryInitiated(false);
                getRowStatus()
                checkAllRowStatusCompleted(rowStatusData);
            }
            clearAllLineItemSelection();
        }
    };

    /**
     * Get the Line Analysis status data
     */
    const getRowStatus = async () => {
        const data = await analysisService.getRowStatus(tableId, projectId, userId);
        if (data && Object.keys(data).length > 0) {
            setRowStatusData(data.rowIds);
            setUsedDocumentList(data.documentList);
            setDeletedDocumentList(data.deleted_document_list);
            setLastOperationStatus(data.lastOperation);
            const indicators = data.indicators || {};
            const {
                // eslint-disable-next-line
                cnt,
                documentInd,
                relationshipInd,
                kpiInd,
                lineanalysisInd,
                tableanlysisInd
            } = indicators;
            setShowDocumentIcon(documentInd === 0);
            setShowRelationshipIcon(relationshipInd === 0);
            setShowKpiIndIcon(kpiInd === 0);
            setShowLineAnalysisIcon(lineanalysisInd === 0);
            setShowTableAnalysisIcon(tableanlysisInd === 0);
            setLineAnalysisStatus(checkStatus(data.rowIds));
            setExportButtonDisabledStatus(!(data.rowIds.some(row => row.status === 'Completed')));
            setIsAllLineAnalysisNotGenerated(data.rowIds.every(row => row.status === 'No Analysis Initiated'));
            setIsSomeLineAnalysisGenerated(data.rowIds.some(row => row.status === 'Completed'));
            setIsTableAnalysisGenerated(data.tableAnalysisStatus);
            setIsAllLineAnalysisInProgress(data.rowIds.every(row => row.status === 'In-Progress'));
            const isAllRowInProgress = data.rowIds.every(row => row.status === 'In-Progress');
            if (isAllRowInProgress === true) {
                setIsSelectAllDisabled(true);
            } else {
                setIsSelectAllDisabled(false);
            }
            generateCommentaryInExcel(data);
        }
    };

    /**
     * Use Effect hook to check if we received newly generated table analysis
     */
    useEffect(() => {
        if ((isTableAnalysisGenerated === 'In-Progress') && isTableCommentaryInitiated) {
            setIsRegenTableAnalysis(true);
        }
        if ((isTableAnalysisGenerated === 'Completed') && isTableCommentaryInitiated && isRegenTableAnalysis) {
            getTableAnalysisData();
            setIsProceedBtnDisabled(false);
        }
        //  eslint-disable-next-line
    }, [isTableAnalysisGenerated])

    /**
     * Checks the status of a list of data entries and returns the overall status.
     * The function evaluates the statuses of each entry in the provided data array
     * and returns the first matching status based on the following priority:
     * 1. 'In-Progress'
     * 2. 'No Analysis Initiated'
     * 3. 'Completed'
     * 
     * @param {Array} data - An array of objects, each containing a 'status' property.
     * @returns {string|undefined} - Returns the first matching status as a string or undefined if no statuses match.
     */
    const checkStatus = (data) => {
        if (data.some(row => row.status === 'In-Progress')) {
            return 'In-Progress';
        } else if (
            data.some(row => row.status === 'No Analysis Initiated') ||
            data.some(row => row.status === 'Failure')
        ) {
            return 'No Analysis Initiated';
        } else if (data.some(row => row.status === 'Completed')) {
            return 'Completed';
        }
    };

    /**
     * Checks the overall status of a list of data entries and updates the line analysis status.
     * This function uses the `checkStatus` function to determine the current status of the data.
     * If the status is 'In-Progress', it calls the `getRowStatus` function to refresh the row statuses.
     * Regardless of the status, it then calls `getRowStatus` again to ensure the most up-to-date information is retrieved.
     * Finally, it sets the line analysis status using the determined row status.
     *
     * @param {Array} data - An array of objects, each containing a 'status' property.
     */
    const checkAllRowStatusCompleted = (data) => {
        const rowStatus = checkStatus(data)
        if (rowStatus === 'In-Progress') {
            getRowStatus();
        }
        getRowStatus();
    };

    useEffect(() => {
        getTableAnalysisData()
        getRowStatus();
        const interval = setInterval(() => {
            checkAllRowStatusCompleted(rowStatusData);
        }, 10000);
        return () => clearInterval(interval); // Cleanup function to clear interval
        // eslint-disable-next-line
    }, []);

    /**
     * Function to check whether to dsplay Description/Q&A/Commentary tabs with default message or content
     * @param {*} vLineAnalysisType 
     * @param {*} vLineItem 
     * @returns 
     */
    const displayLineAnalysis = (vLineAnalysisType, vLineItem) => {
        let displayLineAnalysis = false;
        if (
            vLineItem &&
            lineAnalysisData &&
            (vLineItem.rowId === lineAnalysisData.rowId) &&
            lineAnalysisData.results &&
            (lineAnalysisData.results.length > 0)
        ) {
            const tmpLineAnalysis = lineAnalysisData.results.filter(element => (element.queueMethod === vLineAnalysisType))[0];
            if ((vLineAnalysisType === 'lineDescription') || (vLineAnalysisType === 'lineCommentary')) {
                if (tmpLineAnalysis && tmpLineAnalysis.answer && (tmpLineAnalysis.answer !== '')) {
                    displayLineAnalysis = true;


                }
            } else if (vLineAnalysisType === 'lineQuestions') {
                if (tmpLineAnalysis && tmpLineAnalysis.extractions && (tmpLineAnalysis.extractions.length > 0)) {
                    displayLineAnalysis = true;
                }
            }
        }
        return displayLineAnalysis;
    };

    /**
     * Function to display notification
     * @param {*} vType 
     * @param {*} vMessage 
     */
    const displayNotification = (vType, vMessage) => {
        setShowNotification(true);
        setNotificationType(vType);
        setNotificationMessage(vMessage);
    };

    /**
     * Function to get line items state as exclude or include for line analysis
     */
    const getLineItemsState = async (vSelectedTable) => {
        const vData = {
            "tableId": vSelectedTable.id
        };
        const response = await tableService.getLineItemsState(vData);
        if (response && response.message) {
            if (response.message[vSelectedTable.id]) {
                setLineItemsState(response.message[vSelectedTable.id]);
            }
        }
    };

    /**
     * 
     * @param {*} selectedTable 
     * @returns Export import details.
     */
    const getExportImportDetails = async (selectedTable) => {
        const data = {
            "tableId": selectedTable.id,
            "projectId": projectId,
            "userId": user.username
        };
        const response = await tableService.getExportImportDetails(data);
        setExportImportDetails(response);
    };

    /*
     * Function will be called when add table relationship modal closes
     * @param {*} vStatus 
     * @param {*} vError 
     */
    const closeTableRelationshipModal = () => {
        setDisplayTableRelationshipModal(false);
    };

    /**
     * Function to set close additional context modal
     */
    const closeAdditionalContextModal = () => {
        setDisplayAdditionalContextModal(false);
    };

    /**
     * Function to open add table relationship popup
     */
    const setTableRelationship = () => {
        setDisplayTableRelationshipModal(true);
    }

    /**
    * Function to open QA Modal
    */
    const uploadQA = () => {
        setDisplayUploadQAModal(true);

    }

    /**
     * Function to get table analysis data
     */

    const getTableAnalysisData = async (data) => {
        const tableAnalysisData = await analysisService.getTableAnalysisData(tableId)
        if (tableAnalysisData && (tableAnalysisData.length > 0)) {
            setTableAnalysisData(tableAnalysisData);
            const isAnswerExist = tableAnalysisData.some(item => item.hasOwnProperty('answer'));
            if (isAnswerExist === true) {
                setIsTableCommentaryGenerated(true);
                dispatch(setTableAnalysis({
                    tableAnalysis: tableAnalysisData[0]
                }));
            } else {
                setIsTableCommentaryGenerated(false);
                setIsTableCommentaryInitiated(false);
            }
        }
    }

    const handleExportQnAClick = async () => {
        setShowExportQnAModal(true)
    }
    /**
     * Check if Proceed button disabled due to
     * 1. All line item analyis status is not generated or
     * 2. Some of the line item analysis is in progress
     * @returns true or false
     */
    const getTableAnalysisProceedDisableState = () => {
        let vTableAnalysisProceedDisableState = false;
        if (
            (selectedTableDetailsTab === 'idTabContentTableAnalysis') &&
            (
                isAllLineAnalysisNotGenerated ||
                !isSomeLineAnalysisGenerated ||
                (lineAnalysisStatus === 'In-Progress') ||
                (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                (isTableAnalysisGenerated === "In-Progress") ||
                (lastOperationStatus !== "Line Analysis")
            )
        ) {
            vTableAnalysisProceedDisableState = true;
        }
        return vTableAnalysisProceedDisableState;
    };

    /**
     * Check if Proceed button disabled due to
     * 1. All line item analysis status is in progress or
     * 2. Table analysis status is in progress
     * @returns true or false
     */
    const getLineAnalysisProceedDisableState = () => {
        let vLineAnalysisProceedDisableState = false;
        if (
            (selectedTableDetailsTab === 'idTabContentLineAnalysis') &&
            (
                isAllLineAnalysisInProgress ||
                (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                (isTableAnalysisGenerated === "In-Progress")
            )
        ) {
            vLineAnalysisProceedDisableState = true;
        }
        return vLineAnalysisProceedDisableState;
    };

    /**
     * Check if any line item selected for proceed
     * @returns true or false
     */
    const getSelectedLineItemsState = () => {
        let vSelectedLineItemsState = false;
        if (
            (selectedTableDetailsTab === 'idTabContentLineAnalysis') &&
            (checkRowIds.length <= 0)
        ) {
            vSelectedLineItemsState = true;
        }
        return vSelectedLineItemsState;
    };

    /**
     * Get tooltip text for Proceed button disable
     * @returns tooltip text
     */
    const getProceedDisabledTooltipText = () => {
        if (getLineAnalysisProceedDisableState()) {
            return 'Either the Line Analysis or the Table Analysis is still in progress';
        } else if (getTableAnalysisProceedDisableState()) {
            return 'Either none of the line items have analysis, or the Line Analysis is still in progress.';
        } else if (getSelectedLineItemsState()) {
            return 'Select at least one line item to continue';
        } else {
            return 'Analysis in progress';
        }
    };

    const handleExpandIconClick = (event, vLineItem) => {
        setUpAnalysisDetails(event, vLineItem)
        setCurrentVLineItem(vLineItem);
        setShowLAPopover(true);
    }

    const onLAPopoverClose = () => {
        setShowLAPopover(false);
    }

    /**
     * Function to manage accordion click
     * @param {*} event 
     * @param {*} vLineItem 
     */
    const setUpAnalysisDetails = (event, vLineItem) => {
        if ((event.target.className === 'motif-checkbox-input motif-visually-hidden')) {
            const { rowId } = vLineItem;
            if (!vLineItem.selected) {
                const vCheckRowIds = [...checkRowIds, rowId];
                setCheckRowIds(vCheckRowIds);
                if (isProceedBtnDisabled) {
                    setIsProceedBtnDisabled(false);
                }
                if ((vCheckRowIds.length === getEnabledCheckboxesCount()) && !isSelectAll) {
                    setIsSelectAll(true);
                }
            } else {
                const vCheckRowIds = checkRowIds.filter((item) => item !== rowId);
                setCheckRowIds(vCheckRowIds);
                if ((vCheckRowIds.length !== getEnabledCheckboxesCount()) && isSelectAll) {
                    setIsSelectAll(false);
                }
            }
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
            for (const element of vSelectedTableData.tableContent) {
                if (element.rowId === rowId) {
                    element.selected = !vLineItem.selected;
                }
            }

            for (const element of displayTableData.tableContent) {
                if (element.rowId === rowId) {
                    element.selected = !vLineItem.selected;
                }
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setDisplayTableData(displayTableData);
            window.scrollTo(0, 0);
        }
        else {
            if (!vLineItem.open) {
                const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
                for (const element of vSelectedTableData.tableContent) {
                    if (element.rowId === vLineItem.rowId) {
                        element.open = true;
                    } else {
                        element.open = false;
                    }
                }
                Object.preventExtensions(vSelectedTableData);
                setSelectedTableData(vSelectedTableData);
                setDisplayTableData(vSelectedTableData);
                setSelectedAccordion(vLineItem.rowId);
                getLineAnalysisDetails(vLineItem.rowId);
            } else if (vLineItem.open) {
                const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
                for (const element of vSelectedTableData.tableContent) {
                    if (element.rowId === vLineItem.rowId) {
                        element.open = false;
                    }
                }
                Object.preventExtensions(vSelectedTableData);
                setSelectedTableData(vSelectedTableData);
                setDisplayTableData(vSelectedTableData);
                setSelectedAccordion(null);
            }
        }
    };

    /**
     * Function to open add additional context popup
     */
    const setAdditionalContext = () => {
        setDisplayAdditionalContextModal(true);
    }
    /**
         * This method handles export modal close.
         */
    const onExportQnAModalClose = () => {
        setShowExportQnAModal(false);
    }

    /**
     * This method receives export status.
     * @param {number} statusCode 
     */
    const receiveExportStatus = (statusCode) => {
        if (statusCode === 200) {
            setNotificationMessage("Q&A exported successfully");
            setShowNotification(true);
            setNotificationType("success");
        }
        else {
            setNotificationMessage("Export failure");
            setShowNotification(true);
            setNotificationType("error");
        }
    }

    /**
     * This method receives update Q&A status.
     * @param {number} status 
     */
    const receiveUploadQnAStatus = (status) => {
        if (status === 'success') {
            setIsLineCommentaryInitiated(false);
            getExportImportDetails(selectedTable);
            setNotificationMessage("Q&A updated and line analysis run successfully");
            setShowNotification(true);
            setNotificationType("success");
        }
        else {
            setIsLineCommentaryInitiated(false);
            getExportImportDetails(selectedTable);
            setNotificationMessage("Q&A upload failed due to a technical error. Please retry uploading the Q&A.");
            setShowNotification(true);
            setNotificationType("error");
        }
    }

    const onThresholdModalClose = () => {
        setShowTableThresholdModal(false);
    }

    const receiveThresholdUpdateStatus = (statusCode) => {
        // eslint-disable-next-line
        if (statusCode == 200) {
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage('Threshold settings updated successfully.');
        }
        else {
            setShowNotification(true);
            setNotificationType("error");
            setNotificationMessage('Failed to update table threshold settings.');
        }
    }

    const generateCommentaryInExcel = (vData) => {
        if (
            vData?.rowIds?.some(row => row.isommentaryexcel) ||
            vData?.isCommentaryExcelTable
        ) {
            if (window.Excel) {
                window.Excel.run(async (context) => {

                    // Extract the workbook name (filename)
                    const workbook = context.workbook;
                    workbook.load("name");
                    await context.sync();
                    const workbookName = workbook.name;

                    if (selectedTable?.metadata?.filename !== workbookName) {
                        return;
                    }

                    // Get the selected range
                    const selectedRange = context.workbook.getSelectedRange();
                    selectedRange.load(["worksheet/name"]);
                    // Sync the context to load the selected range properties
                    await context.sync();
                    // Extract the values and address from the selected range
                    const sheetName = selectedRange.worksheet.name;

                    if (selectedTable?.metadata?.sheetName !== sheetName) {
                        return;
                    }

                    if (selectedTable?.metadata?.uploadRange) {

                        const vLineCommentaryHeaderText = 'Line Commentary';
                        const vTableCommentaryHeaderText = 'Table Commentary';

                        const targetSheet = context.workbook.worksheets.getItem(sheetName);
                        const vRange = selectedTable?.metadata?.uploadRange;

                        const vCellToUpdate = await getColumnLineCommentary(targetSheet, vRange, context);

                        const pasteRange = `${vCellToUpdate.column}${vCellToUpdate.row}`;
                        let cellToUpdate = targetSheet.getRange(pasteRange);
                        cellToUpdate.load("format/font");
                        cellToUpdate.load("values");
                        await context.sync();

                        // If cell already contains Line commentary header, don't update
                        let currentValues = cellToUpdate.values;
                        let headerValue;
                        let isUpdateNeeded = false;
                        if (currentValues.length > 0 && currentValues[0].length > 0) {
                            if (vLineCommentaryHeaderText !== currentValues[0][0]) {
                                headerValue = vLineCommentaryHeaderText;
                                isUpdateNeeded = true;
                            } else {
                                headerValue = currentValues[0][0];
                            }
                        } else {
                            headerValue = vLineCommentaryHeaderText;
                            isUpdateNeeded = true;
                        }
                        if (isUpdateNeeded) {
                            utils.writeValueToExcelCell('Header', cellToUpdate, headerValue);
                            await context.sync();
                        }

                        let vLineCommentaryCell = vCellToUpdate;
                        for (const element of selectedTable.tableContent) {
                            vLineCommentaryCell.row += 1;
                            const vRow = vData?.rowIds?.find(ele => (ele.rowId === element.rowId));
                            if (vRow?.isommentaryexcel && (vRow?.status === 'Completed')) {
                                const pasteRange = `${vLineCommentaryCell.column}${vLineCommentaryCell.row}`;
                                let cellToUpdate = targetSheet.getRange(pasteRange);
                                cellToUpdate.load("format/font");
                                cellToUpdate.load("values");
                                await context.sync();

                                const vPayload = {
                                    projectId: projectId,
                                    tableId: selectedTable.id,
                                    rowId: element.rowId,
                                    userId: userId,
                                };
                                const vLineAnalysis = await tableService.getLineAnalysisDetails(vPayload);
                                if (
                                    vLineAnalysis?.results &&
                                    (vLineAnalysis.results.length > 1) &&
                                    vLineAnalysis.results[2]?.answer
                                ) {
                                    // If cell already contains Line commentary value as same then will not update
                                    let currentValues = cellToUpdate.values;
                                    let cellValue;
                                    let isUpdateNeeded = false;
                                    if (currentValues.length > 0 && currentValues[0].length > 0) {
                                        if (vLineAnalysis.results[2].answer !== currentValues[0][0]) {
                                            cellValue = vLineAnalysis.results[2].answer;
                                            isUpdateNeeded = true;
                                        } else {
                                            cellValue = currentValues[0][0];
                                        }
                                    } else {
                                        cellValue = vLineAnalysis.results[2].answer;
                                        isUpdateNeeded = true;
                                    }
                                    if (isUpdateNeeded) {
                                        utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                                        await context.sync();
                                    }
                                }
                            } else {
                                const pasteRange = `${vLineCommentaryCell.column}${vLineCommentaryCell.row}`;
                                let cellToUpdate = targetSheet.getRange(pasteRange);
                                cellToUpdate.load("format/font");
                                cellToUpdate.load("values");
                                await context.sync();

                                let cellValue = '';
                                utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                                await context.sync();
                            }
                        }

                        let vTableCommentaryCell = vLineCommentaryCell;
                        if (
                            (vData?.isCommentaryExcelTable) &&
                            (vData?.tableAnalysisStatus === "Completed")
                        ) {
                            vTableCommentaryCell.row += 2;
                            const pasteRange = `${vTableCommentaryCell.column}${vTableCommentaryCell.row}`;
                            let cellToUpdate = targetSheet.getRange(pasteRange);
                            cellToUpdate.load("format/font");
                            cellToUpdate.load("values");
                            await context.sync();

                            // If cell already contains Line commentary header, don't update
                            let currentValues = cellToUpdate.values;
                            let headerValue;
                            let isUpdateNeeded = false;
                            if (currentValues.length > 0 && currentValues[0].length > 0) {
                                if (vTableCommentaryHeaderText !== currentValues[0][0]) {
                                    headerValue = vTableCommentaryHeaderText;
                                    isUpdateNeeded = true;
                                } else {
                                    headerValue = currentValues[0][0];
                                }
                            } else {
                                headerValue = vTableCommentaryHeaderText;
                                isUpdateNeeded = true;
                            }
                            if (isUpdateNeeded) {
                                utils.writeValueToExcelCell('Header', cellToUpdate, headerValue);
                                await context.sync();
                            }
                            const vTableAnalysis = await analysisService.getTableAnalysisData(tableId)
                            if (vTableAnalysis && (vTableAnalysis.length > 0)) {
                                const isAnswerExist = vTableAnalysis.some(item => item.hasOwnProperty('answer'));
                                if (isAnswerExist === true) {
                                    dispatch(setTableAnalysis({
                                        tableAnalysis: vTableAnalysis[0]
                                    }));
                                    vTableCommentaryCell.row += 1;
                                    const pasteRange = `${vTableCommentaryCell.column}${vTableCommentaryCell.row}`;
                                    let cellToUpdate = targetSheet.getRange(pasteRange);
                                    cellToUpdate.load("format/font");
                                    cellToUpdate.load("values");
                                    await context.sync();
                                    // If cell already contains Line commentary value as same then will not update
                                    let currentValues = cellToUpdate.values;
                                    let cellValue;
                                    let isUpdateNeeded = false;
                                    if (currentValues.length > 0 && currentValues[0].length > 0) {
                                        if (vTableAnalysis[0].answer !== currentValues[0][0]) {
                                            cellValue = vTableAnalysis[0].answer;
                                            isUpdateNeeded = true;
                                        } else {
                                            cellValue = currentValues[0][0];
                                        }
                                    } else {
                                        cellValue = vTableAnalysis[0].answer;
                                        isUpdateNeeded = true;
                                    }
                                    if (isUpdateNeeded) {
                                        utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                                        await context.sync();
                                    }
                                }
                            }
                        }
                    }

                }).catch((error) => {
                    console.error("Error: " + error);
                });
            }
        }
    };

    /**
     * Function to get cell to write line commentary
     * @param {*} vSheet 
     * @param {*} vRange 
     * @param {*} vContext 
     * @returns 
     */
    const getColumnLineCommentary = async (vSheet, vRange, vContext) => {
        const firstCell = utils.splitExcelCell(vRange.split(":")[0]);
        const lastCell = utils.splitExcelCell(vRange.split(":")[1]);
        const vRow = firstCell.row;
        let vColumn = utils.letterToNum(lastCell.column);
        const noOfCols = 100;
        for (let i = vColumn + 1; i < noOfCols; i++) {
            const curColumn = utils.numToLetters(i);
            const curCellValue = await getCellValue(vRow, curColumn, vSheet, vContext);
            const nextColumn = utils.numToLetters(i + 1);
            const nextCellValue = await getCellValue(vRow, nextColumn, vSheet, vContext);
            if ((curCellValue === '') && ((nextCellValue === '') || (nextCellValue === 'Line Commentary'))) {
                return { column: nextColumn, row: vRow };
            }
        }
        return null;
    };

    /**
     * Function to get cell value
     * @param {*} vRow 
     * @param {*} vCol 
     * @param {*} vSheet 
     * @param {*} vContext 
     * @returns 
     */
    const getCellValue = async (vRow, vCol, vSheet, vContext) => {
        const cellRange = `${vCol}${vRow}`;
        const curCell = vSheet.getRange(cellRange);
        curCell.load("format/font");
        curCell.load("values");
        await vContext.sync();
        return curCell.values[0][0];
    };

    /**
     * Function to get source reference
     * @param {*} vDocRef
     */
    const getSourceReference = (vDocRef) => {
        if (isExcelFileFormat(vDocRef.source)) {
            return getExcelSourceReference(vDocRef);
        } else {
            return getNonExcelSourceReference(vDocRef);
        }
    };

    /**
     * Function to check if excel file format or not
     * @param {*} vSource
     * @returns
     */
    const isExcelFileFormat = (vSource) => {
        let vIsExcelFileFormat = false;
        if (vSource) {
            const isFileFormatFound = vExcelFileFormats.find(
                (element) => vSource.toLowerCase().indexOf(element) !== -1
            );
            if (isFileFormatFound) {
                vIsExcelFileFormat = true;
            }
        }
        return vIsExcelFileFormat;
    };

    /**
     * Function to get Non excel source references
     * @param {*} vDocRef
     * @returns
     */
    const getNonExcelSourceReference = (vDocRef) => {
        let vSourceRef = "";
        if (vDocRef?.page) {
            vSourceRef = vDocRef.source + ", Page Number - " + vDocRef.page;
        } else {
            vSourceRef = vDocRef.source;
        }
        return vSourceRef;
    };

    /**
     * Function to get excel source reference
     * @param {*} vDocRef
     */
    const getExcelSourceReference = (vDocRef) => {
        let vSourceRef = "";
        if (vDocRef?.sheet_name) {
            vSourceRef = vDocRef.source + ", Sheet Name - " + vDocRef.sheet_name;
        } else {
            vSourceRef = vDocRef.source;
        }
        return vSourceRef;
    };

    /**
     * Function to check if ellipsis applied to table name
     * @returns 
     */
    const isEllipsis = () => {
        let vIsEllipsis = false;
        const idTableName = document.getElementById('idTableName');
        if (idTableName && (idTableName.scrollWidth > idTableName.clientWidth)) {
            vIsEllipsis = true;
        }
        return vIsEllipsis;
    };

    const toggleDocsDropdown = (vLineItemId) => {
        vwSrcDocsDropdownId ? setVwSrcDocsDropdownId(null) : setVwSrcDocsDropdownId(vLineItemId);
    }

    const toggleVwSrcDocsQA = (vElement) => {
        if (vElement['isDropdownOpen']) {
            vElement['isDropdownOpen'] = false;
        } else {
            vElement['isDropdownOpen'] = true;
        }
    };

    const clearAllLineItemSelection = () => {
        const vSelectedTableData = JSON.parse(JSON.stringify(displayTableData));
        for (const element of vSelectedTableData.tableContent) {
            element.selected = false;
        }
        Object.preventExtensions(vSelectedTableData);
        //setSelectedTableData(vSelectedTableData);
        setDisplayTableData(vSelectedTableData);
        if (isSelectAll) {
            setIsSelectAll(false);
        }
    };

    const filterLineItems = (e) => {
        setSearchValue(e.target.value);
        let filteredLineItems = selectedTableData?.tableContent?.filter((lineItem) => {
            return lineItem.RowHeader.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setDisplayTableData({
            ...displayTableData,
            tableContent: filteredLineItems
        });

        setReapplySelections(!reapplySelections);
    }

    const getEnabledCheckboxesCount = () => {
        let count = 0;
        displayTableData?.tableContent?.forEach((vLineItem) => {
            const isDisabled = ((rowStatusData?.find((row) => row.rowId === vLineItem.rowId) || {}).status === "In-Progress") ||
                ((lineItemsState?.find((row) => row.rowId === vLineItem.rowId) || {}).exclude);
            if (!isDisabled) {
                count += 1;
            }
        })
    }

    return (
        <div className="tableDetailsContainer">
            <div className="tableDetailsBreadcrumb">
                <MotifIconButton
                    aria-label="Click here to go back to Project Details"
                    onClick={() => navToProjectDetails()}
                    className="btnBack"
                    data-testid="testIdBtnBack"
                >
                    <img src={iconArrowBack} alt="Previous" />
                </MotifIconButton>
                <div className="fluid">
                    <div className="tableName" id="idTableName">
                        <MotifTooltip
                            hide={!isEllipsis()}
                            trigger={selectedTableData?.tableName}
                            allowClick={false}
                            hideCloseButton={true}
                        >
                            {selectedTableData?.tableName}
                        </MotifTooltip>
                    </div>
                </div>
                <span className="ellipsisText">{(isEllipsis() ? '...' : '')}</span>
                <div className="tableActions">
                    <MotifIconButton
                        aria-label="Click here for source documents"
                        className="btnTableActions btnDocument"
                        disabled={!((usedDocumentList && usedDocumentList.length > 0))}
                        data-testid="testIdBtnDocument"
                        onClick={() => showDocuments()}
                    >
                        <MotifTooltip
                            trigger={<img src={showDocumentIcon ? document_red : iconDocument} alt="Source Documents" />}
                            placement="bottom"

                            contentClassName="tooltipWithoutClose"
                            allowClick={false}
                            hideCloseButton={true}
                        >
                            {showDocumentIcon ? (
                                <>There has been a change to <br />
                                    the Documents  for this table, Select<br />
                                    a row(s) and click 'Proceed' to <br />
                                    regenerate Line Analysis
                                </>) : (
                                "Documents"
                            )}

                        </MotifTooltip>
                    </MotifIconButton>
                    {displayDocumentsModal &&
                        <Documents
                            openModal={displayDocumentsModal}
                            setOpenModal={setDisplayDocumentsModal}
                            filterDocuments={usedDocumentList}
                            filterdelDocumentList={deletedDocumentList}
                        />
                    }
                    <MotifIconButton
                        aria-label="Click here for table relationships"
                        className="btnTableActions btnNetwork"
                        data-testid="testIdBtnNetwork"
                        onClick={() => setTableRelationship()}
                        disabled={
                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                            !isRelationshipEnabled ||
                            (lineAnalysisStatus === 'In-Progress') ||
                            (isTableAnalysisGenerated === "In-Progress")
                        }
                    >
                        <MotifTooltip
                            trigger={<img src={showRelationshipIcon ? relationship_red : iconRelationship}
                                alt="Table Relationships" />}

                            placement="bottom"
                            contentClassName="tooltipWithoutClose"
                            allowClick={false}
                            hideCloseButton={true}
                        >
                            {showRelationshipIcon ? (
                                <>There has been a change to the<br />
                                    relationships for this table,<br />
                                    Select a row(s) and click 'Proceed'<br />
                                    to regenerate Line Analysis
                                </>) : (
                                "Relationship"
                            )}

                        </MotifTooltip>
                    </MotifIconButton>
                    <MotifIconButton
                        aria-label="Additional context"
                        className="btnTableActions btnAddContext"
                        data-testid="testIdBtnAddContext"
                        onClick={() => setAdditionalContext()}
                        disabled={
                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                            (lineAnalysisStatus === 'In-Progress') ||
                            (isTableAnalysisGenerated === "In-Progress")
                        }
                    >
                        <MotifTooltip
                            trigger={<img src={showKpiIndIcon ? additinal_context_red : iconAdditionalContext} alt="Additional context" />}
                            placement="bottom"
                            contentClassName="tooltipWithoutClose"
                            allowClick={false}
                            hideCloseButton={true}
                        >
                            {showKpiIndIcon ? (
                                <>There has been a change to <br />
                                    the Additional Context for this table,<br />
                                    Select a row(s) and click 'Proceed'<br />
                                    to regenerate Line Analysis
                                </>) : (
                                "Additional Context"
                            )}

                        </MotifTooltip>
                    </MotifIconButton>
                    {displayAdditionalContextModal &&
                        <AdditionalContext
                            openModal={displayAdditionalContextModal}
                            setOpenModal={setDisplayAdditionalContextModal}
                            closeModal={closeAdditionalContextModal}
                            selectedTable={selectedTable}
                        />
                    }
                    <MotifIconButton
                        aria-label="Click here for Threshold"
                        className='btnTableActions btnThreshold'
                        onClick={() => openThresholdPopover()}
                        data-testid="testIdBtnThreshold"
                        disabled={
                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                            (lineAnalysisStatus === 'In-Progress') ||
                            (isTableAnalysisGenerated === "In-Progress")
                        }
                    >
                        <MotifTooltip
                            trigger={
                                <img src={iconThreshold} alt="Threshold" />
                            }
                            placement="bottom"
                            contentClassName="tooltipWithoutClose"
                            allowClick={false}
                            hideCloseButton={true}
                        >
                            {(lineItemsState?.filter((row) => row.exclude).length > 0) ? "Thresholds updated" : "Thresholds"}
                        </MotifTooltip>
                    </MotifIconButton>
                    {displayTableRelationshipModal &&
                        <TableRelationship
                            openModal={displayTableRelationshipModal}
                            setOpenModal={setDisplayTableRelationshipModal}
                            projectId={projectId}
                            tableSelected={selectedTable}
                            closeModal={closeTableRelationshipModal}
                            tables={tables}
                        />
                    }
                </div>
            </div>
            <div className="tableContent">
                <MotifTabNavigation defaultActiveKey={0} className="tabControl">
                    {vTabs.tableDetails.map((vTab) => (
                        <MotifTabControl
                            key={vTab.id}
                            onClick={() => handleTabClick("Table Details", vTab)}
                            className="btnTabControl"
                        >
                            <span className="tabNavigationHeaders">{vTab.title}</span>

                            {showTableAnalysisIcon && (vTab.elementId === "idTabContentTableAnalysis") && (

                                <MotifTooltip
                                    trigger={<img src={iconWarningCircle} alt='Warning' className="warningIcon" />}
                                    placement="bottom"
                                    contentClassName="tooltipWithoutClose"
                                    hideCloseButton={true}
                                    allowClick={false}
                                >

                                    <div className="tcTooltipTitle">Commentary updates available</div>
                                    <div className="tcTooltipBody">
                                        There has been a change to <br />
                                        the line analysis used to generate<br />
                                        the Table Analysis. Generate the Table<br />
                                        Analysis again to see an updated result.
                                    </div>
                                </MotifTooltip>

                            )}

                            {showLineAnalysisIcon && vTab.elementId === "idTabContentLineAnalysis" && (


                                <MotifTooltip
                                    trigger={<img src={iconWarningCircle} alt='Warning' className="warningIcon" />}
                                    placement="bottom"
                                    contentClassName="tooltipWithoutClose"
                                    hideCloseButton={true}
                                    allowClick={false}
                                >
                                    <div className="tcTooltipTitle">Settings updated</div>
                                    <div className="tcTooltipBody">
                                        There has been a change to the settings<br
                                        />  for the line items in this table since the<br />
                                        Line Analysis was generated. Generate <br />
                                        the Line Analysis again to
                                        <br />see an updated result.
                                    </div>
                                </MotifTooltip>


                            )}
                        </MotifTabControl>
                    ))}
                </MotifTabNavigation>
                <div className="tabContentLineAnalysis" id="idTabContentLineAnalysis">
                    {
                        !isLineCommentaryInitiated &&
                        <>
                            <div className="rowSelectAll">
                                <MotifCheckbox
                                    disabled={isSelectAllDisabled}
                                    checked={isSelectAll}
                                    id="fldSelectAll"
                                    name="fldSelectAll"
                                    onChange={($event) => onChangeSelectAll($event)}
                                    value={isSelectAll}
                                    className="fldSelectAll"
                                    data-testid="testIdSelectAll"
                                >
                                    <div className="selectAllLabel">Select all</div>
                                </MotifCheckbox>
                                <div className="search search-bar">
                                    <MotifFormField>
                                        <MotifSearch
                                            aria-label="Search"
                                            placeholder="Search"
                                            value={searchValue}
                                            onChange={filterLineItems}
                                            searchButtonProps={{ hidden: true }}
                                        />
                                        <img src={searchIcon} alt='Search' className='iconSearch' />
                                    </MotifFormField>
                                </div>
                            </div>
                            {
                                displayTableData?.tableContent?.map((vLineItem) => (
                                    <div className="line-div">
                                        <MotifCheckbox
                                            disabled={
                                                ((rowStatusData?.find((row) => row.rowId === vLineItem.rowId) || {}).status === "In-Progress") ||
                                                ((lineItemsState?.find((row) => row.rowId === vLineItem.rowId) || {}).exclude)
                                            }
                                            checked={vLineItem.selected}
                                            id={"lineItemCheckbox" + vLineItem.rowId}
                                            name="line item checkbox"
                                            onChange={($event) => setUpAnalysisDetails($event, vLineItem)}
                                            value={vLineItem.selected}
                                            className="lineItemCheckbox"
                                        ></MotifCheckbox>
                                        <div className="accordionTitle">{vLineItem.RowHeader}</div>
                                        <div className="status-div">
                                            {((rowStatusData.find((row) => row.rowId === vLineItem.rowId) || {}).status === "In-Progress") &&
                                                !(lineItemsState.find((row) => row.rowId === vLineItem.rowId) || {}).exclude && (
                                                    <MotifTooltip
                                                        trigger={<img src={iconProgressLoader} alt="Loading" className="progress-loader"></img>}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                    >
                                                        Analysis in progress
                                                    </MotifTooltip>
                                                )}

                                            {rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.status
                                                === "Completed" &&
                                                (
                                                    !(lineItemsState.find((row) => row.rowId === vLineItem.rowId) || {}).exclude &&
                                                    <>
                                                        {(
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowdocumentInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowrelationshipInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowkpiInd === 0) && (
                                                                <img src={line_circle_red} alt="Completed"></img>
                                                            )}

                                                        {!(
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowdocumentInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowrelationshipInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowkpiInd === 0) && (
                                                                <img className="icon-check-green" src={iconCheckCircle} alt="Completed"></img>
                                                            )}
                                                    </>
                                                )}

                                            {((rowStatusData.find((row) => row.rowId === vLineItem.rowId) || {}).status === "Failure") &&
                                                !(lineItemsState.find((row) => row.rowId === vLineItem.rowId) || {}).exclude && (
                                                    <MotifTooltip hideCloseButton="true" trigger={
                                                        <img src={warning_error} alt="Error" className="errorMsg"></img>
                                                    } >
                                                        <center> Error in Line Analysis <br />
                                                            Please try again</center>
                                                    </MotifTooltip>
                                                )}
                                            {(lineItemsState.find((row) => row.rowId === vLineItem.rowId) || {}).exclude && (
                                                <MotifTooltip
                                                    trigger={<img src={iconInfo} alt="Help"></img>}
                                                    contentClassName="tooltipWithoutClose"
                                                    allowClick={false}
                                                    hideCloseButton={true}
                                                >
                                                    Row excluded due to applied thresholds
                                                </MotifTooltip>
                                            )}
                                            {
                                                <MotifIconButton
                                                    disabled={rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.status
                                                        !== "Completed"}
                                                    onClick={(e) => handleExpandIconClick(e, vLineItem)}
                                                >
                                                    <img src={expandButton} alt="expand"></img>
                                                </MotifIconButton>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                displayTableData?.tableContent?.length == 0 && (
                                    <div className="parent-nolineitems-div">
                                        <div className="no_nolineitems_div">
                                            <img src={featured_icon} alt='featured_icon' className="no_nolineitems_image" />
                                            <p className="no_nolineitems_text">No line items match the search criteria</p>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                    {
                        isLineCommentaryInitiated &&
                        (
                            <div className="tableAnalysisLoaderDiv">
                                <div>
                                    <img src={iconProgressLoaderCircle} alt='Progress' className="tableAnalysisProgress" />
                                </div>
                                <div className="loaderText">
                                    Line analysis being generated...
                                </div>
                            </div>
                        )
                    }
                </div>
                <div id="viewLineAnalysisPopover">
                    <MotifModal
                        show={showLAPopover}
                        onClose={onLAPopoverClose}
                        size='xl'
                    >
                        <MotifModalHeader
                            closeButtonProps={{
                                'aria-label': 'Close',
                                title: 'Close'
                            }}
                            className="lineAnalysisPopoverHeader"
                        >
                            <div>{currentVLineItem.RowHeader}</div>
                        </MotifModalHeader>
                        <MotifModalBody>
                            <MotifInlineMessage
                                variant="warning"
                                icons={
                                    {
                                        message: <img src={iconWarningInfoCircle} className="iconWarningInfoCircle" alt="Warning" />
                                    }
                                }
                                className="msgAIContainer"
                            >
                                <span className="msgAI">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
                            </MotifInlineMessage>
                            <MotifTabNavigation defaultActiveKey={0} className='tabControl'>
                                {
                                    vTabs.lineAnalysis.map((vTab) =>
                                        <MotifTabControl key={vTab.id} onClick={() =>
                                            handleTabClick('Line Analysis', vTab, currentVLineItem.rowId)}
                                            className='btnTabControl' data-testid="testIdBtnTabControl">
                                            {vTab.title}
                                        </MotifTabControl>
                                    )
                                }
                            </MotifTabNavigation>
                            <div
                                className="tabContentDescription"
                                id={"idTabContentDescription~" + currentVLineItem.rowId}
                            >
                                <div className="contentLayer">
                                    {!displayLineAnalysis('lineDescription', currentVLineItem) &&
                                        <div className='noContentLayer'>
                                            <div className="noContentTitle">No description found</div>
                                            <div className='noContentBody'>Click 'Proceed' and run line commentary to view description</div>
                                        </div>
                                    }
                                    {displayLineAnalysis('lineDescription', currentVLineItem) &&
                                        <>
                                            <div className='genAILayer'>
                                                <MotifIconButton
                                                    aria-label="AI Generated"
                                                    className='genAIActions btnGenAI'
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={iconGenAI} alt='AI Generated' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                    >
                                                        AI generated
                                                    </MotifTooltip>

                                                </MotifIconButton>
                                            </div>
                                            <div className='content'
                                                dangerouslySetInnerHTML={{
                                                    __html: utils.markdownToHtml(extractLineAnalysisData('lineDescription', 'answer')),
                                                }}
                                            ></div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='tabContentQA' id={'idTabContentQA~' + currentVLineItem.rowId}>
                                {!displayLineAnalysis('lineQuestions', currentVLineItem) &&
                                    <div className="contentLayer">
                                        <div className='noContentLayer'>
                                            <div className="noContentTitle">No Q&A found</div>
                                            <div className='noContentBody'>Click 'Proceed' in Line Analysis and enable Q&A in Analysis Settings</div>
                                        </div>
                                    </div>
                                }
                                {displayLineAnalysis('lineQuestions', currentVLineItem) && extractLineAnalysisData('lineQuestions', 'extractions').map((element, index) =>
                                    <div className="contentLayer" key={element.extractionId}>
                                        <div className='content'>
                                            <div className='questionAnswerLayer'>
                                                <div className='questionTitle'>
                                                    <div className='questionText'>Question {index + 1}</div>
                                                    <div className='spacer'></div>
                                                </div>
                                                <div className='questionSubTitle'>
                                                    <div className='questionText'>Question</div>
                                                    <div className='spacer'></div>
                                                    <MotifIconButton
                                                        aria-label="AI Generated"
                                                        className='contentActions btnGenAI'
                                                    >
                                                        <MotifTooltip
                                                            trigger={<img src={
                                                                (element?.extraction_flags?.type === 'AI') ?
                                                                    iconGenAI :
                                                                    iconUser
                                                            } alt='AI Generated' />}
                                                            contentClassName="tooltipWithoutClose"
                                                            allowClick={false}
                                                            hideCloseButton={true}
                                                            allowHover={element?.extraction_flags?.type === 'AI'}
                                                        >
                                                            AI generated
                                                        </MotifTooltip>

                                                    </MotifIconButton>
                                                </div>
                                                <div className='questionContent'>
                                                    {element.extraction}
                                                </div>
                                                <div className='answerTitle'>
                                                    <div className='answerText'>Answer</div>
                                                    <div className='spacer'></div>
                                                    <MotifIconButton
                                                        aria-label="AI Generated"
                                                        className='contentActions btnGenAI'
                                                    >
                                                        <MotifTooltip
                                                            trigger={<img src={
                                                                (element?.answer[0]?.extraction_flags?.type === 'AI') ?
                                                                    iconGenAI :
                                                                    iconUser
                                                            } alt='AI Generated' />}
                                                            contentClassName="tooltipWithoutClose"
                                                            allowClick={false}
                                                            hideCloseButton={true}
                                                            allowHover={element?.answer[0]?.extraction_flags?.type === 'AI'}
                                                        >
                                                            AI generated
                                                        </MotifTooltip>

                                                    </MotifIconButton>
                                                </div>
                                                <div className='answerContent'>
                                                    {element.answer[0]?.extraction}
                                                </div>
                                                <div className='questionAnswerActions'>
                                                    <MotifDropdown
                                                        className="dropdownViewSrcDocs"
                                                        aria-labelledby={"more-dropdown-trigger-vsd-" + element.extractionId}
                                                        handleClickOutside={() => element['isDropdownOpen'] = false}
                                                        id={"more-dropdown-vsd-" + element.extractionId}
                                                        placement="top-placement"
                                                        open={element['isDropdownOpen']}
                                                        trigger={
                                                            <MotifIconButton
                                                                className='btnArrowDown'
                                                                aria-label="More"
                                                                id={"more-dropdown-trigger-vsd-" + element.extractionId}
                                                                onClick={() => toggleVwSrcDocsQA(element)}
                                                                disabled={!(
                                                                    element.answer[0]?.doc_reference &&
                                                                    (element.answer[0]?.doc_reference.length > 0)
                                                                )}>
                                                                <span className='viewSrcDocsText'>View source documents</span>
                                                                <img className="iconViewSrcDocs" src={element['isDropdownOpen'] ? iconArrowUp : iconArrowDown} alt='View Source Dcouments' />
                                                            </MotifIconButton>
                                                        }
                                                    >
                                                        {element.answer[0]?.doc_reference && element.answer[0]?.doc_reference.map((vRef, vIndex) =>
                                                            <div key={vIndex} className={`sourceDocList ${vRef.IsDeleted ? "deleted" : "active"}`} >
                                                                <MotifDropdownItem onClick={() => { }}>{getSourceReference(vRef)}</MotifDropdownItem>
                                                                {vRef.IsDeleted && <MotifTooltip key={vIndex + 1}
                                                                    trigger={<img src={brokenLink} alt="Deleted" />}
                                                                    placement="left"
                                                                    contentClassName=""
                                                                    allowHover={true}
                                                                    allowClick={false}
                                                                    hideCloseButton={true}
                                                                >
                                                                    Document deleted
                                                                </MotifTooltip>}
                                                            </div>
                                                        )}
                                                    </MotifDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className="tabContentCommentary"
                                id={"idTabContentCommentary~" + currentVLineItem.rowId}
                            >
                                <div className="contentLayer">
                                    {!displayLineAnalysis('lineCommentary', currentVLineItem) &&
                                        <div className='noContentLayer'>
                                            <div className="noContentTitle">No commentary found</div>
                                            <div className='noContentBody'>Click 'Proceed' and run line commentary to view commentary</div>
                                        </div>
                                    }
                                    {displayLineAnalysis('lineCommentary', currentVLineItem) &&
                                        <>
                                            <div className='genAILayer'>
                                                <MotifIconButton
                                                    aria-label="Like"
                                                    className='genAIActions btnLike'
                                                    onClick={() => onSubmitFeedabck('Like', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback'))}
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={(getFeedback('Like', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback')) === 1) ? iconThumbsUpFilled : iconThumbsUp} alt='Like' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        Like
                                                    </MotifTooltip>
                                                </MotifIconButton>
                                                <MotifIconButton
                                                    aria-label="Dislike"
                                                    className='genAIActions btnDislike'
                                                    onClick={() => onSubmitFeedabck('Dislike', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback'))}
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={(getFeedback('Dislike', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback')) === -1) ? iconThumbsDownFilled : iconThumbsDown} alt='Dislike' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        Dislike
                                                    </MotifTooltip>
                                                </MotifIconButton>
                                                <MotifIconButton
                                                    aria-label="AI Generated"
                                                    className='genAIActions btnGenAI'
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={iconGenAI} alt='AI Generated' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        AI generated
                                                    </MotifTooltip>
                                                </MotifIconButton>
                                                <MotifDropdown
                                                    className="dropdownMore"
                                                    aria-labelledby={"more-dropdown-trigger-" + currentVLineItem.rowId}
                                                    handleClickOutside={() => toggleDropdownMenuMore(currentVLineItem.rowId, 'Close')}
                                                    id={"more-dropdown-" + currentVLineItem.rowId}
                                                    placement="bottom-right"
                                                    trigger={
                                                        <MotifIconButton
                                                            className='genAIActions btnMore'
                                                            aria-label="More"
                                                            id={"more-dropdown-trigger-" + currentVLineItem.rowId}
                                                            onClick={() => toggleDropdownMenuMore(currentVLineItem.rowId, 'Open')}>
                                                            <img src={iconMore} alt='More' />
                                                        </MotifIconButton>
                                                    }
                                                >
                                                    <MotifDropdownItem onClick={() => onCopy(currentVLineItem.rowId)}>
                                                        <img src={iconCopy} alt="Copy" className='iconCopy' /> Copy
                                                    </MotifDropdownItem>
                                                </MotifDropdown>
                                            </div>
                                            <div className={'content lineCommentary-' + currentVLineItem.rowId}
                                                dangerouslySetInnerHTML={{
                                                    __html: utils.markdownToHtml(extractLineAnalysisData('lineCommentary', 'answer')),
                                                }}
                                            ></div>
                                            <div className='questionAnswerActions'>
                                                <MotifDropdown
                                                    className="dropdownViewSrcDocs"
                                                    aria-labelledby={"more-dropdown-trigger-vsd-" + currentVLineItem.rowId}
                                                    handleClickOutside={() => setVwSrcDocsDropdownId(null)}
                                                    id={"more-dropdown-vsd-" + currentVLineItem.rowId}
                                                    placement="top-placement"
                                                    open={vwSrcDocsDropdownId === currentVLineItem.rowId}
                                                    trigger={
                                                        <MotifIconButton
                                                            className='btnArrowDown'
                                                            aria-label="More"
                                                            id={"more-dropdown-trigger-vsd-" + currentVLineItem.rowId}
                                                            onClick={() => toggleDocsDropdown(currentVLineItem.rowId)}
                                                            disabled={!(
                                                                extractLineAnalysisData("lineCommentary", "doc_reference") &&
                                                                (extractLineAnalysisData("lineCommentary", "doc_reference").length > 0)
                                                            )}>
                                                            <span className='viewSrcDocsText'>View source documents</span>
                                                            <img className="iconViewSrcDocs" src={(vwSrcDocsDropdownId === currentVLineItem.rowId) ? iconArrowUp : iconArrowDown} alt='View Source Dcouments' />
                                                        </MotifIconButton>
                                                    }
                                                >
                                                    {extractLineAnalysisData('lineCommentary', 'doc_reference').map((vDocRef, vDocRefIndex) =>
                                                        <div key={vDocRefIndex} className={`sourceDocList ${vDocRef.IsDeleted ? "deleted" : "active"}`} >
                                                            <MotifDropdownItem onClick={() => { }}>{getSourceReference(vDocRef)}</MotifDropdownItem>
                                                            {vDocRef.IsDeleted && <MotifTooltip key={vDocRefIndex + 1}
                                                                trigger={<img src={brokenLink} alt="Deleted" />}
                                                                placement="left"
                                                                contentClassName=""
                                                                allowHover={true}
                                                                allowClick={false}
                                                                hideCloseButton={true}
                                                            >
                                                                Document deleted
                                                            </MotifTooltip>}
                                                        </div>

                                                    )}
                                                </MotifDropdown>

                                            </div>
                                        </>
                                    }

                                </div>           
                            </div>
                        </MotifModalBody>
                        <MotifModalFooter className="analysis-footer">
                        {showTransferToastLineAnalysis &&
                                    <MotifToast
                                        actionName=""
                                        onClose={() => {
                                            setShowTransferToastLineAnalysis(false);
                                        }}
                                        variant={"success"}
                                        className='notifyToast lineAnalysisToast'
                                        icons={{ toast: <img src={iconSuccess} alt="Success" /> }}
                                    >
                                       Transfer successful
                                    </MotifToast>
                                }
                            {activeTab === commentaryTabId && (
                                <>
                                    <div className="transferToPPT">
                                        <MotifButton variant="secondary"
                                            onClick={() => handleTransferToPPTClick(true)} disabled={showTransferToastLineAnalysis || isButtonDisabled || showToast}  >
                                            <div className="tarnsferbuttoon">
                                                <img src={IconDataTransfer} alt="ppt transferimage" />
                                                <span className="tarnsfertext">Transfer to PPT</span>
                                            </div>
                                        </MotifButton>
                                    </div>
                                </>)
                            }
                        </MotifModalFooter>

                    </MotifModal>
                </div>
                <div className="tabContentTableAnalysis" id="idTabContentTableAnalysis">
                    {!isTableAnalysisGenerated && !(isTableCommentaryInitiated && !isTableCommentaryGenerated) && (<div className="tableAnalysisDiv">
                        <div className="tableAnalysisCommentry">Commentary</div>
                        <div className="tableAnalysisNoFoundTextDiv">
                            <div className="tableAnalysisNoFoundText">No analysis found</div>
                            <div className="tableAnalysisNoFoundClickText">
                                Click on Proceed to generate table commentary
                            </div>
                        </div>
                    </div>)}
                    {((isTableAnalysisGenerated === "In-Progress") || (isTableCommentaryInitiated && !isTableCommentaryGenerated)) && (<div className="tableAnalysisLoaderDiv">
                        <div>
                            <img src={iconProgressLoaderCircle} alt='Progress' className="tableAnalysisProgress" />
                        </div>
                        <div className="loaderText">
                            Table analysis being generated...
                        </div>
                    </div>)}
                    {(isTableAnalysisGenerated === "Completed") && !(isTableCommentaryInitiated && !isTableCommentaryGenerated) && <Commentary selectedTableData={selectedTableData} />}
                    {(isTableAnalysisGenerated === "Failure") && !(isTableCommentaryInitiated && !isTableCommentaryGenerated) && <div className="container">
                        <div className="content">
                            <div className="top-bar">
                                <div><span className="page-title">Commentary</span></div>
                            </div>
                            <div className="body-content">
                                <div>
                                    <img className="img-failure" src={iconError} alt="failure" />
                                </div>
                                <div className="page-title">
                                    Analysis failed due to a technical error
                                </div>
                                <div>
                                    Please click on 'Proceed' and try again
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {
                    showTransferToastTableAnalysis &&
                    <div className="toast-div-commentry">
                        <MotifToast
                            onClose={function noRefCheck() { setShowTransferToastTableAnalysis(false); }}
                            variant="success"
                            icons={{ toast: <img src={iconSuccess} alt="Success" /> }}
                        >
                            Transfer successful
                        </MotifToast>
                    </div>
                }
                {(showNotification) &&
                    <MotifToast
                        actionName=""
                        onClose={() => setShowNotification(false)}
                        variant={notificationType}
                        className='notifyToast'
                        icons={{
                            toast:
                                <img src={
                                    (notificationType === 'success') ? iconCheckCircle :
                                        (notificationType === 'error') ? iconError :
                                            (notificationType === 'warning') ? iconWarning :
                                                iconWhiteCheckCircle
                                } alt={
                                    (notificationType === 'success') ? "Success" :
                                        (notificationType === 'error') ? "Failure" :
                                            (notificationType === 'warning') ? "Warning" :
                                                "Info"
                                }
                                />
                        }}
                    >
                        {notificationMessage}
                    </MotifToast>
                }
                {
                    showTableThresholdModal && <TableThreshold onClose={onThresholdModalClose} receiveThresholdUpdateStatus={receiveThresholdUpdateStatus} ></TableThreshold>
                }
            </div>
            {
                (
                    (selectedTableDetailsTab === vTabIds.tableDetails.lineAnalysis) ||
                    (selectedLineAnalysisTab === vTabIds.lineAnalysis.description) ||
                    (selectedLineAnalysisTab === vTabIds.lineAnalysis.qa) ||
                    (selectedLineAnalysisTab === vTabIds.lineAnalysis.commentary)
                ) &&
                <div className='genericFooter'>
                    <div className='footerInner alignSpaceBetween'>
                        <div className="left-buttons">
                            {(selectedTableDetailsTab === vTabIds.tableDetails.lineAnalysis) &&
                                <>
                                    <MotifLabel className="export-qa">Export/Upload Q&A</MotifLabel>
                                    <MotifTooltip trigger={
                                        <img src={infoIcon} alt="info" className="info-icon" />}
                                        placement="top"
                                        className="export-info"
                                        hideCloseButton={true}
                                    >
                                        Download the Q&A file to<br></br> add, edit or delete the<br></br> Questions and Answers
                                    </MotifTooltip>
                                    <MotifIconButton
                                        aria-label="Click here for download"
                                        onClick={() => handleExportQnAClick()}
                                        className='btnDownload'
                                        disabled={(lineAnalysisStatus === 'In-Progress') || (isTableAnalysisGenerated === "In-Progress") || isLineCommentaryInitiated}
                                    >
                                        <MotifTooltip
                                            trigger={<img src={lineAnalysisStatus !== "Completed" ? iconDownloadDisabled : iconDownload} alt='Download' />}
                                            contentClassName="tooltipWithoutClose"
                                            hideCloseButton={true}
                                            allowClick={false}
                                        >
                                            Export Q&A
                                        </MotifTooltip>
                                    </MotifIconButton>
                                    <MotifIconButton
                                        aria-label="Click here for upload"
                                        onClick={() => uploadQA()}
                                        className='btnUpload'
                                        disabled={(lineAnalysisStatus === 'In-Progress') || exportImportDetails?.Export_Import_Flag || showUploadQnALoader}
                                    >
                                        <MotifTooltip
                                            trigger={<img src={exportImportDetails?.Export_Import_Flag ? iconUploadDisabled : iconUpload} alt='Upload' />}
                                            contentClassName="tooltipWithoutClose"
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            Upload Q&A
                                        </MotifTooltip>
                                    </MotifIconButton>
                                    {displayUploadQAModal &&
                                        <UploadQA
                                            openModal={displayUploadQAModal}
                                            setOpenModal={setDisplayUploadQAModal}setOpenModalForUploadProgress={setFromUploadProcess}
                                            userId={user.username}
                                            projectId={activeProject.project_UUID}
                                            tableId={selectedTable.id}
                                            exportedFileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails.Version + ".xlsm"}
                                            exportImportDetails={exportImportDetails}
                                        />
                                    }
                                </>
                            }
                        </div>
                        <div className="right-buttons">
                            {(selectedTableDetailsTab === 'idTabContentTableAnalysis') ? (
                                <>
                                    <div className="button-group">
                                        <MotifButton className="abc" variant="secondary"
                                            disabled={isTableCommentaryInitiated || !isTableCommentaryGenerated
                                                || showTransferToastTableAnalysis || isButtonDisabled || showToast}
                                            onClick={() => handleTransferToPPTClick(false)} >
                                            <div className="tarnsferbuttoon">
                                                <img src={IconDataTransfer} alt="ppt transferimage" />
                                                <span className="tarnsfertext">Transfer to PPT</span>
                                            </div>
                                        </MotifButton>
                                        <MotifTooltip trigger={
                                            <MotifButton
                                                onClick={() => proceed()}
                                                className="btnProceed"
                                                disabled={
                                                    isProceedBtnDisabled ||
                                                    getTableAnalysisProceedDisableState() ||
                                                    getLineAnalysisProceedDisableState() ||
                                                    getSelectedLineItemsState()

                                                }
                                            >
                                                Proceed
                                            </MotifButton>}
                                            allowHover={
                                                isProceedBtnDisabled ||
                                                getTableAnalysisProceedDisableState() ||
                                                getLineAnalysisProceedDisableState() ||
                                                getSelectedLineItemsState()
                                            }
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            {
                                                getProceedDisabledTooltipText()
                                            }
                                        </MotifTooltip>
                                    </div>
                                </>) : <>
                                <MotifTooltip trigger={
                                    <MotifButton
                                        onClick={() => proceed()}
                                        className="btnProceed"
                                        disabled={
                                            isProceedBtnDisabled ||
                                            getTableAnalysisProceedDisableState() ||
                                            getLineAnalysisProceedDisableState() ||
                                            getSelectedLineItemsState()
                                        }
                                    >
                                        Proceed
                                    </MotifButton>}
                                    allowHover={
                                        isProceedBtnDisabled ||
                                        getTableAnalysisProceedDisableState() ||
                                        getLineAnalysisProceedDisableState() ||
                                        getSelectedLineItemsState()
                                    }
                                    allowClick={false}
                                    hideCloseButton={true}
                                >
                                    {
                                        getProceedDisabledTooltipText()
                                    }
                                </MotifTooltip>
                            </>
                            }
                        </div>
                    </div>
                    {(showLineCommentaryModal || fromUploadProcess) && (
                        <AnalysisSettings
                            initSource={fromUploadProcess ? "upload" : "proceed"}
                            openModal={showLineCommentaryModal || fromUploadProcess}
                            setOpenModal={setShowLineCommentaryModal}
                            setOpenModalForUploadProgress={setFromUploadProcess}
                            setDisplayUploadQAModal={setDisplayUploadQAModal}
                            fromUploadProcess={fromUploadProcess}
                            closeModal={handleCloseModal}
                            onConfirm={handleConfirmClick}
                            lineAnalysisStatus={lineAnalysisStatus}
                            documentList={usedDocumentList}
                            isCallForTableAnalysis={isCallForTableAnalysis}
                            selectedTable={selectedTable}
                            exportedFileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails.Version + ".xlsm"}
                            receiveUploadQnAStatus={receiveUploadQnAStatus}
                        />
                    )}
                    {showExportQnAModal && (
                        <ExportQnA
                            show={showExportQnAModal}
                            onClose={onExportQnAModalClose}
                            fileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails?.Version + ".xlsm"}
                            downloadedBy={exportImportDetails.UserID}
                            downloadedOn={exportImportDetails.ExportDate}
                            previouslyExported={true}
                            showConfirmation={!exportImportDetails?.Export_Import_Flag}
                            receiveExportStatus={receiveExportStatus}
                            tableId={tableId}
                            projectId={projectId}
                        ></ExportQnA>
                    )}
                </div>
            }
        </div>
    );
};

TableDetails.propTypes = {
    tableLAStat: PropTypes.string,
    onCloseSelectedTable: PropTypes.func,
    tables: PropTypes.array
};

export default TableDetails;