/**
 * Toast file created to display a toast when api fails
 * created on 17-09-2024
 *
 */
import React from "react";
import { MotifToast } from "@ey-xd/motif-react";
import { useSelector, useDispatch } from "react-redux";
import "./Toast.scss";
import warningCircle from "../../../assets/images/warning-circle.svg";
import { setShowToast } from "../../../auth/toastSlice.js";

const Toast = () => {
  const dispatch = useDispatch();
  const { showToast, toastVariant } = useSelector((state) => state.toast);

  const handleOnCloseClick = () => {
    dispatch(
      setShowToast({
        showToast: false,
        toastVariant: null,
      })
    );
  };

  return (
    showToast && (
      <div className="errorToast-div">
        <MotifToast
          actionName=""
          icons={{ toast: <img src={warningCircle} alt="Search" /> }}
          onClose={() => handleOnCloseClick()}
          variant={toastVariant}
          className="errorToast"
        >
          <div>
            <span className="errorToast-text1">
              System error <br />
            </span>
            <span className="errorToast-text2">
              Please try again later. If the issue persists, please contact
              support@ey.com
            </span>
          </div>
        </MotifToast>
      </div>
    )
  );
};

export default Toast;
