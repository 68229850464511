import { createSlice } from "@reduxjs/toolkit";

const defaultNewPersonalPromptModel = require("../model/defaultNewPersonalPromptModel.js");
const defaultNewPersonalPrompt = defaultNewPersonalPromptModel.DefaultNewPersonalPromptModel;

const defaultNewCentralPromptModel = require("../model/defaultNewCentralPromptModel.js");
const defaultNewCentralPrompt = defaultNewCentralPromptModel.DefaultNewCentralPromptModel;

const promptSlice = createSlice ({
    name: "prompt",
    
    initialState: {
        personalPrompts: {},
        systemPromptsClient: {},
        systemPromptsTest : {},
        defaultNewPersonalPrompt: JSON.parse(JSON.stringify(defaultNewPersonalPrompt)),
        newPersonalPrompt: JSON.parse(JSON.stringify(defaultNewPersonalPrompt)),
        defaultNewCentralPrompt: JSON.parse(JSON.stringify(defaultNewCentralPrompt)),
        newCentralPrompt: JSON.parse(JSON.stringify(defaultNewCentralPrompt)),
        isValidationErrorOnAddNewPrompt: false
    },

    reducers: {
        setPersonalPrompts: (state, action) => {
            state.personalPrompts = action.payload.personalPrompts
        },

        setCentralPrompts: (state, action) => {
            state.centralPrompts = action.payload.centralPrompts
        },

        setSystemPromptsClient: (state, action) => {
            state.systemPromptsClient = action.payload.systemPromptsClient
        },

        setSystemPromptsTest: (state, action) => {
            state.systemPromptsTest = action.payload.systemPromptsTest
        },

        resetNewPersonalPrompt: (state, action) => {
            state.newPersonalPrompt = action.payload.newPersonalPrompt
        },

        setNewPersonalPromptName: (state, action) => {
            state.newPersonalPrompt.promptName = action.payload.promptName
        },

        setNewPersonalTableType: (state, action) => {
            state.newPersonalPrompt.tableType = action.payload.tableType
        },

        setNewPersonalPrompts: (state, action) => {
            state.newPersonalPrompt.prompts = action.payload.prompts
        },

        setIsValidationErrorOnAddNewPrompt: (state, action) => {
            state.isValidationErrorOnAddNewPrompt = action.payload.isValidationErrorOnAddNewPrompt
        },

        resetNewCentralPrompt: (state, action) => {
            state.newCentralPrompt = action.payload.newCentralPrompt
        },

        setNewCentralPromptName: (state, action) => {
            state.newCentralPrompt.promptName = action.payload.promptName
        },

        setNewCentralTableType: (state, action) => {
            state.newCentralPrompt.tableType = action.payload.tableType
        },

        setNewCentralFullTableContext: (state, action) => {
            state.newCentralPrompt.advancedSetting.additionalContext = action.payload.additionalContext
        },

        setNewCentralPrompts: (state, action) => {
            state.newCentralPrompt.prompts = action.payload.prompts
        }
    }
})

export const { 
    setPersonalPrompts,
    resetNewPersonalPrompt,
    setNewPersonalPromptName,
    setNewPersonalTableType,
    setNewPersonalPrompts,
    setCentralPrompts,
    resetNewCentralPrompt,
    setNewCentralPromptName,
    setNewCentralTableType,
    setNewCentralPrompts,
    setNewCentralFullTableContext,
    setIsValidationErrorOnAddNewPrompt
} = promptSlice.actions;
export const { setSystemPromptsClient, setSystemPromptsTest } = promptSlice.actions;


export default promptSlice.reducer;