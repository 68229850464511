/**
 * Add table relationship modal
 * Created On: 12-07-2024
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifFormField from '@ey-xd/motif-react/FormField';
import { MotifLabel } from '@ey-xd/motif-react';
import MotifSelect, { MotifOption } from '@ey-xd/motif-react/Select';
import MotifAccordion, {
    MotifAccordionTrigger,
    MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifTable from '@ey-xd/motif-react/Table';
import MotifToast from '@ey-xd/motif-react/Toast';

import iconAdd from '../../../../assets/images/add-circle.svg';
import trash from '../../../../assets/images/trash.svg';
import norelation from '../../../../assets/images/no-relation.svg';
import iconWhiteCheckCircle from "../../../../assets/images/white-check-circle.svg";
import iconSuccess from "../../../../assets/images/check-circle.svg";

import './TableRelationship.scss';
import TableService from '../../../../services/tableService';
import DeleteRelationshipConfirmModal from '../deleteRelationship/DeleteRelationshipConfirmModal';


const TableRelationship = ({
    openModal,
    setOpenModal,
    projectId,
    tableSelected,
    closeModal,
    tables
}) => {
    const [selectedTable, setSelectedTable] = useState("");
    const [selectedTableData, setSelectedTableData] = useState();
    const [selectedTableRow, setSelectedTableRow] = useState("");
    const [selectedTables, setSelectedTables] = useState([]);
    const [filteredTables, setFilteredTables] = useState([]);
    const [disableConfirmButton, setDisableConfirmButton] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const tableService = new TableService();
    const [relationRowHeaderList, setrelationRowHeaderList] = useState([]);
    const [filterTableData, setFilterTableData] = useState(null);
    let relationList = [];
    const [relationtableList, setRelationtableList] = useState([]);
    const [showEditToast, setShowEditToast] = useState(false);
    const [showModal, setShowmodal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [selectedRelationshipId, setSelectedRelationshipId] = useState(null);
    const [showAccordian, setShowAccordian] = useState(false);

    useEffect(() => {
        if (tables.length > 0) {
            getTableRelationship();
            onTableNameChange(tableSelected.id);
        }
        // eslint-disable-next-line
    }, []);
    
    /**
      * Function to open a Delete_relationship modal.
      */

    const handleOpenModal = (relationshipId) => {
        setSelectedRelationshipId(relationshipId)
        setShowmodal(true);
    };

    /**
      * Function to close a Delete_relationship modal.
      */ 

    const handleCloseModal = () => {
        setShowmodal(false);

    }
    /**
      * Function to delete a Delete_relationship .
      */

    const handleDelete = async () => {
        const response = await tableService.deleteTableRelationship(selectedRelationshipId);
        if (response) {
            const updatedData = filterTableData.filter(item => item.tableRelationshipId !==
                 selectedRelationshipId);
            setFilterTableData(updatedData);
            setShowmodal(false);
            setShowToast(true);
            getTableRelationship();
            
        }
    
    };

    /**
      * Function will be called when submitting add table relationship.
      */
    const getTableRelationship = async () => {
        const response = await tableService.getTableRelationship(
            tableSelected.id
        );        
        if (response) {            
            loadExistingRelationShipData(response);
            if(response.length===0)
            {
                setShowAccordian(false);
            }
        }

    }

    /**
     * Function to load existing relationship data during popup load
     */
    const loadExistingRelationShipData = (relationData) => {
        if (relationData) {
            const uniqueRowId = [...new Set(relationData.map(item => item.originRowId))];
            for (let i in uniqueRowId) {
                relationList.push({
                    id: uniqueRowId[i],
                    name: tableSelected.tableContent.find(item => item.rowId === uniqueRowId[i]).RowHeader,
                })
            }
            setrelationRowHeaderList(relationList);
            
            let arrayList = [];
            for (let i in relationData) {
                arrayList.push({
                    originRowId: relationData[i].originRowId,
                    tableRelationshipId: relationData[i].tableRelationshipId,
                    targetTableId: relationData[i].targetTableId,
                    tableName: tables.find(item => item.id === relationData[i].targetTableId)?.tableName,
                    targetuploadRange: `'${relationData[i]?.sheet_name}'!${relationData[i].targetuploadRange}`
                })
            }
            setRelationtableList(arrayList);
        }
    }

    /**
   * This assists in configuring column headers.
   */
    const colDefs = [
        {
            headerName: "Table name",
            field: "tableName",
            wrapText: true,
            autoHeight: true,
            flex: 2,
            cellClass: 'relation-column',
            filter: false,
            sortable: false
        },
        {
            headerName: "Table range",
            field: "targetuploadRange",
            wrapText: true,
            autoHeight: true,
            flex: 2,
            type: 'centerAligned',
            cellClass: 'relation-column',
            filter: false,
            sortable: false
        },
        {
            field: "",
            headerName: "Actions",
            wrapText: true,
            width: 120,
            autoHeight: true,
            cellRenderer: (params) => {
                return (
                    <MotifIconButton
                        aria-label="Delete"
                        className="btnDelete"
                        onClick={() => handleOpenModal(params.data.tableRelationshipId)}
                    >
                        <img className='trashrelation' src={trash} alt="Delete" />
                    </MotifIconButton>
                );
            },
            cellClass: 'relation-column-center',
            colId:"actions",
            filter: false,
            sortable: false
        }
    ];

    /**
     * Function will be called when submitting add table relationship.
     */
    const addTableRelationship = async () => {
        setDisableConfirmButton(true);
        let vTables = [];
        for (const element of selectedTables) {
            vTables.push({
                "originRowId": selectedTableRow,
                "targetTableId": element
            });
        }
        const vData = {
            "related_tables": vTables,
            "tableId": selectedTable
        };
        const response = await tableService.addTableRelationship(
            projectId,
            selectedTable,
            vData
        );
        if (response) {
            setDisableConfirmButton(false);
            getTableRelationship();
            gotoStep(1);
            setShowEditToast(true);
            setShowAccordian(true);
        } else {
            setDisableConfirmButton(false);
            closeModal('Failure', response?.output);
        }
    };

    /**
     * Function to populate table name to table relationship data
     * @param {*} data 
     */
    const onTableNameChange = (data) => {
        setSelectedTable(data);
        const vSelectedTableData = tables.filter(element => element.id === data);
        setSelectedTableData(vSelectedTableData[0]);
        const vFileteredTables = tables.filter(element => element.id !== data);
        setFilteredTables(vFileteredTables);
    }

    /**
     * Function to populate table row to table relationship data
     * @param {*} data 
     */
    const onRowChange = (data) => {
        setSelectedTableRow(data);
    }

    /**
     * Function to populate tables to table relationship data
     * @param {*} data 
     */
    const onTablesChange = (data) => {
        var selectClassDivs = document.getElementsByClassName("selectTableList");
        if (selectClassDivs.length > 0) {
            var selectClassDiv = selectClassDivs[0];
            var motifSelectInputButton =
                selectClassDiv.getElementsByClassName("motif-select-input")[0];
            var motifSelectInputTextDiv =
                motifSelectInputButton.getElementsByClassName(
                    "motif-select-input-text"
                )[0];
            if (motifSelectInputTextDiv) {
                setTimeout(() => {
                    motifSelectInputTextDiv.textContent =
                        "Selected tables (" + data.length + ")";
                }, 1);
            }
        }
        setSelectedTables(data);
    }

    /**
     * Function to switch Step
     * Also currently integrated auto suggestions for table details.
     * @param {*} vStep 
     */
    const gotoStep = (vStep) => {
        setSelectedTableRow("");
        setSelectedTables([]);
        setCurrentStep(vStep);
    }

    /**
     * Function while opening a line item
     * @param {*} vLineItem 
     */
    const onOpenLineItem = (vLineItem) => {
        let filteredRelation = relationtableList.filter((item) => {
            return item.originRowId === vLineItem.id;
        });
        setFilterTableData(filteredRelation);
    };

    return (
        <>
            {showModal && (
                <DeleteRelationshipConfirmModal
                    onClose={handleCloseModal}
                    handleDelete={handleDelete}
                />
            )}
            <MotifModal
                show={setOpenModal}
                onClose={() => setOpenModal(!openModal)}
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                className='TableRelationshipModal genericModal modalMotifFooterModal'
                size='xl'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className='addTableRelationshipHeader'
                >
                    <div className='addTableRelationshipHead'>
                        {(currentStep === 1) ? "Table relationships" : "Add table relationship"}
                    </div>
                </MotifModalHeader>
                <MotifModalBody>
                    {(currentStep === 1) &&
                        <>
                            {(relationtableList?.length === 0 && showEditToast === false && showAccordian===false ) &&
                                <>
                                    <div className='bodyheaderdiv'>
                                        <div className='lblSelectTableName'>
                                            Selected Table Name
                                        </div>
                                        <div className='valSelectTableName'>
                                            {tableSelected.tableName}
                                        </div>
                                    </div>
                                    <div className='bodycenterdiv'>
                                        <div className='bodycenterinnerdiv'>
                                            <img className='imageborder' src={norelation} alt="Previous" />
                                        </div>
                                        <div className='bodycenterinnerdivtext'>
                                            No relationships found
                                        </div>
                                        <div className='bodycenterinnerdivbottomtext'><p>To create a relationship for this table,<br /> click
                                            <mark className='addrelation'> ‘Add relationship’</mark></p></div>
                                    </div>
                                </>
                            }
                            {((relationtableList?.length > 0) ||
                                (tableSelected.tableRelationships?.length === 0 && showAccordian)) &&
                                <div className='bodyheaderdiv'>
                                    <div className='lblSelectTableName'>
                                        <div className='lblSelectTableName'>
                                            Selected Table Name
                                        </div>
                                        <div className='valSelectTableName'>
                                            {tableSelected.tableName}
                                        </div>
                                        <div className='relation-accordian'  id="accordianrelation">
                                            {relationRowHeaderList?.map((vLineItem) => (
                                                <MotifAccordion

                                                    id="relationaccordian"
                                                    key={vLineItem.id}
                                                    onOpen={() => onOpenLineItem(vLineItem)}
                                                    style={{
                                                        flex: '1'
                                                    }}
                                                    useChevronIcon={true}
                                                    className='relaccordian'
                                                >
                                                    <MotifAccordionTrigger>
                                                        {vLineItem.name}
                                                    </MotifAccordionTrigger>
                                                    <MotifAccordionContent>
                                                        {filterTableData &&  
                                                            <MotifTable
                                                            rowData={relationtableList.filter(item => item.originRowId === vLineItem.id)}
                                                                columnDefs={colDefs}
                                                                suppressCellFocus
                                                                suppressRowClickSelection
                                                                suppressRowHoverHighlight
                                                                enableSorting={false}
                                                                className='tableRelationship'
                                                            ></MotifTable>       
                                                        }
                                                    </MotifAccordionContent>
                                                </MotifAccordion>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {(currentStep === 2) &&
                        <>
                            <div className='lblSelectTableName'>
                                Selected Table Name
                            </div>
                            <div className='valSelectTableName'>
                                {tableSelected.tableName}
                            </div>
                            <div className='lblSelectRow'>
                                Select Row
                            </div>
                            <div className='fldRowContainer'>
                                <div className='fldRow'>
                                    <MotifFormField>
                                        <MotifSelect
                                            value={selectedTableRow}
                                            onChange={val => onRowChange(val)}
                                            ariaLabelledBy="Table Row"
                                            triggerButtonProps={{
                                                'aria-describedby': 'Table Row'
                                            }}
                                            visibleOptions={3}>
                                            {
                                                (selectedTableData) ?
                                                    selectedTableData?.tableContent?.map((element) =>
                                                        <MotifOption value={element.rowId} key={element.rowId}>{element.RowHeader}</MotifOption>
                                                    ) :
                                                    <MotifOption value="" key="0">Select Row</MotifOption>
                                            }
                                        </MotifSelect>
                                        <MotifLabel className='fldLabel'>
                                            select 1 row to assign a relationship
                                        </MotifLabel>
                                    </MotifFormField>
                                </div>
                            </div>
                            <div className='lblSelectTables'>
                                Select Table(s)
                            </div>
                            <div className='fldTablesContainer'>
                                <div className='fldTables'>
                                    <MotifFormField>
                                        <MotifSelect
                                            className="selectTableList"
                                            multiple
                                            value={selectedTables}
                                            onChange={val => onTablesChange(val)}
                                            ariaLabelledBy="Tables"
                                            triggerButtonProps={{
                                                'aria-describedby': 'Tables'
                                            }}
                                            visibleOptions={3}>
                                            {
                                                (filteredTables) ?
                                                    filteredTables.map((element) =>
                                                        <MotifOption value={element.id} key={element.id}>{element.tableName}</MotifOption>
                                                    ) :
                                                    <MotifOption value="" key="0">Select table(s)</MotifOption>
                                            }
                                        </MotifSelect>
                                        <MotifLabel className='fldLabel'>
                                            Select tables to add relationship with for selected row
                                        </MotifLabel>
                                    </MotifFormField>
                                </div>
                            </div>
                        </>
                    }

                </MotifModalBody>
                <MotifModalFooter className='TableRelationshipFooter'>
                    <div className='tableRelationshipToastLayer'>
                        {showToast && (
                            <MotifToast
                                actionName=""
                                onClose={() => {
                                    setShowToast(false);
                                }}
                                variant="info"
                                className='notifyToast11'
                                icons={{toast: <img src={iconWhiteCheckCircle} alt="Info" />}}
                            >
                                Relationship removed!
                            </MotifToast>
                        )}
                        {(showEditToast) &&
                            <MotifToast
                                actionName=""
                                onClose={() => {
                                    setShowEditToast(false);
                                }}
                                variant={"success"}
                                className='notifyToast'
                                icons={{toast: <img src={iconSuccess} alt="Success" />}}
                            >
                                Relationship added successfully!
                            </MotifToast>
                        }
                    </div>
                    <div className='tableRelationshipFooterleft'>
                        {(currentStep === 1) &&
                            <MotifIconButton
                                className='add-relationship'
                                aria-label="Click here to go back to Project Details"
                                onClick={() => gotoStep(2)}
                                data-testid="testIdBtnBack"              >
                                <img className='addrelationicon' src={iconAdd} alt="Previous" />
                                <p className='add-relation'>Add relationship</p>
                            </MotifIconButton>
                        }
                    </div>
                    <div className='tableRelationshipFooterRight'>
                        <MotifButton
                            onClick={() => setOpenModal(!openModal)}
                            type="button"
                            variant="secondary"
                            className='btnCancel'
                        >
                            {(currentStep === 1) ? "Close" : "Cancel"}
                        </MotifButton>
                        {(currentStep === 2) &&
                            <MotifButton
                                onClick={() => addTableRelationship()}
                                type="button"
                                className='btnConfirm'
                                disabled={
                                    disableConfirmButton ||
                                    !selectedTable ||
                                    !selectedTableRow ||
                                    (selectedTables.length===0)
                                }
                            >
                                Confirm
                            </MotifButton>
                        }
                    </div>
                </MotifModalFooter>
            </MotifModal>
        </>);
};

TableRelationship.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    projectId: PropTypes.string,
    tableSelected: PropTypes.object,
    closeModal: PropTypes.func,
    tables: PropTypes.array
};

export default TableRelationship;