import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { MotifErrorMessage, MotifFormField, MotifInput, MotifLabel, MotifTooltip } from "@ey-xd/motif-react";
import GenericModal from "../GenericModal";
import ThresholdService from "../../../../services/thresholdService";
import TableService from '../../../../services/tableService';
import { setSelectedTable, setTables } from "../../../../auth/tableSlice";
import iconHelp from '../../../../assets/images/help-circle.svg';
import iconError from '../../../../assets/images/error.svg';
import "../../../molecules/modals/tableThreshold/TableThreshold.scss";

export default function TableThreshold(props) {
    
    const { onClose, receiveThresholdUpdateStatus } = props;
    const { activeProject } = useSelector(state => state.user);
    const { selectedTable } = useSelector(state => state.table);
    const projectId = activeProject?.project_UUID;
    const [absoluteThresholdValueOld,] = useState(selectedTable.tableAbsoluteThreshold);
    const [absoluteThresholdValue, setAbsoluteThresholdValue] = useState(selectedTable.tableAbsoluteThreshold);
    const [percentageThresholdValueOld,] = useState(selectedTable.tablePercentageThreshold + "%");
    const [percentageThresholdValue, setPercentageThresholdValue] = useState(selectedTable.tablePercentageThreshold + "%");
    const [isMinimumSatisfied, setIsMinimumSatisfied] = useState(true);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const thresholdService = new ThresholdService();
    const tableService = new TableService();

    
    useEffect(()=>{
        if(
            absoluteThresholdValue && 
            percentageThresholdValue && 
            (
                (parseInt(absoluteThresholdValue) !== parseInt(absoluteThresholdValueOld)) ||
                (percentageThresholdValue !== percentageThresholdValueOld)
            ) &&
            isMinimumSatisfied
        ){
            setIsConfirmButtonDisabled(false);
        }
        else {
            setIsConfirmButtonDisabled(true);
        }
        // eslint-disable-next-line
    }, [isMinimumSatisfied, percentageThresholdValue, absoluteThresholdValue])

    const onAbsoluteThresholdInputChange = (input) => {
        const regex = /^[0-9\b]+$/;
        if (input !== "" && regex.test(input)) {
            setAbsoluteThresholdValue(input);
        } else if (input === "") {
            setAbsoluteThresholdValue("");
        } else if (!regex.test(input)) {
            // do nothing
        }
    }

    const onPercentageThresholdInputChange = (input) => {
        const regex = /^[0-9\b]+$/;
        let value = input.replace('%', '');
        if (value === "") {
            setPercentageThresholdValue("");
        }
        else {
            if (regex.test(value) && !(value > 100)) {
                setPercentageThresholdValue(value);
                if (value < 5) {
                    setIsMinimumSatisfied(false);
                } else {
                    setIsMinimumSatisfied(true);
                }
            }
        }
    }

    const handleInputBlur = (value) => {
        if (value !== "" && !value.includes("%")) {
            setPercentageThresholdValue(value + '%');
        }
    };

    const handleClose = () => {
        onClose();
    }

    const handleConfirm = async () => {
        const payload = {
            "update_table": [
                [
                    selectedTable.id,
                    parseInt(absoluteThresholdValue, 10),
                    parseInt(percentageThresholdValue.replace('%', ''), 10)
                ]
            ]
        };
        setIsConfirmButtonDisabled(true);
        handleClose();
        const response = await thresholdService.updateGlobalThreshold('Table', payload);
        if (response && response[0] && response[0][0] && (response[0][0] === '200')) {
            receiveThresholdUpdateStatus(response[0][0]);

            const tables = await tableService.getTables(projectId);
            if (tables[0]?.table_details) {
                dispatch(setSelectedTable({
                    selectedTable: tables[0].table_details.filter((table) => table.id === selectedTable.id)[0]
                }));
                dispatch(setTables({
                    tables: tables[0].table_details
                }));
            }
        } else {
            receiveThresholdUpdateStatus(response[0][0]);
        }
    }

    const getModalBody = () => {
        return (
            <div>
                <MotifLabel>Table name</MotifLabel>
                <MotifLabel>{selectedTable.tableName}</MotifLabel>
                <br></br>
                <div className='lblAbsThreshold'>
                    <MotifLabel>Absolute threshold</MotifLabel>
                </div>
                <div className='fldAbsThreshold'>
                    <MotifFormField className="field-group">
                        <MotifInput
                            className="inputThreshold"
                            onChange={(e) => onAbsoluteThresholdInputChange(e.target.value, true)}
                            type='text'
                            placeholder="Enter Threshold"
                            value={absoluteThresholdValue}
                            hideClearButton={true}
                        />
                        <MotifTooltip
                            className="threshold-tooltip"
                            trigger={
                                <img src={iconHelp} alt='Help' className='helpTooltipIcon' />
                            }
                            hideCloseButton={true}
                            allowClick={false}
                        >
                            <div className="tooltipAbsThresholdTitle">Absolute Threshold</div>
                            <div className="tooltipAbsThrehsoldBody">
                                The number should be written as a whole value <br />
                                and will apply in absolute terms, e.g. if <br />
                                100,000 is set as the threshold, this will apply <br />
                                to both positive and negative values in a table. <br /><br />
                                The threshold will be set across all periods <br />
                                presented in a table, e.g. if 100,000 is set as <br />
                                the threshold, then any rows in the table where <br />
                                the value in each period is less than 100,000 <br />
                                will be excluded.
                            </div>
                        </MotifTooltip>
                    </MotifFormField>
                </div>
                <div className='lblPercThreshold'>
                    <MotifLabel>Percentage threshold</MotifLabel>
                </div>
                <div className='fldPercThreshold'>
                    <MotifFormField className={(!isMinimumSatisfied && percentageThresholdValue !== '') ? 'field-group motif-input-invalid' : 'field-group'}>
                        <MotifInput
                            className="inputThreshold"
                            onChange={(e) => onPercentageThresholdInputChange(e.target.value)}
                            onBlur={(e) => handleInputBlur(e.target.value)}
                            type='text'
                            placeholder="Enter Percentage"
                            value={percentageThresholdValue}
                            hideClearButton={true}
                        />
                        {!isMinimumSatisfied && percentageThresholdValue !== '' &&
                            <img src={iconError} alt='Error' className='errorTooltipIcon' />
                        }
                        {(isMinimumSatisfied || percentageThresholdValue === '') &&
                            <MotifTooltip
                                trigger={
                                    <img src={iconHelp} alt='Help' className='helpTooltipIcon' />
                                }
                                hideCloseButton={true}
                                allowClick={false}
                            >
                                <div className="tooltipAbsThresholdTitle">Percentage threshold</div>
                                <div className='tooltipAbsThrehsoldBody'>
                                    Minimum 5%
                                    <br />
                                    <br />
                                    The value may reflect an increase or<br /> decrease in value presentation. This can be<br /> adjusted for specific tables at a later stage in<br /> the project.
                                </div>
                            </MotifTooltip>
                        }
                    </MotifFormField>
                    {!isMinimumSatisfied && percentageThresholdValue !== '' &&
                        <MotifErrorMessage className='tablePercThreshold errorText'>
                            Threshold setting too low, please enter minimum 5%
                        </MotifErrorMessage>
                    }
                </div>
            </div>
        )
    }

    return (
        <GenericModal
            modalProperties={{
                show: true,
                modalType: "basic",
                title: "Thresholds",
                body: getModalBody(),
                customCloseButtonTitle: "Close",
                rightButtonVariant: "primary",
                leftButtonVariant: "secondary",
                rightButtonSize: "medium",
                leftButtonSize: "medium",
                leftButtonText: "Cancel",
                rightButtonText: "Confirm",
                footerCssClass: "modal-buttons",
                closeHandler: handleClose,
                leftButtonHandler: handleClose,
                rightButtonHandler: handleConfirm,
                rightButtonDisabled: isConfirmButtonDisabled
            }}
        >
        </GenericModal>
    )

} 