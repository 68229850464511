/**
 * Documents Modal
 * Created On: 06-09-2024
 */
import React from 'react';
import PropTypes from 'prop-types';
//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifButton from '@ey-xd/motif-react/Button';
import MotifFormField from '@ey-xd/motif-react/FormField';
import { MotifTooltip } from "@ey-xd/motif-react";
import page from "../../../../assets/images/page.svg"
import brokenLink from '../../../../assets/images/broken-link.svg'
import './Documents.scss';

let allowedFileNameLenth = 30;

const Documents = ({
    openModal,
    setOpenModal,
    filterDocuments,
    filterdelDocumentList
}) => {

    const isTrancReq = (docid) => {
        const element = document.getElementById(docid);
        let clientWidth = element?.clientWidth;
        let scrollWidth = element?.scrollWidth;
        if (clientWidth && scrollWidth && scrollWidth > clientWidth) {
            //For large file name
            return true;
        }
        else {
            //For normal file name
            return false;
        }
    };


    const getDocExt = (docName) => {
        let ext = docName.split('.').pop();
        if(ext === docName)
        {
            return '';
        }
        return ext;
    };

    const truncateFileName = (docid, docName) => {
        if (isTrancReq(docid)) {
            //Large File name
            let tempFile = docName.substring(0, docName.lastIndexOf('.'));
            return tempFile;
        }
        else {
            return docName;
        }
    };

    return (
        <div>
            <MotifModal
                show={setOpenModal}
                onClose={() => setOpenModal(!openModal)}
                className='docModal genericModal modalMotifFooterModal'
                size='xl'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className='docHeader'
                >
                    <div className='docHead'>Documents</div>
                </MotifModalHeader>
                <MotifModalBody className='docModalBody'>
                    <MotifFormField>
                        <div className="documentList ">
                            {filterDocuments.map((doc, i) => (
                                <div key={i} className={`doc-list ${doc.docName?.length > allowedFileNameLenth ? "lengthy" : "normal"}`}>
                                    <div className={`document-item ${(filterdelDocumentList.indexOf(doc.id) > -1) ? "deleted" : "active"}`}>
                                        <MotifTooltip
                                            hide={!isTrancReq(doc.id)}
                                            trigger={
                                                <>
                                                    <div id={doc.id} className="document-name textEllipsis"><img src={page} alt='Document' />
                                                        {truncateFileName(doc.id, doc.docName)}
                                                    </div>
                                                    {isTrancReq(doc.id) && <div>...{getDocExt(doc.docName)}</div>}
                                                    <div className="document-status" >
                                                        {(filterdelDocumentList.indexOf(doc.id) > -1) && <MotifTooltip
                                                            hide={false}
                                                            trigger={<img src={brokenLink} alt='Deleted' />}
                                                            placement="left"
                                                            contentClassName=""
                                                            allowHover={true}
                                                            allowClick={false}
                                                            hideCloseButton={true}
                                                        >
                                                            Document deleted
                                                        </MotifTooltip>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            placement="bottom"
                                            contentClassName="tooltipWithoutCloseRow"
                                            allowHover={true}
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            {doc.docName}
                                        </MotifTooltip>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </MotifFormField>
                </MotifModalBody>
                <MotifModalFooter className='docFooter'>
                    <MotifButton className='btnNext'
                        onClick={() => {
                            setOpenModal(!openModal);
                        }
                        }
                    >
                        Close
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </div>
    );
};
Documents.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    filterDocuments: PropTypes.array,
    filterdelDocumentList: PropTypes.array
};
export default Documents;