/**
 * Copy Central Prompt To Personal 
 * Created On: 21-08-2024
 */

import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React, {  useState,useEffect } from "react";
import MotifFormField from '@ey-xd/motif-react/FormField';
import { MotifInput, MotifLabel, MotifErrorMessage} from "@ey-xd/motif-react";
import "./CopyCentralModal.scss"
import iconError from "../../../../assets/images/error.svg"
import { useSelector } from "react-redux";
import PromptLibraryService from '../../../../services/promptLibraryService';


const CopyCentralModal = ({ onClose, handleConfirm }) => {
    const [promptName, setPromptName] = useState("");
    const [isErrorOnPromptName, setIsErrorOnPromptName] = useState(false);
    const [errorMsgPromptName, setErrorMsgPromptName] = useState("");
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);
     //  Error Messages
    const errMsgAlphaNumWithMax50Chars = "Please enter alphanumeric name, up to 50 characters.";
    const errMsgDuplicateTable = "The name is already in use. Please choose another.";
    const promptLibraryService = new PromptLibraryService();
    const [personalPrompts, setPersonalPrompts] = useState([]);
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        getPersonalPrompts(user.username);
        // eslint-disable-next-line
    }, [user])

    const onConfirmClick = () => {
        handleConfirm(promptName);
    }

    const onPromptNameChange = (data) => {
        setPromptName(data);   
        //  Check for Alphanumeric with space & maximum 50 characters validation
        const vValid = /^[a-zA-Z0-9 ]*$/.test(data);
        if (
            (data === '') ||
            !vValid ||
            (data.length > 50)
        ) {
            setIsErrorOnPromptName(true);
            setErrorMsgPromptName(errMsgAlphaNumWithMax50Chars);
            setDisableConfirmButton(true);
            return;
        }
        const filteredTable = personalPrompts?.length > 0 ? 
            (personalPrompts?.filter(prompt => (prompt.promptName.trim().toLowerCase() === data.trim().toLowerCase()))) : null; 
            if (filteredTable && filteredTable.length > 0) {
            setIsErrorOnPromptName(true);
            setErrorMsgPromptName(errMsgDuplicateTable);
            setDisableConfirmButton(true);
            return;
        }
        setIsErrorOnPromptName(false);
        setErrorMsgPromptName("");
        setDisableConfirmButton(false);
    }

     /**
     * Function to get personal prompts associated with user
     */
     const getPersonalPrompts = async (userId) => {
        const vPersonalPrompts = await promptLibraryService.getPersonalPrompts(userId);
        setPersonalPrompts(vPersonalPrompts);
    }


    return (
        <div>
        <MotifModal id="copyCentralModal" show={true}
            size="md"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'none',
                },
            }}
            onClose={onClose}
            className="copyCentralModal genericModal modalMotifFooterModal"
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
                className="copyCentralHeader"
            >
                <div>Copy to Personal</div>
            </MotifModalHeader>
            <MotifModalBody className="copyCentralBody">
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel'>
                        Prompt Name
                    </MotifLabel>
                    <MotifInput
                        onChange={(e) => onPromptNameChange(e.target.value)}
                        name="promptName"
                        type='text'
                        value={promptName}
                        className={isErrorOnPromptName ? 'motif-input-invalid' : ''}
                        hideClearButton={!!isErrorOnPromptName}
                    />
                    {(isErrorOnPromptName) &&
                        <>
                            <img src={iconError} alt='Error' className='iconError topPosPromptName' />
                            <MotifErrorMessage className='errorText'>
                                <div>{errorMsgPromptName}</div>
                            </MotifErrorMessage>
                        </>
                    }
                    {(!isErrorOnPromptName) &&
                        <div className='fldDetails'>
                            Please enter alphanumeric name, up to 50 characters.
                        </div>
                    }
                </MotifFormField>
            </div>
            </MotifModalBody>
            <MotifModalFooter className="motif-modal-footer">
                <MotifButton
                    label="btnCancel"
                    size="small"
                    type="button"
                    variant="secondary"
                    className="btnCancel"
                    onClick={onClose}
                >
                    Cancel
                </MotifButton>
                <MotifButton
                            label="btnConfirm"
                            size="small"
                            type="button"
                            variant="primary"
                            className="btnConfirm"
                            disabled={disableConfirmButton}
                            onClick={onConfirmClick}
                        >
                            Confirm
                </MotifButton>               
            </MotifModalFooter>
        </MotifModal>
    </div>
    );
};
export default CopyCentralModal;