import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import { MotifButton } from "@ey-xd/motif-react";
import MotifToast from '@ey-xd/motif-react/Toast';
import iconArrowBack from "../../../assets/images/arrow_back.svg";
import iconCheckCircleSuccess from '../../../assets/images/check-circle.svg';
import LoaderComponent from '../../molecules/loader/LoaderComponent';
import PromptLibraryService from '../../../services/promptLibraryService'; 
import "./PersonalPromptDetails.scss";
import EditPersonalPrompt from "../../molecules/forms/editPersonalPrompt/EditPersonalPrompt";
import { setPersonalPrompts as setPersonalPromptsToStore } from "../../../auth/promptSlice";

function PersonalPromptDetails() {
    const { id } = useParams();
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [personalPrompt, setPersonalPrompt] = useState();
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
    const promptLibraryService = new PromptLibraryService();
    const [showEditPersonalPrompt, setShowEditPersonalPrompt] = useState(false);
    const [showPromptSaveSuccessToast, setShowPromptSaveSuccessToast] = useState(false);

    useEffect(() => {
        setIsLoadingInProgress(true);
        let responsePrompt = {};
        async function getPrompt() {
            responsePrompt = await promptLibraryService.getPersonalPromptById(id);
            setPersonalPrompt(responsePrompt[0]);
        };
        getPrompt();
        setIsLoadingInProgress(false);
        // eslint-disable-next-line
    }, [id, showEditPersonalPrompt])

    const handleEditPrompt = () => {
        setShowEditPersonalPrompt(true);
    }

    /**
     * Function to get personal prompts associated with user
     */
    const getPersonalPrompts = async (userId) => {
        const vPersonalPrompts = await promptLibraryService.getPersonalPrompts(userId);
        dispatch(setPersonalPromptsToStore({
            personalPrompts: vPersonalPrompts
        }));
    }

    const handleSavePrompt = async (updatedPrompt) => {
        setIsLoadingInProgress(true);
        const response = await promptLibraryService.savePersonalPrompt(updatedPrompt);
        if(user) {
            getPersonalPrompts(user.username);
        }
        setIsLoadingInProgress(false);
        setShowEditPersonalPrompt(false);
        if(response?.status === 200) {
            setShowPromptSaveSuccessToast(true);
        }
    }

    return (
        <React.Fragment>
            <LoaderComponent show={isLoadingInProgress} />
            <div className="prompt-details">
                <div className="prompt-div">
                    <MotifIconButton
                        aria-label="Click here to go back to Personal Prompt Library"
                        onClick={() => navigate(-1)}
                        data-testid="testIdBtnBack"
                    >
                        <img src={iconArrowBack} alt='Go to previous page' />
                    </MotifIconButton>
                    <span className='prompt-name'>{personalPrompt?.tableType}</span>
                </div>
                <div className="prompt-container">
                {
                    showEditPersonalPrompt ? (
                        <div className="view-edit-prompt">
                            <EditPersonalPrompt 
                                personalPrompt={personalPrompt}
                                setShowEditPersonalPrompt={setShowEditPersonalPrompt}
                                handlePromptSave={handleSavePrompt} />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="view-edit-prompt">
                                <div className="field-label">Prompt Name</div>
                                <div className="field-value">{personalPrompt?.promptName}</div>
                                {
                                    personalPrompt?.prompts?.map((prompt) => (
                                        <React.Fragment key={prompt.id}>
                                            <div className="field-label">{prompt.promptMethodName}</div>
                                            <div className="field-value">{prompt.promptBody}</div>
                                        </React.Fragment>
                                    ))
                                }
                                {
                                    showPromptSaveSuccessToast &&
                                    <MotifToast
                                        variant="success"
                                        onClose={() => {
                                            setShowPromptSaveSuccessToast(false);
                                        }}
                                        icons={{toast:<img src={iconCheckCircleSuccess} alt="Success" />}}
                                        className='notifyToast'
                                    >
                                        Prompt saved successfully
                                    </MotifToast>
                                }
                            </div>
                            <div className='genericFooter'>
                                <div className='footerInner alignEnd'>
                                    <MotifButton onClick={handleEditPrompt} className='' type="button" variant="secondary">
                                        Edit Prompt
                                    </MotifButton>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
                </div>
            </div>
        </React.Fragment>
    );
}

export default PersonalPromptDetails;