import React from "react";
import { useSelector } from "react-redux";

/**
 * Motif imports
 */
import { MotifButton } from "@ey-xd/motif-react";

import './NoProjects.scss'
import searchIcon from '../../../assets/images/search.svg';

export default function NoProjects() {

    let platformUrl = process.env.REACT_APP_PLATFORMURL;
    const { searchProjectValue } = useSelector(state => state.user);

    const redirectToPlatform = () => {
        window.open(platformUrl, '_blank');
    }

    return (
        <div className="parent-noprojects-div">
            {
                !searchProjectValue &&
                <div>
                    <div className="parent-search-icon-div">
                        <div className="search-icon-div">
                            <img src={searchIcon} alt="Search" />
                        </div>
                    </div>
                    <div className="parent-noprojects-text-div">
                        No Projects Created
                    </div>
                    <div className="parent-statement-div">
                        You will need access to a project on<br /> Digital Diligence Assistant to get started.
                    </div>
                    <div className="parent-accessplatform-div">
                        <MotifButton onClick={() => { redirectToPlatform() }}>Access Platform</MotifButton>
                    </div>
                </div>
            }
        </div>
    )
}