/**
 * Header control to show logged in users details and image
 * ! Create some test cases
 */

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";

import { MotifTextLink, MotifTooltip } from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";

import logOut from "../../../assets/images/log-out.svg";
import defaultUserIcon from "../../../assets/images/default-user-icon.jpg";
import iconGenAI from '../../../assets/images/sparks.svg';

import "./Header.scss";
import { setUserData } from "../../../auth/userSlice";
import { setLoggedOut } from "../../../auth/authSlice";
import GraphService from "../../../services/graphService";
import AIGuidance from "../modals/aiGuidance/AIGuidance";

export function Header() {

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userAvatarURL, setUserAvatarURL] = useState();
  const [displayAIGuidanceModal, setDisplayAIGuidanceModal] = useState(false);

  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const ref = useRef();

  /**
   * Show's logged users card
   */
  const showLoggedInUserMenu = () => {
    setShowUserMenu(true);
  };

  /**
   * Hook to handle click outside to close profile dropdown
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowUserMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  /**
   * Hook to get profile photo
   */
  useEffect(() => {
    getAvatarByEmail(user?.username);
    // eslint-disable-next-line
  }, []);

  /**
   * Retrieves the user's avatar based on their email address.
   * If an avatar is available, sets the URL for display; otherwise, uses a default user icon.
   * @param {string} email - The user's email address.
   */
  async function getAvatarByEmail(email) {
    const graphService = new GraphService();
    const userData = await graphService.getUserDetailsByEmail(email);
    dispatch(setUserData({
      userData: userData
    }))
    const userId = userData?.value[0]?.id;

    if (userId) {
      const userAvatarBlob = await graphService.getUserAvatar(userId);
      if (userAvatarBlob) {
        const blob = new Blob([userAvatarBlob], { type: "image/jpeg" });
        setUserAvatarURL(URL.createObjectURL(blob));
      } else {
        setUserAvatarURL(defaultUserIcon);
      }
    } else {
      setUserAvatarURL(defaultUserIcon);
    }
  }

  /**
   * Description
   * -----------
   /**
 * Handles user logout by sending a logout request to the authentication instance.
 * @param {Event} e - The event object (e.g., button click event).
   */
  const handleLogOut = (e) => {
    e.preventDefault();
    const logoutRequest = {
      account: user,
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);
    dispatch(setLoggedOut());
  };

  const platformURL = process.env.REACT_APP_PLATFORMURL;

  /**
   * Open AI Guidance modal
   */
  const openAIGuidance = () => {
    setDisplayAIGuidanceModal(true);
  };
  
  /**
   * Close AI Guidance modal
   */
  const closeAIGuidance = () => {
    setDisplayAIGuidanceModal(false);
  };

  return (
    <div className="header" id="header">
      <div className="headercontainer">
        <div className="logomenu ">
          <div className="projectTitle">
            <MotifTextLink href={platformURL} target="_blank">
              Access Platform
            </MotifTextLink>
          </div>
        </div>
        <div className="profileImageContainer ">
          <MotifIconButton
            aria-label="AI Guidance"
            className="btnTableActions"
            onClick={() => openAIGuidance()}
          >
            <MotifTooltip
                trigger={<img src={iconGenAI} alt="AI Guidance" />}
                placement="bottom"
                contentClassName="tooltipWithoutClose tooltipTopPos"
                allowClick={false}
                hideCloseButton={true}
            >
              AI Guidance
            </MotifTooltip>
          </MotifIconButton>
          {displayAIGuidanceModal &&
              <AIGuidance
                  openModal={displayAIGuidanceModal}
                  setOpenModal={setDisplayAIGuidanceModal}
                  closeModal={closeAIGuidance}
              />
          }
          <div className="NavDropdown">
            <div className="Dropdown">
              {showUserMenu && (
                <div className="headerContainer" ref={ref}>
                  <div className="userMenupopupcontainer" >
                    <div className="userMenuPopup">
                      <div className="usermenuwidthscratch">
                        <div className="usermenuwidth">
                          <div className="userMenuCont">
                            <div className="usermenuchane">
                              <div className="usermenushow">
                                <div className="displaymenu">
                                  <div className="userMenuAvatar">
                                    {}
                                    {
                                      <div className="userAvatarUserMenuItem">
                                        {" "}
                                        <span>
                                          {user?.name}
                                        </span>
                                        <div className="userAvatarEmailMenuItem">
                                          {user?.username}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <hr className="logoutDivider"></hr>
                                  <div className="logoutcontainer">
                                    <img
                                      className="logoutimg"
                                      alt="icon"
                                      src={logOut}
                                    />
                                    <a
                                      href="##"
                                      onClick={handleLogOut}
                                      className="logout"
                                      target="_blank"
                                      rel="noopeer noreferrer"
                                    >
                                      {" "}
                                      Log out
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div id="divUserAvatar" className="Avatar">
                <MotifIconButton
                  aria-label="Profile"
                  onClick={() => showLoggedInUserMenu()}
                  className="btnAvatar"
                >
                  <img
                    alt="Avatar"
                    src={userAvatarURL}
                    className="userAvatar"
                  />
                </MotifIconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
