import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

/**
 * Motif imports
 */
import { MotifButton, MotifFormField } from "@ey-xd/motif-react";
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/TabNavigation';
import MotifToast from '@ey-xd/motif-react/Toast';
import MotifSearch from '@ey-xd/motif-react/Search';

/**
 * Internal imports
 */
import refresh from '../../../assets/images/refresh.svg';
import iconCheckCircle from "../../../assets/images/check-circle.svg";
import searchIcon from '../../../assets/images/search.svg';
import iconWarning from "../../../assets/images/warning-triangle.svg";
import ProjectService from "../../../services/projectService";
import './Home.scss';
import PaansAcknowledge from "../paansAcknowledge/paansAcknowledge";
import PaansService from "../../../services/paansService";
import { setUserProjects, setPlatformAdminUsers, setSearchProjectValue } from "../../../auth/userSlice";
import GraphService from "../../../services/graphService";
import iconProgressLoaderCircle from "../../../assets/images/Loading_indicator_circles.svg";

export default function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const projectService = new ProjectService();
    const [activeTab, setActiveTab] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [listOfProjects, setListOfProjects] = useState(false);
    const [loadingInProgress, setLoadingInProgress] = useState(false);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [refreshStatus, setRefreshStatus] = useState(null);
    const [showToastMessage, setShowToastMessage] = useState(false);
    const [paansAcknowledge, setPaansAcknowledge] = useState(true);
    const [showPaansToastMessage, setShowPaansToastMessage] = useState(false);
    const isPageRendered = useRef(false);
    const { user } = useSelector(state => state.user);

    const privacyNoticeUrl = process.env.REACT_APP_PRIVACY_NOTICE;
    const engagementGuidanceUrl = process.env.REACT_APP_ENGAGEMENT_GUIDANCE;

    /**
     * Function will trigger to check the PAANS acknowledgment from Page load and Refersh button
     * @param {*} refresh 
     */
    const getPaansAcknowledgeStatus = async (refresh) => {
        setShowRefreshButton(true);
        const paansService = new PaansService();
        const paansResponse = await paansService.getPaansAcknowledgeStatus(user?.username);
        setPaansAcknowledge(paansResponse?.hasAccepted);
        if (paansResponse && !paansResponse.hasAccepted && isPageRendered.current) {
            setShowPaansToastMessage(true);
        } else {
            await getProjects();
            if (refresh) {
                setShowToastMessage(true);
                setShowPaansToastMessage(false);
            }
        }
        isPageRendered.current = true;
    };

    useEffect(() => {
        getPlatformAdminList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user) {
            getPaansAcknowledgeStatus();
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        const asyncGetProjects = async () => {
            if (paansAcknowledge) {
                const pathname = window.location.pathname;
                if (pathname === "/home/projects") {
                    setActiveTab("projectsTab");
                    document.getElementById("projectsTab").click();
                    setShowRefreshButton(true);
                } else if (pathname === "/home/promptLibrary") {
                    setActiveTab("promptLibraryTab");
                    document.getElementById("promptLibraryTab").click();
                }
                setShowPaansToastMessage(false);
            }
        };
        asyncGetProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paansAcknowledge]);

    /**
    * Retrieves the platform admin list by access token.
    */
    async function getPlatformAdminList() {
        const graphService = new GraphService();
        const platformAdminUsers = await graphService.getPlatformAdminList();
        if (platformAdminUsers) {
            dispatch(setPlatformAdminUsers({
                platformAdminUsers: platformAdminUsers
            }))
        }
    }

    const filterProjects = async (e) => {
        setSearchValue(e.target.value);
        dispatch(setSearchProjectValue({ searchProjectValue: e.target.value }));
        let filteredProjectlist = listOfProjects.filter((item) => {
            return item.projectName
                .toLowerCase().trim()
                .includes(e.target.value.trim().toLowerCase());
        });
        if (filteredProjectlist.length > 0) {
            dispatch(setUserProjects({ projects: filteredProjectlist }));
        } else {
            dispatch(setUserProjects({ projects: [] }));
        }
        setLoadingInProgress(false);
        setRefreshStatus("success");
    };

    const getProjects = async () => {
        try {

            setLoadingInProgress(true);
            const projects = await projectService.GetProjectMasterData(user?.username, []);
            if (projects) {
                setListOfProjects(projects);
                dispatch(setUserProjects({
                    projects: projects
                }));
            }
            setLoadingInProgress(false);
            setRefreshStatus("success");
        }
        catch (e) {
            setLoadingInProgress(false);
            setRefreshStatus("error");
        }
    };

    const handleTabClick = (tab) => {
        const bodySectionLayer = document.getElementsByClassName('body-section')[0];
        switch (tab) {
            case "Projects":
                setActiveTab("projectsTab");
                setShowRefreshButton(true);
                navigate("projects");
                if (bodySectionLayer) {
                    bodySectionLayer.style.height = 'revert-layer';
                }
                break;
            case "PromptLibrary":
                if (activeTab !== "promptLibraryTab") {
                    setActiveTab("promptLibraryTab");
                    setShowRefreshButton(false);
                    navigate("promptLibrary");
                    if (bodySectionLayer) {
                        bodySectionLayer.style.height = 'auto';
                    }
                }
                break;
            default:
        }
    };

    /**
     * Function will handle refresh of PAANS and projects
     * @param {*} refresh 
     */
    const refreshProjectsList = async () => {
        getPaansAcknowledgeStatus(true);
    };

    return (
        <React.Fragment>
            {paansAcknowledge && (
                <>
                    <div className="tab-section">
                        <MotifTabNavigation className={"tab-nav"} variant="alt">
                            <MotifTabControl id="projectsTab" onClick={() => handleTabClick("Projects")}>
                                <span className={activeTab === "projectsTab" ? "tab-button tab-active" : "tab-button"}>Projects</span>
                            </MotifTabControl>
                            <MotifTabControl id="promptLibraryTab" onClick={() => handleTabClick("PromptLibrary")}>
                                <span className={activeTab === "promptLibraryTab" ? "tab-button tab-active" : "tab-button"}>Prompt Library</span>
                            </MotifTabControl>
                        </MotifTabNavigation>
                    </div>
                    {
                        (activeTab === "projectsTab") &&
                        <div className="search-section">
                            <MotifFormField>
                                <MotifSearch
                                    aria-label="Search"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={filterProjects}
                                    searchButtonProps={{ hidden: true }}
                                />
                                <img src={searchIcon} alt='Search' className='iconSearch' />
                            </MotifFormField>
                        </div>
                    }
                    <div className="body-section spinner-parent">
                        {loadingInProgress ? (
                            <div className="loaderDiv">
                                <div>
                                    <img src={iconProgressLoaderCircle} alt='Progress' className="refreshIcon " />
                                </div>
                                <div className="refreshListText">
                                    Refreshing List...
                                </div>
                            </div>) : <Outlet />
                        }
                    </div>
                </>
            )}
            {!paansAcknowledge && <PaansAcknowledge></PaansAcknowledge>}
            <div className="genericFooter">
                {location.pathname.indexOf("promptLibrary") === -1 &&
                    <div className="footerInner">
                        <div className="footerLinks textEllipsis">
                            <a href={privacyNoticeUrl}
                                className="footerLink textEllipsis">Privacy Notice </a>
                            <span className="privacyNoticeSeparator"> | </span>
                            <a href={engagementGuidanceUrl}
                                className="footerLink textEllipsis">Engagement Management Guidance</a>
                        </div>

                        {
                            showRefreshButton &&
                            <MotifButton className="refreshButton" variant="secondary" id="btnRefreshProjectsList" onClick={() => { refreshProjectsList() }} type="button">
                                <img className="refresh-icon" src={refresh} alt="refresh peojects icon" />{/*
                            */}
                                {!paansAcknowledge && (
                                    "Refresh"
                                )}
                                {paansAcknowledge && (
                                    <span className="textEllipsis">Refresh</span>
                                )}
                            </MotifButton>
                        }
                    </div>
                }
            </div>
            {
                showToastMessage && activeTab === "projectsTab" && <div className="toast-div">
                    <MotifToast
                        onClose={function noRefCheck() { setShowToastMessage(false); }}

                        icons={{
                            ...(refreshStatus === "success" && {
                                toast: <img src={iconCheckCircle} alt="Success" />
                            })
                        }}
                        variant={refreshStatus}

                    >
                        {refreshStatus === "success" ? "Refresh Complete" : "List could not be refreshed"}
                    </MotifToast>
                </div>
            }
            {showPaansToastMessage && (
                <div className="toast-div">
                    <MotifToast
                        onClose={function noRefCheck() {
                            setShowPaansToastMessage(false);
                        }}
                        variant="warning"
                        icons={{ toast: <img src={iconWarning} alt="Warning" /> }}
                    >
                        {"Privacy Notice acknowledgement pending"}
                    </MotifToast>
                </div>
            )}
        </React.Fragment>
    )
}