import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import { MotifButton, MotifToast } from "@ey-xd/motif-react";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import iconArrowBack from "../../../assets/images/arrow_back.svg";
import iconCheckCircleSuccess from '../../../assets/images/check-circle.svg';
import LoaderComponent from '../../molecules/loader/LoaderComponent';
import PromptLibraryService from '../../../services/promptLibraryService'; 
import "./CentralPromptDetails.scss";
import CopyCentralModal from '../../molecules/modals/copyCentralPrompt/CopyCentralModal';
import EditCentralPrompt from "../../molecules/forms/editCentralPrompt/EditCentralPrompt";
import { groupConfig } from '../../../config/groupConfig';
import { setCentralPrompts as setCentralPromptsToStore } from "../../../auth/promptSlice";
import CommonService from "../../../services/commonService";

function CentralPromptDetails() {
    const { id } = useParams();
    const { user, userGroupNamePlatform } = useSelector(state => state.user);
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const [centralPrompt, setCentralPrompt] = useState();
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
    const promptLibraryService = new PromptLibraryService();

    const [showCopyToPersonalModal, setShowCopyToPersonalModal] = useState(false);
    const [showEditCentralPrompt, setShowEditCentralPrompt] = useState(false);
    const [showPromptSaveSuccessToast, setShowPromptSaveSuccessToast] = useState(false);
    const [tableTypeRefs, setTableTypeRefs] = useState([]);
    const commonService = new CommonService();

    async function getPrompt() {
        let responsePrompt = {};
        responsePrompt = await promptLibraryService.getCentralPromptById(id);
        setCentralPrompt(responsePrompt[0]);
    };

    useEffect(() => {
        setIsLoadingInProgress(true);
        getPrompt();
        setIsLoadingInProgress(false);
        // eslint-disable-next-line
    }, [id])

    /**
     * Hook to populate table type references
     */
    useEffect(() => {
        const getReferences = async () => {
            const response = await commonService.getReferences();
            if (response) {
                const vTableTypeRefs = response.filter(element => (element.GroupKey === "TableType"));
                setTableTypeRefs(vTableTypeRefs);
            }
        };
        getReferences();
        // eslint-disable-next-line
    }, []);

     /**
    * Function called when copy to personal button clicked
    */
    const onCopyToPersonalClick = () => {  
        setShowCopyToPersonalModal(true);
    }

    /**
     * Function to close a copy to personal modal.
     */ 
    const handleCloseModal = () => {
        setShowCopyToPersonalModal(false);

    }

    /**
     * Function to add prompt to personal from central.
     */
    const handleConfirm= async (promptName) => {
    if(id && promptName)
    {
        const response = await promptLibraryService.copyCentralPromptToPersonal(user.username,id,promptName);
        if (response.status === 200) {
            setShowCopyToPersonalModal(false);
        }  
    }         
    };

    /**
     * Function to get Central prompts associated with user
     */
    const getCentralPrompts = async (userId) => {
        const vCentralPrompts = await promptLibraryService.getCentralPrompt(userId);
        dispatch(setCentralPromptsToStore({
            centralPrompts: vCentralPrompts
        }));
    }

    const handleSavePrompt = async (updatedPrompt) => {
        setIsLoadingInProgress(true);
        const response = await promptLibraryService.saveCentralPrompt(updatedPrompt);
        if(user) {
            getCentralPrompts(user.username);
        }
        setIsLoadingInProgress(false);
        setShowEditCentralPrompt(false);
        if(response?.status === 200) {
            getPrompt();
            setShowPromptSaveSuccessToast(true);
        }
    }

    const handleEditPrompt = () => {
        setShowEditCentralPrompt(true);
    }

    return (
        <React.Fragment>
            <LoaderComponent show={isLoadingInProgress} />
            <div className="prompt-details">
                <div className="prompt-div">
                    <MotifIconButton
                        aria-label="Click here to go back to Central Prompt Library"
                        onClick={() => navigate(-1)}
                        data-testid="testIdBtnBack"
                    >
                        <img src={iconArrowBack} alt='Go to previous page' />
                    </MotifIconButton>
                    <span className='prompt-name'>{centralPrompt?.tableType}</span>
                </div>
                <div className="prompt-container">
                {
                    showEditCentralPrompt ? (
                        <div className="view-edit-prompt">
                            <EditCentralPrompt 
                                centralPrompt={centralPrompt}
                                setShowEditCentralPrompt={setShowEditCentralPrompt}
                                handlePromptSave={handleSavePrompt}
                                tableTypeRefs={tableTypeRefs} />
                        </div>
                    ):(
                        <React.Fragment>
                            <div className="view-edit-prompt">
                                <div className="field-label">Prompt Name</div>
                                <div className="field-value">{centralPrompt?.promptName}</div>
                                {
                                    centralPrompt?.prompts?.map((prompt) => (
                                        <React.Fragment>
                                            <div className="field-label">{prompt.promptMethodName}</div>
                                            <div className="field-value">{prompt.promptBody}</div>
                                        </React.Fragment>
                                    ))
                                }

                                <p className="field-label">Full Table Context</p>
                                <MotifCheckbox
                                    disabled={true}
                                    checked={centralPrompt?.fullTableContextIndicator}
                                    id="fullTableContextIndicator"
                                    name="fullTableContextIndicator"
                                    value={centralPrompt?.fullTableContextIndicator}
                                    className="field-value"
                                >
                                    <span className="field-value">
                                    Include full table as Context
                </span>
                                    
                                </MotifCheckbox>
                               
                                </div>

                               
                            {
                                showPromptSaveSuccessToast &&
                                <MotifToast
                                    variant="success"
                                    onClose={() => {
                                        setShowPromptSaveSuccessToast(false);
                                    }}
                                    icons={{toast:<img src={iconCheckCircleSuccess} alt="Success" />}}
                                    className='notifyToast'
                                >
                                    Prompt saved successfully
                                </MotifToast>
                            }
                               
                                
                            <div className='genericFooter'>
                                <div className='footerInner alignEnd'>
                                    <MotifButton onClick={() => onCopyToPersonalClick()} className='' type="button" variant="secondary">
                                        Copy to Personal
                                    </MotifButton>
                                    { userGroupNamePlatform === groupConfig.groupNamePlatForm &&
                                        <MotifButton onClick={handleEditPrompt} className='' type="button" variant="secondary">
                                            Edit Prompt
                                        </MotifButton>
                                    }
                                </div>
                            </div>
                            {showCopyToPersonalModal && (
                                <CopyCentralModal
                                    onClose={handleCloseModal}
                                    handleConfirm={handleConfirm}
                                />
                            )}
                        </React.Fragment>
                    )
                }
                </div>
            </div>
        </React.Fragment>
    );
}

export default CentralPromptDetails;