import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader, MotifButton } from '@ey-xd/motif-react';
import MotifTooltip from '@ey-xd/motif-react/Tooltip';
import EditCentralPrompt from "../../forms/editCentralPrompt/EditCentralPrompt";
import CommonService from '../../../../services/commonService';
import "./AddCentralPrompt.scss";

function AddCentralPrompt({showAddCentralModal, handleCloseAddCentralPromptModal, handleConfirmAddCentralPromptModal}) {
    const [tableTypeRefs, setTableTypeRefs] = useState([]);
    const commonService = new CommonService();
    const { newCentralPrompt, isValidationErrorOnAddNewPrompt } = useSelector(state => state.prompt);
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);

    /**
     * Hook to populate table type references
     */
    useEffect(() => {
        const getReferences = async () => {
            const response = await commonService.getReferences();
            if (response) {
                const vTableTypeRefs = response.filter(element => (element.GroupKey === "TableType"));
                setTableTypeRefs(vTableTypeRefs);
            }
        };
        getReferences();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        //Validate newCentralPrompt for button enable/disable
        const tableType = newCentralPrompt.tableType;
        const promptName = newCentralPrompt.promptName;
        const prompts = newCentralPrompt.prompts;
        if(
            (tableType && tableType.length > 0) &&
            (promptName && promptName.trim().length > 0) &&
            (prompts.every((p) => p.promptBody?.trim()?.length > 0))
        ){
            setDisableConfirmButton(false);
        } else {
            setDisableConfirmButton(true);
        }
    }, [newCentralPrompt])

    return(
        <MotifModal id="addCentralPromptModal" 
            show={showAddCentralModal} 
            size="md"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'none',
                },
            }}
            onClose={handleCloseAddCentralPromptModal}
            className="addCentralPromptModal genericModal modalMotifFooterModal"
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
                className="addCentralPromptHeader"
            >
                <div>Add New Prompt</div>
            </MotifModalHeader>
            <MotifModalBody className="addCentralPromptBody">
                <EditCentralPrompt 
                    centralPrompt={newCentralPrompt}
                    setShowEditCentralPrompt={()=>{}}
                    handlePromptSave={()=>{}}
                    useAsAddCentralPrompt={showAddCentralModal}
                    tableTypeRefs={tableTypeRefs} />
            </MotifModalBody>
            <MotifModalFooter className="motif-modal-footer">
                <MotifButton
                    label="btnCancel"
                    size="small"
                    type="button"
                    variant="secondary"
                    className="btnCancel"
                    onClick={handleCloseAddCentralPromptModal}
                >
                    Cancel
                </MotifButton>
                <MotifTooltip
                    hide={!(isValidationErrorOnAddNewPrompt || disableConfirmButton)}
                    contentClassName="tooltipWithoutCloseCenterText"
                    trigger={
                        <MotifButton
                            label="btnConfirm"
                            size="small"
                            type="button"
                            variant="primary"
                            className="btnConfirm"
                            disabled={isValidationErrorOnAddNewPrompt || disableConfirmButton}
                            onClick={handleConfirmAddCentralPromptModal}
                        >
                            Confirm
                        </MotifButton>
                    }
                    placement="top"
                    allowClick={false}
                    hideCloseButton={true}
                >
                    Complete all <br/>inputs to continue
                </MotifTooltip>
            </MotifModalFooter>
        </MotifModal>
    );
}

AddCentralPrompt.propTypes = {
    showAddNewModal: PropTypes.bool,
    handleCloseAddCentralPromptModal: PropTypes.func,
    handleConfirmAddCentralPromptModal: PropTypes.func
}

export default AddCentralPrompt;