/**
 * Add additional ocntext modal
 * Created On: 26-08-2024
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifFormField from '@ey-xd/motif-react/FormField';
import MotifSelect, { MotifOption } from '@ey-xd/motif-react/Select';
import MotifAccordion, {
    MotifAccordionTrigger,
    MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifTable from '@ey-xd/motif-react/Table';
import MotifToast from '@ey-xd/motif-react/Toast';
import MotifTooltip from '@ey-xd/motif-react/Tooltip';
import { MotifInput, MotifErrorMessage, MotifLabel } from '@ey-xd/motif-react';

import iconAdd from '../../../../assets/images/add-circle.svg';
import iconTrash from '../../../../assets/images/trash.svg';
import iconNoRelationship from '../../../../assets/images/no-relation.svg';
import iconError from '../../../../assets/images/error.svg';
import iconWhiteCheckCircle from "../../../../assets/images/white-check-circle.svg";
import iconSuccess from "../../../../assets/images/check-circle.svg";

import './AdditionalContext.scss';
import TableService from '../../../../services/tableService';
import DeleteAdditionalContext from '../deleteAdditionalContext/DeleteAdditionalContext';

const AdditionalContext = ({
    openModal,
    setOpenModal,
    closeModal,
    selectedTable
}) => {

    const { user, activeProject } = useSelector(state => state.user);
    const [selectedTableRow, setSelectedTableRow] = useState("");
    const [disableConfirmButton, setDisableConfirmButton] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const tableService = new TableService();
    const [additionalContextRowHeaderList, setAdditionalContextRowHeaderList] = useState([]);
    let additionalContextList = [];
    const [additionalContexttableList, setAdditionalContexttableList] = useState([]);
    const [showEditToast, setShowEditToast] = useState(false);
    const [showClose, setShowClose] = useState(true);
    const [showDACModal, setShowDACModal] = useState(false);
    const [showDACToast, setShowDACToast] = useState(false);
    const [selectedKpiId, setSelectedKpiId] = useState(null);
    const [selectedRange, setSelectedRange] = useState('');
    const [selectedRangeData, setSelectedRangeData] = useState(null);
    const [isErrorOnRange, setIsErrorOnRange] = useState(false);

    const projectId = activeProject?.project_UUID;
    const vCurrentUserEmail = user?.username;
    const vCurrentUserRole = activeProject?.role;

    useEffect(() => {
        if (selectedTable) {
            getAdditionalContext();
        }
        // eslint-disable-next-line
    }, [selectedTable]);
    
    /**
      * Function to open a Delete additional context modal.
      */

    const handleOpenModal = (kpiId) => {
        setSelectedKpiId(kpiId)
        setShowDACModal(true);
    };

    /**
      * Function to close a Delete additional context modal.
      */ 

    const handleCloseDACModal = () => {
        setShowDACModal(false)
    }
    /**
      * Function to delete a Delete additional context.
      */

    const handleDelete = async () => {
        const responseStatus = await tableService.deleteADC(projectId, selectedTable.id, selectedKpiId);
        if (responseStatus === 200) {
            setShowDACModal(false);
            setShowDACToast(true)
            getAdditionalContext();
        }
    };

    /**
      * Function will be called when submitting add Additional context.
      */
    const getAdditionalContext = async () => {
        const vData = {
            "projectId" : projectId,
            "tableId" : selectedTable.id
        };
        const response = await tableService.getAdditionalContext(vData);
        if (response) {
            loadAdditionalContext(response);
        }

    }

    /**
     * Function to load existing Additional context data during popup load
     */
    const loadAdditionalContext = (vData) => {
        if (vData) {
            const uniqueRowId = [...new Set(vData.map(item => item.originRowId))];
            for (let i in uniqueRowId) {
                additionalContextList.push({
                    id: uniqueRowId[i],
                    name: selectedTable.tableContent.find(item => item.rowId === uniqueRowId[i])?.RowHeader,
                })
            }
            setAdditionalContextRowHeaderList(additionalContextList);
            let arrayList = [];
            for (let i in vData) {
                arrayList.push({
                    originRowId: vData[i].originRowId,
                    kpiId: vData[i].kpiId,
                    kpiUploadRange: `'${vData[i].kpimetadata.sheetName}'!${vData[i].kpimetadata.uploadRange}`
                })
            }
            setAdditionalContexttableList(arrayList);
        }
    }

    /**
   * This assists in configuring column headers.
   */
    const colDefs = [
        {
            headerName: "Table range",
            field: "kpiUploadRange",
            wrapText: true,
            autoHeight: true,
            flex: 2,
            type: 'centerAligned',
            cellClass: 'relation-column',
            filter: false,
            sortable: false
        },
        {
            field: "",
            headerName: "Actions",
            wrapText: true,
            autoHeight: true,
            width: 120,
            cellRenderer: (params) => {
                return (
                    <MotifIconButton
                        aria-label="Delete"
                        className="btnDelete"
                        onClick={() => handleOpenModal(params.data.kpiId)}
                    >
                        <img className='trashrelation' src={iconTrash} alt="Delete" />
                    </MotifIconButton>
                );
            },
            cellClass: 'relation-column-center',
            colId:"actions",
            filter: false,
            sortable: false
        }
    ];

    /**
     * Function will be called when submitting add additional context.
     */
    const addAdditionalContext = async () => {
        setDisableConfirmButton(true);
        const vData = {
            "tableId": selectedTable.id,
            "rowId": selectedTableRow,
            "additional_context_data": selectedRangeData.additional_context_data,
            "additional_context_metadata": selectedRangeData.additional_context_metadata,
            "userId": vCurrentUserEmail,
            "userRole": vCurrentUserRole
        };
        const response = await tableService.addAdditionalContext(vData);
        if (response) {
            setDisableConfirmButton(false);
            getAdditionalContext();
            gotoStep(1);
            setShowEditToast(true);
            setShowClose(true);
        } else {
            setDisableConfirmButton(false);
            closeModal('Failure', response?.output);
        }
    };

    /**
     * Function to set selected table row or line item
     * @param {*} data 
     */
    const onRowChange = (data) => {
        setSelectedTableRow(data);
    }

    /**
     * Function to switch Step
     * Also currently integrated auto suggestions for table details.
     * @param {*} vStep 
     */
    const gotoStep = (vStep) => {
        setCurrentStep(vStep);
    }

    /**
     * Function to get selected table range in excel
     */
    const getSelectedRange = () => {
        if (window.Excel) {
            window.Excel.run(async (context) => {

                // Get the selected range
                const selectedRange = context.workbook.getSelectedRange();
                selectedRange.load(["values", "address", "worksheet/name"]);

                // Sync the context to load the selected range properties
                await context.sync();
                // Extract the values and address from the selected range
                const values = selectedRange.values;

                validateSelectedRange(values);

                if (selectedRange?.address && (selectedRange.address.indexOf(':') !== -1)) {
                    setSelectedRange(selectedRange.address);
                }
                const address = selectedRange.address
                    .replace(selectedRange.worksheet.name, "")
                    .replace(/[&#,+()$~%.'"*?!<>{}]/g, "");
                const sheetName = selectedRange.worksheet.name;

                let vSelectedValues;
                if (values && (values.length > 0)) {
                    vSelectedValues = values.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])), []);
                }
                
                // Extract the workbook name (filename)
                const workbook = context.workbook;
                workbook.load("name");
                await context.sync();
                const workbookName = workbook.name;

                // Prepare the result object with values and metadata
                const result = {
                    additional_context_data: vSelectedValues,
                    additional_context_metadata: {
                        filename: workbookName,
                        sheetName: sheetName,
                        uploadRange: address,
                    }
                };
                if (result) {
                    setSelectedRangeData(result);
                }
            }).catch((error) => {
                console.error("Error: " + error);
            });
        }
    };

    /**
     * Validate selected table range
     * @param {*} vRange 
     */
    const validateSelectedRange = (vRange) => {
      //  Check for minimum 1 row selected
      if (vRange.length > 0) {
        setIsErrorOnRange(false);
      } else {
        setIsErrorOnRange(true);
        return;
      }
      //  Check for same no of columns as parent table selected
      if (vRange[0].length === selectedTable.tableHeaders.length) {
        setIsErrorOnRange(false);
      } else {
        setIsErrorOnRange(true);
        return;
      }
      //  Check if first cell from each rows are not empty
      for (const element of vRange) {
        if (element[0] !== "") {
          setIsErrorOnRange(false);
        } else {
          setIsErrorOnRange(true);
          return;
        }
      }
    };
      
    return (
        <>
            {   showDACModal && (
                <DeleteAdditionalContext
                    onClose={handleCloseDACModal}
                    handleDelete={handleDelete}
                />
            )}
            <MotifModal
                show={setOpenModal}
                onClose={() => setOpenModal(!openModal)}
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                className='TableRelationshipModal genericModal modalMotifFooterModal'
                size='xl'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className='addTableRelationshipHeader'
                >
                    <div className='addTableRelationshipHead'>
                        {(currentStep === 1) ? "Additional context" : "Add additional context"}
                    </div>
                </MotifModalHeader>
                <MotifModalBody>
                    {(currentStep === 1) &&
                        <>
                            {   (   (
                                        (!additionalContextRowHeaderList) || 
                                        (additionalContextRowHeaderList?.length === 0)
                                    ) && 
                                    showEditToast === false
                                ) &&
                                <>
                                    <div className='bodyheaderdiv'>
                                        <div className='lblSelectTableName'>
                                            Selected Table Name
                                        </div>
                                        <div className='selectedTableName'>
                                            {selectedTable.tableName}
                                        </div>
                                    </div>
                                    <div className='bodycenterdiv'>
                                        <div className='bodycenterinnerdiv'>
                                            <img className='imageborder' src={iconNoRelationship} alt="Previous" />
                                        </div>
                                        <div className='bodycenterinnerdivtext'>
                                            No additional context found
                                        </div>
                                        <div className='bodycenterinnerdivbottomtext'><p>To add additional context,<br /> click
                                            <mark className='addrelation'> 'Add additional context'</mark></p></div>
                                    </div>
                                </>
                            }
                            {((additionalContextRowHeaderList?.length > 0) ||
                                (additionalContextRowHeaderList?.length === 0 && showEditToast)) &&
                                <div className='bodyheaderdiv'>
                                    <div className='lblSelectTableName'>
                                        <div className='lblSelectTableName'>
                                            Selected Table Name
                                        </div>
                                        <div className='selectedTableName'>
                                            {selectedTable.tableName}
                                        </div>
                                        <div className='relation-accordian'>
                                            {additionalContextRowHeaderList?.map((vLineItem) => (
                                                <MotifAccordion
                                                    id="relationaccordian"
                                                    key={vLineItem.id}
                                                    onOpen={() => {}}
                                                    style={{
                                                        flex: '1'
                                                    }}
                                                    useChevronIcon={true}
                                                    className='relaccordian'
                                                >
                                                    <MotifAccordionTrigger>
                                                        {vLineItem.name}
                                                    </MotifAccordionTrigger>
                                                    <MotifAccordionContent>
                                                        {additionalContexttableList &&  
                                                            <MotifTable
                                                                rowData={additionalContexttableList.filter(item => (item.originRowId === vLineItem.id))}
                                                                columnDefs={colDefs}
                                                                suppressCellFocus
                                                                suppressRowClickSelection
                                                                suppressRowHoverHighlight
                                                                enableSorting={false}
                                                                className='tableAdditionalContext'
                                                            ></MotifTable>       
                                                        }
                                                    </MotifAccordionContent>
                                                </MotifAccordion>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {(currentStep === 2) &&
                        <>
                            <div className='lblSelectTableName'>
                                Selected Table Name
                            </div>
                            <div className='lblSelectTableName selectedTableName'>
                                {selectedTable.tableName}
                            </div>
                            <div className='lblSelectRow'>
                                Select Row
                            </div>
                            <div className='fldRowContainer'>
                                <div className='fldRow'>
                                    <MotifFormField>
                                        <MotifSelect
                                            value={selectedTableRow}
                                            onChange={val => onRowChange(val)}
                                            ariaLabelledBy="Table Row"
                                            triggerButtonProps={{
                                                'aria-describedby': 'Table Row'
                                            }}
                                            visibleOptions={3}>
                                            {
                                                (selectedTable) ?
                                                    selectedTable?.tableContent?.map((element) =>
                                                        <MotifOption value={element.rowId} key={element.rowId}>{element.RowHeader}</MotifOption>
                                                    ) :
                                                    <MotifOption value="" key="0">Select Row</MotifOption>
                                            }
                                        </MotifSelect>
                                        <MotifLabel className='fldLabel'>
                                            Select 1 row to add additional context
                                        </MotifLabel>
                                    </MotifFormField>
                                </div>
                            </div>
                            <div className='lblSelectRange'>
                                Select Range (click below)
                            </div>
                            <div className='fldRangeContainer'>
                                <div className='fldRange'>
                                    <MotifFormField className={isErrorOnRange ? 'field-group error-border' : 'field-group'}>
                                        <MotifInput
                                            onChange={(e) => setSelectedRange(e.target.value)}
                                            value={selectedRange}
                                            placeholder='Add range'
                                            onFocus={() => getSelectedRange()}
                                            className={isErrorOnRange ? 'inputThreshold motif-input-invalid' : 'inputThreshold'}
                                            data-testid="testIdFldRange"
                                            readOnly="true"
                                        />
                                        {(isErrorOnRange) &&
                                            <MotifTooltip
                                                contentClassName="tooltipWithoutCloseCenterText"
                                                trigger={
                                                    <img src={iconError} alt='Error' className='helpTooltipIcon' />
                                                }
                                                placement='top'
                                                allowClick={false}
                                                hideCloseButton={true}
                                            >
                                                Please select a <br />valid range
                                            </MotifTooltip>
                                        }
                                    </MotifFormField>
                                    {(!isErrorOnRange) &&
                                        <MotifLabel className='fldLabel'>
                                            Periods selected for context should match periods for row items.
                                        </MotifLabel>
                                    }
                                    {(isErrorOnRange) &&
                                        <MotifErrorMessage className='rangeMessage errorText'>
                                            Periods selected for context should match periods for row items.
                                        </MotifErrorMessage>
                                    }
                                </div>
                            </div>
                        </>
                    }

                </MotifModalBody>
                <MotifModalFooter className='TableRelationshipFooter'>
                    <div className='additionalContextToastLayer'>
                        {showDACToast && (
                            <MotifToast
                                actionName=""
                                onClose={() => {
                                    setShowDACToast(false);
                                }}
                                variant="info"
                                className='notifyToast11'
                                icons={{toast: <img src={iconWhiteCheckCircle} alt="Info" />}}
                            >
                                Additional context removed!
                            </MotifToast>
                        )}
                        {(showEditToast) &&
                            <MotifToast
                                actionName=""
                                onClose={() => {
                                    setShowEditToast(false);
                                }}
                                variant={"success"}
                                className='notifyToast'
                                icons={{toast: <img src={iconSuccess} alt="Success" />}}
                            >
                                Additional context added successfully!
                            </MotifToast>
                        }
                    </div>
                    <div className='tableRelationshipFooterleft'>
                        {(currentStep === 1) &&
                            <MotifIconButton
                                className='add-relationship'
                                aria-label="Add additional context"
                                onClick={() => {
                                    gotoStep(2);
                                    setShowClose(false);
                                }}
                                data-testid="testIdBtnBack"              >
                                <img className='addrelationicon' src={iconAdd} alt="Previous" />
                                <p className='add-relation'>Add additional context</p>
                            </MotifIconButton>
                        }
                    </div>
                    <div className='tableRelationshipFooterRight'>
                        <MotifButton
                            onClick={() => setOpenModal(!openModal)}
                            type="button"
                            variant="secondary"
                            className='btnCancel'
                        >
                            {showClose ? "Close" : "Cancel"}
                        </MotifButton>
                        {(currentStep === 2) &&
                            <MotifButton
                                onClick={() => addAdditionalContext()}
                                type="button"
                                className='btnConfirm'
                                disabled={
                                    disableConfirmButton ||
                                    !selectedTableRow ||
                                    !selectedRangeData ||
                                    isErrorOnRange
                                }
                            >
                                Confirm
                            </MotifButton>
                        }
                    </div>
                </MotifModalFooter>
            </MotifModal>
        </>
    );
};

AdditionalContext.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    closeModal: PropTypes.func,
    selectedTable: PropTypes.object
};

export default AdditionalContext;