/**
 * Redux slice for table reducers with actions
 * 1. 
 * Created on 05-08-2024
 */
import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
    name: "table",
    initialState: {
        tables: null,
        selectedTable: null
    },
    reducers: {
        setTables: (state, action) => {
            state.tables = action.payload.tables;
        },
        setSelectedTable: (state, action) => {
            state.selectedTable = action.payload.selectedTable;
        }
    }
})

export const { setTables, setSelectedTable } = tableSlice.actions;
export default tableSlice.reducer;