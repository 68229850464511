import React from "react";

/**
 * Motif imports
 */

import "../noProjects/NoProjects.scss";
import searchIcon from "../../../assets/images/privacy policy.svg";

export default function PaansAcknowledge() {
  return (
    <div className="parent-nopaans-div">
      <div>
        <div className="parent-search-icon-div">
          <div className="search-icon-div">
            <img src={searchIcon} alt="Search" />
          </div>
        </div>
        <div className="parent-noprojects-text-div">Privacy Notice <br/>  acknowledgement pending</div>
        
        <div className="parent-statement-div">
          Please acknowledge the Privacy Notice through
          <br />
          the ‘Access Platform’ link above.
        </div>
        <div className="parent-statement-div">
          Once complete, return to the DDA Excel Add-in
          <br />
          and click ‘Refresh’ to continue.
        </div>
      </div>
    </div>
  );
}
