/**
 * Utility functions to perform custom functions
 */
import showdown from 'showdown';
import { groupConfig } from '../config/groupConfig';

class Utils {

  /**
   * Get the HTML equivalent to Markdown text
   * @param {*} markdownText - Markdown text
   * @returns HTML equivalent to Markdown text
   */
  markdownToHtml = (markdownText) => {
    const converter = new showdown.Converter();
    return converter.makeHtml(markdownText);
  };
  
  /**
   * Function to split excel cell as row and column
   * @param {*} cell 
   * @returns 
   */
  splitExcelCell = (cell) => {
    let match = cell.match(/^([A-Z]+)(\d+)$/);
    if (match) {
      return { column: match[1], row: parseInt(match[2]) };
    }
  };

  /**
   * Function to convert numbers to letters incase of excel rows & coloumns references
   * @param {*} num 
   * @returns 
   */
  numToLetters = (num) => {
    let letters = "";
    while (num > 0) {
      let remainder = (num - 1) % 26;
      letters = String.fromCharCode(65 + remainder) + letters;
      num = Math.floor((num - remainder) / 26);
    }
    return letters;
  };

  /**
   * Function to convert letters to numbers incase of excel rows & coloumns references
   * @param {*} letter 
   * @returns 
   */
  letterToNum = (letter) => {
    letter = letter.toUpperCase();
    let num = 0;
    let len = letter.length;
    for (let pos = 0; pos < len; pos++) {
      num *= 26;
      num += letter.charCodeAt(pos) - 64;
    }
    return num;
  };

  /**
 * Check if the group name provided is in the SAT group name configuration
 * @param {groupDisplayName} groupDisplayName Group name to check as SAT Group name 
 * @returns true if provided group name is SAT group name, otherwise false
 */
  isSatGroup(groupDisplayName) {
    //REACT_APP_GROUPNAME_DAISEY configuration contains comma (,) separated group names
    const satGroupNames = groupConfig.groupNameDaisEy.toLowerCase().split(",");
    return satGroupNames.findIndex(sgn => sgn.trim() === groupDisplayName.toLowerCase().trim()) !== -1
  };

  /**
   * Check if the group name provided is in the Platform Admin group name configuration
   * @param {groupDisplayName} groupDisplayName Group name to check as Platform Admin Group name 
   * @returns true if provided group name is Platform Admin group name, otherwise false
   */
  isPlatformAdminGroup(groupDisplayName) {
    //REACT_APP_GROUPNAME_PLATFORM_ADMIN configuration contains comma (,) separated group names
    const platformAdminGroupNames = groupConfig.groupNamePlatformAdmin.toLowerCase().split(",");
    return platformAdminGroupNames.findIndex(pagn => pagn.trim() === groupDisplayName.toLowerCase().trim()) !== -1
  };
  
  /**
   * Function to write cell value in excel
   * @param {*} cellType 
   * @param {*} cell 
   * @param {*} value 
   */
  writeValueToExcelCell = (cellType, cell, value) => {
    cell.format.font.name = 'Arial';
    cell.format.font.size = 8;
    if (cellType === 'Header') {
      cell.format.fill.color = "Blue";
      cell.format.font.color = "White";
      cell.numberFormat = [["General"]];
    } else {
      cell.format.fill.color = "white";
      cell.format.font.color = "black";
      cell.numberFormat = "@";
      cell.format.columnWidth = 100;
      cell.format.rowHeight = 13;
    }
    cell.format.horizontalAlignment = "Left";
    cell.format.verticalAlignment = "Top";
    cell.format.wrapText = true;
    cell.values = [[value]];
    cell.format.autofitColumns();
  };

  /**
   * Function to format date string
   * @param {*} vDate 
   * @param {*} vFormat 
   * @returns 
   */
  formatDateString = (vDate, vFormat) => {

    const dtDate = new Date(vDate);
    let strDay;
    let strMonth;
    let strYear;
    let strDate = "";

    if (vFormat === 'Mmm DD, YYYY') {

      strDay = dtDate.toLocaleString('default', { day: '2-digit' });
      strMonth = dtDate.toLocaleString('default', { month: 'short' });
      strYear = dtDate.toLocaleString('default', { year: 'numeric' });

      strDate = `${strMonth} ${strDay}, ${strYear}`;

    }

    return strDate;
  };

}
export default Utils;
