/**
 * Project page of DaaS application
 * opens when project is selected from Dashboard page
 * Created Date: 18/06/2024
 */
import React, { useState } from 'react';

import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import warningCircle from '../../../../assets/images/warning.svg';
import './EditRangeWarning.scss';

const EditRangeWarning = ({setOpenModal, setconfirm}) => {
    const [open, setOpen] = useState(true);
    /**
   * Description
   * -----------
   * This method is to close the close project popup
   */
    const onCloseClick = () => {
        setOpen(false);
        setOpenModal(false);
    }
    return (

        <div>
            <MotifModal id="editRangeWarningModal" show={open} size="sm" focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: 'none'
                    }
                }}
                onClose={() => { onCloseClick() }}
                className='editRangeWarningModal genericModal modalMotifFooterModal'
            >
                <MotifModalHeader
                    className='editRangeWarningHeader'
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                >
                   <div className='closeSymbolContainer'>
                    <img className='warningCircle' alt='icon' src={warningCircle} />
                   </div>
                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment key=".0">
                        <div className='editRangeText'>Edit range selected</div>
                        <div id="editRangeWarningLines">
                            <span >
                            This action will clear any line commentary, table commentary and remove any relationships or update the table range. Click ‘Confirm’ to proceed.
                            </span>
                        </div>
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter className='editRangeWarningFooter'>
                    <React.Fragment key=".0">                        
                        <MotifButton
                            label="btnCancel"
                            onClick={() => { onCloseClick() }}
                            size="medium"
                            type="button"
                            variant="secondary"
                            className='btnCancel'
                        >
                            Cancel
                        </MotifButton>
                        <MotifButton
                            label="btnConfirm"
                            onClick={() => { setconfirm() }}
                            size="medium"
                            type="button"
                            className='btnConfirm'
                        >
                            Confirm
                        </MotifButton>
                    </React.Fragment>
                </MotifModalFooter>

            </MotifModal>
        </div>
    )
    
}
export default EditRangeWarning;