/**
 * Project page of DaaS application
 * opens when project is selected from Dashboard page
 * Created Date: 18/06/2024
 */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * Motif controls
 */
import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/TabNavigation';
import MotifTable from '@ey-xd/motif-react/Table';
import MotifToast from '@ey-xd/motif-react/Toast';
import { MotifTooltip } from "@ey-xd/motif-react";
import MotifDropdown, { MotifDropdownItem } from '@ey-xd/motif-react/Dropdown';
import { DocumentLists } from '../projectDocuments/DocumentLists';

/**
 * Internal imports
 */
import './ProjectDetails.scss';
import iconArrowBack from '../../../assets/images/arrow_back.svg';
import iconAdd from '../../../assets/images/add-circle-white.svg';
import iconThresholds from '../../../assets/images/ease-curve-control-points.svg';
import iconMore from '../../../assets/images/more-horiz.svg';
import iconRelationship from '../../../assets/images/link.svg';
import editTable from '../../../assets/images/edit-table.svg';
import deleteTable from '../../../assets/images/delete-table.svg';
import iconSuccess from "../../../assets/images/check-circle.svg";
import iconError from '../../../assets/images/error.svg';

import TableService from '../../../services/tableService';
import AddTable from '../../molecules/modals/addTable/AddTable';
import MemberList from '../projectMembers/MemberList';
import TableDetails from '../tableDetails/TableDetails';
import Thresholds from '../../molecules/modals/thresholds/Thresholds';
import { setUserProjects, setActiveProject } from "../../../auth/userSlice";
import { setTables, setSelectedTable } from "../../../auth/tableSlice";
import DeleteTableModal from '../../molecules/modals/deleteTable/DeleteTableModal';
import iconWhiteCheckCircle from "../../../assets/images/white-check-circle.svg";

const ProjectDetails = () => {

    /**
     * Custom constants
     */
    const vTabs = [
        {
            "id": 1,
            "elementId": "idTabContentTables",
            "title": "Tables"
        },
        {
            "id": 2,
            "elementId": "idTabContentUsers",
            "title": "Users"
        },
        {
            "id": 3,
            "elementId": "idTabContentDocuments",
            "title": "Documents"
        }
    ];
    const vTabIds = { tables: "idTabContentTables", users: "idTabContentUsers", documents: "idTabContentDocuments" };
    const noRowsTemplate = `<span class="ag-overlay-no-rows-center">To get started, select "Add Table" below.</span>`;
    const [colDefs] = useState([
        {
            field: "tableName",
            headerName: "Table name",
            wrapText: true,
            flex: 2,
            autoHeight: true,
            cellRenderer: (params) => (
                params.data.tableName
            ),
            filter: false,
            sortable: false
        },
        {
            field: "metadata.uploadRange",
            headerName: "Range",
            wrapText: true,
            flex: 2,
            autoHeight: true,
            cellRenderer: (params) => (
                <div>
                    {`'${params.data.metadata.sheetName}'!${params.data.metadata.uploadRange}`}
                </div>
            ),
            filter: false,
            sortable: false
        },
        {
            field: "",
            headerName: "Actions",
            colId: "actions",
            wrapText: true,
            width: 100,
            autoHeight: true,
            cellRenderer: (params) => (
                <div>
                    {(params.data.tableRelationships?.length > 0) &&
                        <MotifTooltip trigger={<img src={iconRelationship} alt='Relationship' />} placement="left" hideCloseButton={true}>
                            existing relationship(s) : {params.data.tableRelationships.length}
                        </MotifTooltip>
                    }
                    <MotifDropdown
                        aria-labelledby={"more-dropdown-trigger-" + params.data.id}
                        className='edit-table-dropdown'
                        handleClickOutside={() => toggleDropdownMenuMore(params.data.id, 'Close')}
                        id={"more-dropdown-" + params.data.id}
                        placement="bottom-right"
                        trigger={
                            <MotifTooltip
                                hide={!params.data.LAInProc}
                                trigger={
                                    <MotifIconButton
                                        aria-label="More"
                                        id={"more-dropdown-trigger-" + params.data.id}
                                        onClick={() => toggleDropdownMenuMore(params.data.id, 'Open')}
                                        disabled={params.data.LAInProc}
                                    >
                                        <img src={iconMore} alt='More' />
                                    </MotifIconButton>
                                }
                                placement="left"
                                allowHover={true}
                                allowClick={false}
                                hideCloseButton={true}
                            >
                                Analysis in progress
                            </MotifTooltip>
                        }
                    >
                        <span className="document-download-delete">
                            {
                                (() => {
                                    return <>
                                        <MotifDropdownItem onClick={() => onEditClick(params.data)} data>
                                            <img src={editTable} alt="editTable" className='iconedit' /> Edit Table
                                        </MotifDropdownItem>
                                        <MotifDropdownItem onClick={() => onDeleteClick(params.data)} data>
                                            <img src={deleteTable} alt="deleteTable" className='icondelete' /> Delete Table
                                        </MotifDropdownItem>
                                    </>
                                })()
                            }
                        </span>
                    </MotifDropdown>
                </div>
            ),
            filter: false,
            sortable: false
        }
    ]);

    const toggleDropdownMenuMore = (vDropdownMenuId, vToggle) => {
        const vDropdown = document.getElementById('more-dropdown-' + vDropdownMenuId);
        if (vDropdown && (vToggle === 'Open')) {
            vDropdown.style.display = 'block';
        } else if (vDropdown && (vToggle === 'Close')) {
            vDropdown.style.display = 'none';
        }
    };

    const { activeProject } = useSelector(state => state.user);
    const { selectedTable } = useSelector(state => state.table);
    const projectId = activeProject?.project_UUID;
    const projectName = activeProject?.projectName;

    const [tableData, setTableData] = useState([]);
    const [tableLAStatus, setTableLAStatus] = useState({});
    const [selectedTab, setSelectedTab] = useState(vTabIds.tables);
    const [displayAddTableModal, setDisplayAddTableModal] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayFailure, setDisplayFailure] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState('');
    const [msgFailure, setMsgFailure] = useState('');
    const [displayThresholdsModal, setDisplayThresholdsModal] = useState(false);
    const [addClicked, setAddClicked] = useState(true);
    const [selectedTableData, setSelectedTableData] = useState();
    const [showEditToast, setShowEditToast] = useState(false);
    const [showDeleteTableModal, setShowDeleteTableModal] = useState(false);
    const [selectedDeleteTable, setSelectedDeleteTable] = useState();
    const [showDeleteToast, setShowDeleteToast] = useState(false);
    const [isThresholdDisabled, setIsThresholdDisabled] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * Navigate to home page
     */
    const navToHome = () => {
        dispatch(setSelectedTable({
            selectedTable: null
        }));
        dispatch(setTables({
            tables: null
        }));
        dispatch(setActiveProject({
            activeProject: null
        }));
        dispatch(setUserProjects({
            projects: []
        }));
        navigate('/');
    }

    /**
     * Open Thresholds modal
     */
    const openThresholds = () => {
        setDisplayThresholdsModal(true);
    }

    /**
     * Close Thresholds modal
     */
    const closeThresholds = (vStatus, vError) => {
        setDisplayThresholdsModal(false);
        if (vStatus === 'Success') {
            setDisplaySuccess(true);
            setMsgSuccess('Global settings applied. You can review the Table settings for your tables.');
        } else {
            setDisplayFailure(true);
            setMsgFailure(vError || 'Failed to update global threshold settings.');
        }
    }

    const tableService = new TableService();

    /**
     * Hook to fetch tables initially
     */
    useEffect(() => {
        if (projectId) {
            getTables(projectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId,selectedTable]);

    /**
     * Function to get tables from backend
     * @param {*} vData 
     */
    const getTables = async (vData) => {
        const vProjectId = vData || projectId;
        const vTables = await tableService.getTables(vProjectId);
        if (vTables[0]?.table_details) {
            vTables[0].table_details.map((table, i) => (
                setTableStatus(table, vTables[0].Table_status)
            ));
            setTableData(vTables[0].table_details);
            setTableLAStatus(vTables[0].Table_status);
            dispatch(setTables({
                tables: vTables[0].table_details
            }));
        }
    }

    /**
     * Function to set Analysis in Progress flag as table level
     * @param {*} table 
     * @param {*} tableStatus 
     */
    const setTableStatus = async (table, tableStatus) => {
        if (tableStatus[table.id] === "In-Progress") {
            table.LAInProc = true;
            setIsThresholdDisabled(true);
        }
        else {
            table.LAInProc = false;
        }
    }

    /**
     * Function to switch tabs
     * @param {*} tab 
     */
    const handleTabClick = (tab) => {
        setSelectedTab(tab.elementId);
        switch (tab.elementId) {
            case vTabIds.tables:
                document.getElementById(vTabIds.tables).style.display = "block";
                document.getElementById(vTabIds.users).style.display = "none";
                document.getElementById(vTabIds.documents).style.display = "none";
                break;
            case vTabIds.users:
                document.getElementById(vTabIds.tables).style.display = "none";
                document.getElementById(vTabIds.users).style.display = "block";
                document.getElementById(vTabIds.documents).style.display = "none";
                break;
            case vTabIds.documents:
                document.getElementById(vTabIds.tables).style.display = "none";
                document.getElementById(vTabIds.users).style.display = "none";
                document.getElementById(vTabIds.documents).style.display = "block";
                break;
            default:
        }
    };

    /**
     * Function called when add table button clicked
     */
    const addTable = () => {
        setAddClicked(true);
        setDisplayAddTableModal(true);
    }
    /**
         * Function called when edit table button clicked
         */
    const onEditClick = (vTable) => {
        setSelectedTableData(vTable);
        setAddClicked(false);
        setDisplayAddTableModal(true);
    }
    /**
    * Function called when edit table button clicked
    */
    const onDeleteClick = (vTable) => {
        setSelectedDeleteTable(vTable.id);
        setShowDeleteTableModal(true);
    }
    /**
      * Function to close a Delete_relationship modal.
      */

    const handleCloseModal = () => {
        setShowDeleteTableModal(false);

    }

    /**
     * Function to delete a Delete_relationship .
     */

    const handleDelete = async () => {
        if (projectId && selectedDeleteTable) {
            const response = await tableService.deleteTable(projectId, selectedDeleteTable);
            if (response?.message === "Delete table executed successfully.") {
                setShowDeleteTableModal(false);
                getTables(projectId);
                setShowDeleteToast(true);
            }
        }
    };
    /**
     * Function will trigger when add table modal will be clsed
     * @param {*} vStatus 
     * @param {*} vError 
     */
    const closeModal = (vStatus, vError, isAddClicked) => {
        setDisplayAddTableModal(false);
        if (vStatus === 'Success') {
            getTables();
            setDisplaySuccess(true);
            if (isAddClicked === true) {
                setMsgSuccess('Table added successfully');
            }
            else {
                setMsgSuccess('Table edits updated');
            }
        } else {
            setDisplayFailure(true);
            if (isAddClicked) {
                setMsgFailure(vError || 'Failed to add table.');
            }
            else {
                setMsgFailure('Failed to edit table.');
            }
        }
    }

    /**
     * Open selected table
     * @param {*} e 
     */
    const onRowSelection = (e) => {
        if (e.column.colId !== "actions") {
            dispatch(setSelectedTable({
                selectedTable: e.data
            }))

        }
    };

    /**
     * Close selected table
     * @param {*} vClose 
     */
    const closeSelectedTable = (vClose) => {
        if (vClose) {
            dispatch(setSelectedTable({
                selectedTable: null
            }))
        }
    };

    return (
        <div className="projectContainer">
            <div className="projectDetailsBreadcrumb">
                <MotifIconButton
                    aria-label="Click here to go back to Project Dashboard"
                    onClick={() => navToHome()}
                    className='btnBack'
                    data-testid="testIdBtnBack"
                >
                    <img src={iconArrowBack} alt='Previous' />
                </MotifIconButton>
                <span className='projectName'>{projectName}</span>
            </div>
            <div className='projectContent'>
                <MotifTabNavigation defaultActiveKey={0} className='projectTab'>
                    {
                        vTabs.map((vTab) =>
                            <MotifTabControl key={vTab.id} onClick={() => handleTabClick(vTab)} className='btnProjectTab'>
                                {vTab.title}
                            </MotifTabControl>
                        )
                    }
                </MotifTabNavigation>
                <div className='tabContentTables' id='idTabContentTables'>
                    {!selectedTable &&
                        <MotifTable
                            rowData={tableData}
                            columnDefs={colDefs}
                            suppressCellFocus
                            suppressRowClickSelection
                            suppressRowHoverHighlight
                            className='tablesTable'
                            overlayNoRowsTemplate={noRowsTemplate}
                            enableSorting={false}
                            onCellClicked={(e) => onRowSelection(e)}
                        ></MotifTable>
                    }
                    {selectedTable &&
                        <TableDetails tableLAStat={tableLAStatus[selectedTable.id]} onCloseSelectedTable={closeSelectedTable} tables={tableData}></TableDetails>
                    }
                    {showDeleteToast && (
                        <MotifToast
                            actionName=""
                            onClose={() => {
                                setShowDeleteToast(false);
                            }}
                            variant="info"
                            className='notifyToast'
                            icons={{ toast: <img src={iconWhiteCheckCircle} alt="" /> }}
                        >
                            Table has been deleted
                        </MotifToast>
                    )}
                </div>
                <div className='tabContentUsers' id='idTabContentUsers'>
                    <MemberList />
                </div>
                <div className='tabContentDocuments' id='idTabContentDocuments'>
                    <DocumentLists />
                </div>
                {(displaySuccess || displayFailure) &&
                    <MotifToast
                        actionName=""
                        onClose={() => {
                            if (displaySuccess) {
                                setDisplaySuccess(false);
                            } else {
                                setDisplayFailure(false);
                            }
                        }}
                        variant={displaySuccess ? "success" : "error"}
                        className='notifyToast'
                        icons={{ toast: <img src={displaySuccess ? iconSuccess : iconError} alt={displaySuccess ? "Success" : "failure"} /> }}
                    >
                        {displaySuccess ? msgSuccess : msgFailure}
                    </MotifToast>
                }
                {(showEditToast) &&
                    <MotifToast
                        actionName=""
                        onClose={() => {
                            setShowEditToast(false);
                        }}
                        variant={"success"}
                        className='notifyToast'
                        icons={{ toast: <img src={iconSuccess} alt="Success" /> }}
                    >
                        Table edits updated.
                    </MotifToast>
                }
            </div>
            {(selectedTab === vTabIds.tables) && (!selectedTable) &&
                <div className='genericFooter'>
                    <div className='footerInner alignEnd'>
                        <MotifButton onClick={() => addTable()} className='btnAddTable'>
                            <img src={iconAdd} alt='Add Table' />{/*
                            */}Add Table
                        </MotifButton>
                        {displayAddTableModal &&
                            <AddTable
                                openModal={displayAddTableModal}
                                setOpenModal={setDisplayAddTableModal}
                                projectId={projectId}
                                projectName={projectName}
                                tables={tableData}
                                closeModal={closeModal}
                                isAddClicked={addClicked}
                                selectedTable={selectedTableData}
                            />
                        }
                        {showDeleteTableModal && (
                            <DeleteTableModal
                                onClose={handleCloseModal}
                                handleDelete={handleDelete}
                            />
                        )}
                        <MotifButton onClick={() => openThresholds()} variant='secondary' className='btnThreshold'
                            disabled={isThresholdDisabled}>
                            <img src={iconThresholds} className={isThresholdDisabled ? 'imgThreshold': ''} alt='Thresholds' />{/*
                            */}Thresholds
                        </MotifButton>
                        {displayThresholdsModal &&
                            <Thresholds
                                openModal={displayThresholdsModal}
                                setOpenModal={setDisplayThresholdsModal}
                                closeModal={closeThresholds}
                            />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectDetails;