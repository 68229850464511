import axios from "../services/axiosService"

const axiosMiddleware = store => next => action => {
    if (action && action.type === 'auth/setAuthenticated') {
        const accessToken = action.payload?.accessToken;
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }
    return next(action);
};

export default axiosMiddleware;
