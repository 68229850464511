import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { MotifInput, MotifLabel, MotifErrorMessage, MotifTextArea, MotifButton } from "@ey-xd/motif-react";
import MotifFormField from '@ey-xd/motif-react/FormField';
import MotifSelect, { MotifOption } from '@ey-xd/motif-react/Select';
import iconError from "../../../../assets/images/error.svg"
import "./EditPersonalPrompt.scss";
import { setIsValidationErrorOnAddNewPrompt, setNewPersonalPromptName, setNewPersonalPrompts, setNewPersonalTableType } from "../../../../auth/promptSlice";

function EditPersonalPrompt ({
        personalPrompt, 
        setShowEditPersonalPrompt, 
        handlePromptSave, 
        useAsAddPersonalPrompt=false,
        tableTypeRefs=[]
    }) {
    const [isErrorOnPromptName, setIsErrorOnPromptName] = useState(false);
    const [errorMsgPromptName, setErrorMsgPromptName] = useState("");
    const [promptMethods, setPromptMethods] = useState(personalPrompt?.prompts);
    const [promptName, setPromptName] = useState(personalPrompt.promptName);
    const {user} = useSelector((state) => state.user);
    const {personalPrompts} = useSelector((state) => state.prompt);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [tableType] = useState(" ");
    const dispatch = useDispatch();
    //  Error Messages
    const errMsgAlphaNumWithMax50Chars = "Please enter alphanumeric name, up to 50 characters.";
    const errMsgDuplicateTable = "The name is already in use. Please choose another.";

    useEffect(() => {
        dispatch(setIsValidationErrorOnAddNewPrompt({isValidationErrorOnAddNewPrompt:isErrorOnPromptName}));
        // eslint-disable-next-line
    }, [isErrorOnPromptName])

    const onPromptMethodChange = (event) => {
        const promptMethod = event.target.name;
        const updatedPromptBody = event.target.value;
        let updatedPromptMethods = JSON.parse(JSON.stringify(promptMethods));
        updatedPromptMethods = updatedPromptMethods.map((pm) => {
            if(pm.promptMethod === promptMethod) {
                pm.promptBody = updatedPromptBody
            }
            return pm;
        });
        setPromptMethods(updatedPromptMethods);
        dispatch(setNewPersonalPrompts({prompts: updatedPromptMethods}));
    }

    const onSaveClick = () => {
        let updatedPrompt = Object.assign({}, personalPrompt);
        updatedPrompt.promptName = promptName;
        updatedPrompt.prompts = promptMethods.map((pm) => {
            return {
                promptMethod: pm.promptMethod,
                promptBody: pm.promptBody
            }
        });
        updatedPrompt.metadata = {
            "createdAt": new Date().toJSON(),
            "createdBy": user?.username
        }

        handlePromptSave(updatedPrompt);
    }

    /**
     * Function to validate prompt name field
     * @param {*} promptName 
     */
    const onPromptNameChange = (promptName) => {
        dispatch(setNewPersonalPromptName({promptName: promptName}));
        setPromptName(promptName);
        promptName = promptName ? promptName.trim() : '';
        //  Check for Alphanumeric with space & maximum 50 characters validation
        const vValid = /^[a-zA-Z0-9- ]*$/.test(promptName);
        if (
            (promptName === '') ||
            !vValid ||
            (promptName.length > 50)
        ) {
            setIsErrorOnPromptName(true);
            setErrorMsgPromptName(errMsgAlphaNumWithMax50Chars);
            setSaveDisabled(true);
            return;
        }
        const filteredTable = personalPrompts?.length > 0 ? 
            (personalPrompts?.filter(prompt => (prompt.promptName.trim().toLowerCase() === promptName.trim().toLowerCase()))) : null;
        if (useAsAddPersonalPrompt ? (filteredTable && filteredTable.length > 0 ): ((promptName.trim().toLowerCase() !== personalPrompt.promptName.trim().toLowerCase()) && (filteredTable && filteredTable.length > 0))){
            setIsErrorOnPromptName(true);
            setErrorMsgPromptName(errMsgDuplicateTable);
            setSaveDisabled(true);
            return;
        }
        setIsErrorOnPromptName(false);
        setErrorMsgPromptName("");
        setSaveDisabled(false);
    }

    const onTableTypeChange = (tableType) => {
        dispatch(setNewPersonalTableType({tableType: tableType}));
    }

    return (
        <div className="editPersonalPrompt">
            {
                useAsAddPersonalPrompt &&
                <div className='fldName'>
                    <MotifFormField>
                        <MotifLabel className='fldLabel'>
                            Table Type
                        </MotifLabel>
                        {(tableTypeRefs?.length > 0) && <MotifSelect
                            value={tableType}
                            onChange={(val) => {onTableTypeChange(val)}}
                            ariaLabelledBy="Table Type"
                            triggerButtonProps={{
                                'aria-describedby': 'Table Type'
                            }}
                            visibleOptions={3}>
                            {tableTypeRefs?.map((element, index) =>
                                <MotifOption value={element.ReferenceValue} key={index}>{element.ReferenceKey}</MotifOption>
                            )}
                        </MotifSelect>}
                    </MotifFormField>
                </div>
            }
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel'>
                        Prompt Name
                    </MotifLabel>
                    <MotifInput
                        onChange={(e) => onPromptNameChange(e.target.value)}
                        name="promptName"
                        type='text'
                        value={promptName}
                        className={isErrorOnPromptName ? 'motif-input-invalid' : ''}
                        hideClearButton={!!isErrorOnPromptName}
                    />
                    {(isErrorOnPromptName) &&
                        <>
                            <img src={iconError} alt='Error' className='iconError topPosPromptName' />
                            <MotifErrorMessage className='errorText'>
                                <div>{errorMsgPromptName}</div>
                            </MotifErrorMessage>
                        </>
                    }
                    {(!isErrorOnPromptName) &&
                        <div className='fldDetails'>
                            Please enter alphanumeric name, up to 50 characters.
                        </div>
                    }
                </MotifFormField>
            </div>
            {
                promptMethods?.map((prompt) => (
                    <div className='fldName'>
                        <MotifFormField>
                            <MotifLabel className='fldLabel'>
                                {prompt.promptMethodName}
                            </MotifLabel>
                            <MotifTextArea
                                name={prompt.promptMethod}
                                value={prompt.promptBody}   
                                onChange={(e) => onPromptMethodChange(e)} 
                            />
                            { useAsAddPersonalPrompt &&
                                <div className='fldDetails'>
                                    Please enter alphanumeric text
                                </div>
                            }
                        </MotifFormField>  
                    </div>
                ))
            }
            {
                !useAsAddPersonalPrompt &&
                <div className='genericFooter'>
                    <div className='footerInner alignEnd'>
                        <MotifButton onClick={onSaveClick} className='' type="button" variant="primary" disabled={saveDisabled}>
                            Save
                        </MotifButton>
                        <MotifButton onClick={()=>{setShowEditPersonalPrompt(false)}} className='' type="button" variant="secondary">
                            Cancel
                        </MotifButton>
                    </div>
                </div>
            }
        </div>
    ); 
}

EditPersonalPrompt.propTypes = {
    personalPromptInput: PropTypes.object,
    setShowEditPersonalPrompt: PropTypes.func,
    handlePromptSave: PropTypes.func,
    useAsAddPersonalPrompt: PropTypes.bool,
    tableTypeRefs: PropTypes.array
}

export default EditPersonalPrompt;