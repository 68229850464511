/**
 * DaaS UI
 * Created on 14-06-2024
 */
import './App.scss';
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from 'react-redux';
import { loginRequest } from "./auth/authConfig";
import { groupConfig } from "./config/groupConfig";
import GraphService from './services/graphService';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProjectDetails from './components/pages/projectDetails/ProjectDetails';
import PromptLibrary  from './components/pages/promptLibrary/PromptLibrary';
import Projects from './components/pages/projects/Projects';
import Home from './components/pages/home/Home';
import Layout from './components/pages/layout/Layout';
import ProjectThreshold from './components/pages/projectThreshold/projectThreshold';
import store from './store';
import { acquireTokens } from './services/authService';
import AuthCallback from './components/molecules/Authentication/AuthCallback';
import { setUserGroupName } from './auth/userSlice';
import { setUserGroupNamePlatform } from './auth/userSlice';
import PersonalPromptList from './components/pages/promptLibrary/PersonalPromptList';
import SystemTab from './components/pages/promptLibrary/SystemTab';
import CentralTab from './components/pages/promptLibrary/CentralTab';
import PersonalPromptDetails from './components/pages/promptDetails/PersonalPromptDetails';
import CentralPromptDetails from './components/pages/promptDetails/CentralPromptDetails';
import Utils from "./utils/Utils";

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const utils = new Utils();
  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };

  let allgroups = [];
  
  useEffect(() => {
    
    const fetchTokens = async () => {
      if (instance.getActiveAccount()) {
        clearTimeout(vTimeout);
      }
      await acquireTokens(instance);
    }
    const vTimeout = setTimeout(fetchTokens, 1000);
    // eslint-disable-next-line
  }, [dispatch])
 
  useEffect(() => {
    const fetchUserGroups = async () => {
      if (user) {
        const userGroupData = await getUserGroups(groupConfig.graphApiUrl + '/me/memberOf');
        // Filter group names for PlatForm Admin
        if (userGroupData) {
          const platFormAdmin = userGroupData.filter(group => utils.isPlatformAdminGroup(group.displayName));
          if (platFormAdmin.length > 0) {
            dispatch(setUserGroupNamePlatform({
              userGroupNamePlatform: groupConfig.groupNamePlatForm
            }));        
          }

          // Filter for SAT and Non-SAT
          const groupAssigned = userGroupData.filter(group => utils.isSatGroup(group.displayName)); //[0].displayName  
          // If user Belongs to SAT or Non Sat then user will be SAT
          if (groupAssigned.length > 0) {
            dispatch(setUserGroupName({
              userGroupName: groupConfig.groupNameSat
            }));         
          } else {
            dispatch(setUserGroupName({
              userGroupName: groupConfig.groupNameNonSat
            }));       
          }
          
        }
      }
    }
    fetchUserGroups();
    // eslint-disable-next-line
  }, [user])

  //Get User Groups for the autenticated user 
  const getUserGroups = async (url) => {
    const graphService = new GraphService();
    const accessToken = await graphService.getToken();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
      }
    };
  
    // Add this in header : ConsistencyLevel and value as Eventual
    // Request to get the groups assigned to 
    const response = await fetch(`${url}`, requestOptions);

    if (response.ok) {
      const userGroupData = await response.json();
      allgroups = allgroups.concat(userGroupData.value);
      const nextLink = userGroupData['@odata.nextLink'];
      if (nextLink) {
        await getUserGroups(nextLink);
      }
      return allgroups;

    }
  }

  return (
    <div>
      <AuthenticatedTemplate>
          <Routes>
            <Route path="/signin-oidc" element={<AuthCallback/>} />
            <Route path="/" element={<Layout />} >
              <Route index element={<Navigate to="/home/projects" replace={true} />} />
              <Route path="/home/*" element={<Home />} >
                <Route path="projects" element={<Projects />} />
                <Route path="promptLibrary" element={<PromptLibrary />} >
                <Route path="system" element={<SystemTab/>} />
                  <Route path="central" element={<CentralTab/>} />
                  <Route path="personal" element={<PersonalPromptList />} />
                  <Route path="personal/:id" element={<PersonalPromptDetails />} />
                  <Route path="central/:id" element={<CentralPromptDetails />} />
                </Route>
              </Route>
              <Route path="projectDetails" element={<ProjectDetails />} />
              <Route path="threshold" element={<ProjectThreshold />} />
            </Route>
          </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {handleRedirect}
      </UnauthenticatedTemplate>
    </div>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Provider store={store}>
        <MainContent />
      </Provider>
    </MsalProvider>
  );
};

App.propTypes = {
  instance: PropTypes.object
};

export default App;

