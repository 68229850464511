import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MotifTable from '@ey-xd/motif-react/Table';
import { MotifButton } from '@ey-xd/motif-react';
import MotifToast from '@ey-xd/motif-react/Toast';
import iconAddCircleWhite from '../../../assets/images/add-circle-white.svg';
import iconCheckCircleSuccess from '../../../assets/images/check-circle.svg';
import PromptLibraryService from '../../../services/promptLibraryService'; 
import LoaderComponent from '../../molecules/loader/LoaderComponent';
import './PersonalPromptList.scss';
import { setCentralPrompts as setCentralPromptsToStore, resetNewCentralPrompt } from "../../../auth/promptSlice";
import AddCentralPrompt from '../../molecules/modals/addCentralPrompt/AddCentralPrompt';
import { groupConfig } from '../../../config/groupConfig';

function CentralTab() {
    const [centralPrompts, setCentralPrompts] = useState([]);
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
    const { user, userGroupNamePlatform } = useSelector(state => state.user);
    const { defaultNewCentralPrompt, newCentralPrompt } = useSelector(state => state.prompt);
    const [showAddCentralPromptModal, setShowAddCentralPromptModal] = useState(false);
    const [showPromptAddSuccessToast, setShowPromptAddSuccessToast] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const colDefs = [
        {
            field: "tableType",
            headerName: "Table Type",
            wrapText: true,
            autoHeight: true,
            flex:2
        },
        {
            field: "promptName",
            headerName: "Prompt Name",
            wrapText: true,
            autoHeight: true,
            flex:2
        }
    ];
    const promptLibraryService = new PromptLibraryService();

    useEffect(() => {
        if(user) {
            getCentralPrompt(user.username)
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Function to get personal prompts associated with user
     */
    const getCentralPrompt = async (userId) => {
        setIsLoadingInProgress(true);
        const vCentralPrompts = await promptLibraryService.getCentralPrompt(userId);
        setCentralPrompts(vCentralPrompts);
        dispatch(setCentralPromptsToStore({
            centralPrompts: vCentralPrompts
        }));
        setIsLoadingInProgress(false);
    }

    const handleCloseAddCentralPromptModal = () => {
        dispatch(resetNewCentralPrompt({ newCentralPrompt: defaultNewCentralPrompt }));
        setShowAddCentralPromptModal(false);
    }

    const handleConfirmAddCentralPromptModal = async () => {
        let newCentralPromptToAdd = JSON.parse(JSON.stringify(newCentralPrompt));
        newCentralPromptToAdd.createdBy = user.username;
        newCentralPromptToAdd.prompts.map((p) => delete p.promptMethodName);
        setIsLoadingInProgress(true);
        const response = await promptLibraryService.addCentralPrompt(newCentralPromptToAdd);
        dispatch(resetNewCentralPrompt({ newCentralPrompt: defaultNewCentralPrompt }));
        setShowAddCentralPromptModal(false);
        setIsLoadingInProgress(false);
        if(user) {
            getCentralPrompt(user.username);
        }
        if(response?.status === 200) {
            setShowPromptAddSuccessToast(true);
        }
    }
   
    const handleCellClick = (e) => {
        if (e.column.colId !== "actions") {
            navigate(generatePath(":id", {id: e.data.id}));            
        }
    }

    return (
        <React.Fragment>
            <LoaderComponent show={isLoadingInProgress}/>
            <div className="personalPromptContainer">
                <div className="view-prompt-table">
                    <MotifTable
                        id="personalPromptTable"
                        rowData={centralPrompts}
                        columnDefs={colDefs}
                        suppressCellFocus
                        suppressRowClickSelection
                        suppressRowHoverHighlight
                        suppressNoRowsOverlay
                        onCellClicked={handleCellClick}
                        className='personalPromptTable' >
                    </MotifTable>
                    
                    {
                        showPromptAddSuccessToast &&
                        <MotifToast
                            variant="success"
                            onClose={() => {
                                setShowPromptAddSuccessToast(false);
                            }}
                            icons={{toast:<img src={iconCheckCircleSuccess} alt='Success' />}}
                            className='notifyToast'
                        >
                            Prompt added successfully
                        </MotifToast>
                    }
                </div>
            </div>
            <div className='genericFooter'>
                <div className='footerInner alignEnd'>
                    {
                        userGroupNamePlatform === groupConfig.groupNamePlatForm ?
                        (<MotifButton type="button" className="btnNewPrompt" onClick={() => setShowAddCentralPromptModal(true)}>
                            <img src={iconAddCircleWhite} alt="Add new prompt" className="imgAddNewPrompt"/>
                            Add New Prompt
                        </MotifButton>) : <span className="emptyFooter"></span>
                    }
                </div>
            </div>
            
            <AddCentralPrompt
                showAddCentralModal={showAddCentralPromptModal}
                handleCloseAddCentralPromptModal={handleCloseAddCentralPromptModal}
                handleConfirmAddCentralPromptModal={handleConfirmAddCentralPromptModal} />
        </React.Fragment>
    );
}

export default CentralTab;