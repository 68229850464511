/**
 * The functional component assists in listing project members 
 * 
 */

import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MotifTable,
  MotifBadge,
  MotifToast,
  MotifButton,
  MotifCard,
  MotifCardHeader
} from "@ey-xd/motif-react";


import "./MemberList.scss";
import communityIcon from "../../../assets/images/community.svg";
import refreshIcon from "../../../assets/images/refresh.svg";
import iconCheckCircle from "../../../assets/images/check-circle.svg";
import defaulUserAvatar from "../../../assets/images/default-user-icon.jpg";

import GraphService from "../../../services/graphService";
import MemberService from "../../../services/memberService";
import iconProgressLoaderCircle from "../../../assets/images/Loading_indicator_circles.svg";
import { setActiveProjectUserRole } from "../../../auth/userSlice";

export function MemberList() {
  
  let [editrowData, setEditrowData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { user, activeProject } = useSelector(state => state.user);
  const projectId = activeProject?.project_UUID;
  const projectName = activeProject?.projectName;
  const dataHostingLocation = activeProject?.dataHostingLocation;

  const dispatch = useDispatch();

  /**
   * Intialize Token for Graph Api
   */
  useEffect(() => {
    fetchMemberDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This function helps to fetch users data
   * @param {*} refreshFlag  : Boolean
   */
  const fetchMemberDetails = async (refreshFlag) => {
    setShowLoader(true);
    
    let dataReturn = [];
    setEditrowData([]);
    const memberService = new MemberService();
    let data = await memberService.GetProjectDetailById(projectId);
    if (data) {
      dataReturn = data;
      const curUserRole = data.filter(element => (element.email === user.username));
      if (curUserRole && (curUserRole.length > 0)) {
        dispatch(setActiveProjectUserRole({
          activeProjectUserRole: curUserRole[0].role
        }));
      }
      const graphService = new GraphService();
      let getMembersAdded = [];
      for (const element of dataReturn) {
        const userbymail = await graphService.getUserDetailsByInput(element.email);
        let userAvatar = defaulUserAvatar;
        if (userbymail) {
          const userAvatarBlob = await graphService.getUserAvatar(userbymail.value[0].id);
          if (userAvatarBlob) {
            const blob = new Blob([userAvatarBlob], { type: "image/jpeg" });
            userAvatar = URL.createObjectURL(blob);
          }
        }
        let newRow = [];
        // if no data added in the add member page  then assign the owner details here
        newRow = {
          FullName: element.fullName,
          Title: element.title,
          Role: element.role,
          Email: element.email,
          FirstName: element.firstName,
          LastName: element.lastName,
          Project_UUID: element.project_UUID,
          ProjectName: projectName,
          Avatar: userAvatar,
          GroupName: element.groupName
        };
        getMembersAdded.push(newRow);
      }
      setEditrowData((prevState) => [...prevState, ...getMembersAdded]);
      setShowLoader(false);
    }
    if (refreshFlag === true) {
      setShowMessage(true);
    }
  };


  /**
   * This assists in configuring column headers.
   */
  const colDefs = [
    {
      headerName: "Name and email address",
      field: "FullName",
      wrapText: true,
      flex: 2,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <PeoplePickerAvatarRenderer
            value={params}
          ></PeoplePickerAvatarRenderer>
        );
      }
    },
    {
      headerName: "Role",
      field: "Role",
      wrapText: true,
      width: 150,
      autoHeight: true,
      colId: "role"
    }
  ];

  /**
   * Implementing avatars within a Motif table
   * @param {*} : User data 
   */
  const PeoplePickerAvatarRenderer = (value) => {
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
      if (value.value.data.isAlreadySaved) {
        setUserName(value.value.data.FullName);
        setUserEmail(value.value.data.Email);

        if (value.value.data.Avatar) {
          const reader = new FileReader();
          reader.onload = (e) => {
            setAvatarUrl(e.target.result);
          };
          reader.readAsDataURL(value.value.data.Avatar);
        } else {
          setAvatarUrl(defaulUserAvatar);
        }
      } else {
        if (value.value.data.Avatar) {
          setAvatarUrl(value.value.data.Avatar);
        } else {
          setAvatarUrl(defaulUserAvatar);
        }
        setUserName(value.value.data.FullName);
        setUserEmail(value.value.data.Email);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Show the ‘Refresh List’ message for 3 seconds, and then make it disappear
     */
    useEffect(() => {
      setTimeout(() => {
        setShowMessage(false);
      }, 4000);
    }, []);

    return (
      <div className="avtColumn">
        {<img src={avatarUrl} alt="Avatar" className="avtImage"></img>}
        {
          <div className="Namedisplay">
            {" "}
            {userName || ""}
            <div className="Emaildisplay"> {userEmail || ""}</div>
          </div>
        }
      </div>
    );
  };

  /**
   * This method handles the dismissal of toaster messages.
   */
  const oncloseclick = () => {
    setShowMessage(false);
  };

  const onMemberGridReady = (event) => {
    event.api.sizeColumnsToFit();
  };

  /**
   * Retrieve the most up-to-date records from the API to refresh the member list data.
   */
  const refreshList = () => {
    fetchMemberDetails(true);
  };

  /**
   * Open a new tab to redirect to the Platform application
   */
  const platformRedirectClick = () => {
    const base =  process.env.REACT_APP_MEMBERS_PLATFORM_URL;
    const url = base + "?p_id=" + projectId + "&dataHostingLocation=" + dataHostingLocation;
    window.open(url, '_blank');
  };

  return (
    <div>
      <MotifCard className="appHeader">
        <MotifCardHeader variant="light">
          <span className="motif-h6 listHeader">Members</span>
          <MotifBadge value={editrowData.length} variant="default" className="badge" />
        </MotifCardHeader>
      </MotifCard>
      <div className="memberGrid">
        {showLoader ?
        <div className="loaderDiv">
          <div>
          <img src={iconProgressLoaderCircle} alt='Progress' className="refreshIcon " />
          </div>
          <div className="refreshListText">
            Refreshing List...
          </div>
        </div> : ( <MotifTable
          className="memberTable"
          id="MemberTbl"
          columnDefs={colDefs}
          rowData={editrowData}
          onGridReady={onMemberGridReady}
          suppressCellFocus
          suppressRowClickSelection
          suppressRowHoverHighlight
        />) }
       
<div>
          {showMessage && (
            <MotifToast
              className="notifyToast"
              variant="success"
              icons={{toast: <img src={iconCheckCircle} alt="Success" />}}
              actionName=""
              position=""
              onClose={() => {
                oncloseclick();
              }}
            >
              <div>Refresh Complete</div>
            </MotifToast>
          )}
        </div>
      </div>
      <div className="genericFooter">
      
        <div className="footerInner alignEnd">
          <MotifButton className="btnAccessMember" variant="primary" onClick ={() => platformRedirectClick()} disabled={showLoader}>
            <img src={communityIcon} alt="Member" className="iconAccessMember" />{/*
            */}Access Member List
          </MotifButton>
          <MotifButton className="btnRefresh" variant="secondary" onClick={() => refreshList()} disabled={showLoader}>
            <img src={refreshIcon} alt="Refresh" className="iconRefresh" />{/*
            */}Refresh List
          </MotifButton>
        </div>
      </div>
    </div>
  );
}

export default MemberList;