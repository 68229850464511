/**
 * Thresholds modal
* Created On: 05-08-2024
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/TabNavigation';
import MotifButton from '@ey-xd/motif-react/Button';
import MotifFormField from '@ey-xd/motif-react/FormField';
import { MotifInput, MotifErrorMessage } from '@ey-xd/motif-react';
import MotifTooltip from '@ey-xd/motif-react/Tooltip';
import MotifToast from '@ey-xd/motif-react/Toast';
import MotifAccordion, {
    MotifAccordionTrigger,
    MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifInlineMessage from '@ey-xd/motif-react/InlineMessage';

import './Thresholds.scss';

import iconHelp from '../../../../assets/images/help-circle.svg';
import iconSearch from '../../../../assets/images/search.svg';
import iconError from '../../../../assets/images/error.svg';
import iconInfo from '../../../../assets/images/info.svg';
import iconSuccess from "../../../../assets/images/check-circle.svg";

import ThresholdService from '../../../../services/thresholdService';
import { setTables } from "../../../../auth/tableSlice";
import TableService from '../../../../services/tableService';

const Thresholds = ({
    openModal,
    setOpenModal,
    closeModal
}) => {
    /**
     * Custom constants
     */
    const vProjectAdminRoles = ['Admin', 'Owner'];
    const vTabs = [
        {
            "id": 1,
            "elementId": "idTabContentGlobal",
            "title": "Global settings"
        },
        {
            "id": 2,
            "elementId": "idTabContentTable",
            "title": "Table settings"
        }
    ];
    const vTabIds = { global: "idTabContentGlobal", table: "idTabContentTable" };

    const [disableConfirm, setDisableConfirm] = useState(false);
    const [absoluteThresholdGlobalOld, setAbsoluteThresholdGlobalOld] = useState("");
    const [absoluteThresholdGlobal, setAbsoluteThresholdGlobal] = useState("");
    const [isErrorAbsThresholdGlobal, setIsErrorAbsThresholdGlobal] = useState(false);
    const [absoluteThresholdTable, setAbsoluteThresholdTable] = useState("");
    const [tableThresholdsOld, setTableThresholdsOld] = useState([]);
    const [isErrorAbsThresholdTable, setIsErrorAbsThresholdTable] = useState(false);
    const [percentageThresholdGlobalOld, setPercentageThresholdGlobalOld] = useState("");
    const [percentageThresholdGlobal, setPercentageThresholdGlobal] = useState("");
    const [isErrorPercThresholdGlobal, setIsErrorPercThresholdGlobal] = useState(false);
    const [percentageThresholdTable, setPercentageThresholdTable] = useState("");
    const [isErrorPercThresholdTable, setIsErrorPercThresholdTable] = useState(false);
    const [isMaxChar, setIsMaxChar] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayFailure, setDisplayFailure] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState('');
    const [msgFailure, setMsgFailure] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const [onChange, setOnChange] = useState(false);
    const [selectedTab, setSelectedTab] = useState(vTabIds.global);
    const [tableThresholdsChanged, setTableThresholdsChanged] = useState([]);

    const thresholdService = new ThresholdService();
    const tableService = new TableService();

    const { activeProject, activeProjectUserRole } = useSelector(state => state.user);
    const { tables } = useSelector(state => state.table);
    const projectId = activeProject.project_UUID;

    const [projectTables, setProjectTables] = useState(tables);

    const dispatch = useDispatch();

    useEffect(() => {
        if (projectId) {
            getGlobalThreshold(projectId);
        }
        // eslint-disable-next-line
    }, [projectId]);

    const getGlobalThreshold = async (vProjectId) => {
        const vData = {
            "projectId": vProjectId
        };
        const response = await thresholdService.getGlobalThreshold(vData);
        if (response) {
            if (response.GlobalThreshold_value) {
                onAbsoluteThresholdGlobalChange(response.GlobalThreshold_value, false);
                setAbsoluteThresholdGlobalOld(response.GlobalThreshold_value);
            }
            if (response.GlobalThreshold_percent) {
                onPercentageThresholdGlobalChange(response.GlobalThreshold_percent, false);
                setPercentageThresholdGlobalOld(response.GlobalThreshold_percent);
            }
        }
    }

    /**
     * Function to switch tabs
     * @param {*} tab 
     */
    const handleTabClick = (tab) => {
        setOnChange(false);
        setSelectedTab(tab.elementId);
        switch (tab.elementId) {
          case vTabIds.global:
            document.getElementById(vTabIds.global).style.display = "block";
            document.getElementById(vTabIds.table).style.display = "none";
            break;
          case vTabIds.table:
            clearAccordion();
            document.getElementById(vTabIds.global).style.display = "none";
            document.getElementById(vTabIds.table).style.display = "block";
            break;
          default:
        }
    };

    /**
     * Function to clear opened accordion
     */
    const clearAccordion = () => {
        const vProjectTables = JSON.parse(JSON.stringify(projectTables));
        for (let element of vProjectTables) {
            element['open'] = false;
        }
        Object.preventExtensions(vProjectTables);
        setProjectTables(vProjectTables);
        setSelectedAccordion(null);
        onAbsoluteThresholdTableChange(null, '', false);
        onPercentageThresholdTableChange(null, '', false);
        setIsMaxChar(false);
    };
    
    /**
     * Function to manage when Global Absolute Threshold changes
     * @param {*} e 
     */
    const onAbsoluteThresholdGlobalChange = (data, vChange) => {
        const regex = /^[0-9\b]+$/;
        if ((data !== "") && regex.test(data)) {
            setAbsoluteThresholdGlobal(data);
            setDisableConfirm(false);
            setIsErrorAbsThresholdGlobal(false);
        } else if (data === "") {
            setAbsoluteThresholdGlobal("");
            setDisableConfirm(true);
            setIsErrorAbsThresholdGlobal(true);
        } else if (!regex.test(data)) {
            data = data.slice(0, -1);
            setDisableConfirm(!data);
            setIsErrorAbsThresholdGlobal(false);
        }
        if (
            vChange && 
            (
                (parseInt(data) !== parseInt(absoluteThresholdGlobalOld)) ||
                (parseInt(percentageThresholdGlobal) !== parseInt(percentageThresholdGlobalOld))
            )
        ) {
            setOnChange(true);
        } else {
            setOnChange(false);
        }
    };

    /**
     * Function to manage when Table Absolute Threshold changes
     * @param {*} e 
     */
    const onAbsoluteThresholdTableChange = (vTableId, data, vChange) => {
        const regex = /^[0-9\b]+$/;
        if ((data !== "") && regex.test(data)) {
            setAbsoluteThresholdTable(parseInt(data));
            setDisableConfirm(false);
            setIsErrorAbsThresholdTable(false);
            if (vTableId) {
                const vOldData = tableThresholdsOld.filter(element => (element.id === vTableId))[0];
                let vTableThresholdsChanged = JSON.parse(JSON.stringify(tableThresholdsChanged));
                const tableIndex = vTableThresholdsChanged.findIndex(element => element.includes(vTableId));
                if (
                    (parseInt(vOldData.tableAbsoluteThreshold) !== parseInt(data)) ||
                    (
                        (tableIndex !== -1) &&
                        (parseInt(vOldData.tablePercentageThreshold) !== parseInt(vTableThresholdsChanged[tableIndex][2]))
                    )
                ) {
                    if (tableIndex !== -1) {
                        vTableThresholdsChanged[tableIndex][1] = parseInt(data);
                    } else {
                        vTableThresholdsChanged.push([
                            vTableId,
                            parseInt(data),
                            percentageThresholdTable
                        ]);
                    }
                    Object.preventExtensions(vTableThresholdsChanged);
                    setTableThresholdsChanged(vTableThresholdsChanged);
                } else if (tableIndex !== -1) {
                    vTableThresholdsChanged.splice(tableIndex, 1);
                    Object.preventExtensions(vTableThresholdsChanged);
                    setTableThresholdsChanged(vTableThresholdsChanged);
                }
            }
        } else if (data === "") {
            setAbsoluteThresholdTable("");
            setDisableConfirm(true);
            setIsErrorAbsThresholdTable(true);
        } else if (!regex.test(data)) {
            data = data.slice(0, -1);
            setDisableConfirm(!data);
            setIsErrorAbsThresholdTable(false);
        }
    };

    /**
     * Function to manage when Global Percentage Threshold changes
     * @param {*} e 
     */
    const onPercentageThresholdGlobalChange = (data, vChange) => {
        const regex = /^[0-9\b]+$/;
        if ((data !== "") && regex.test(data) && (data >= 5) && (data <= 100)) {
            setPercentageThresholdGlobal(parseInt(data));
            setDisableConfirm(false);
            setIsErrorPercThresholdGlobal(false);
            setIsMaxChar(false);
        } else if (data === "") {
            setPercentageThresholdGlobal("");
            setDisableConfirm(true);
            setIsErrorPercThresholdGlobal(true);
        } else if (!regex.test(data)) {
            data = data.slice(0, -1);
            setDisableConfirm(!data);
        } else if (data > 100) {
            data = Math.floor(data / 10);
            setDisableConfirm(false);
        } else if (data < 5) {
            setIsMaxChar(true);
            setDisableConfirm(true);
            setIsErrorPercThresholdGlobal(true);
            setPercentageThresholdGlobal(parseInt(data));
        }
        if (
            vChange && 
            (
                (parseInt(data) !== parseInt(percentageThresholdGlobalOld)) ||
                (parseInt(absoluteThresholdGlobal) !== parseInt(absoluteThresholdGlobalOld))
            )
        ) {
            setOnChange(true);
        } else {
            setOnChange(false);
        }
    }

    /**
     * Function to manage when Table Percentage Threshold changes
     * @param {*} e 
     */
    const onPercentageThresholdTableChange = (vTableId, data, vChange) => {
        const regex = /^[0-9\b]+$/;
        let isDataValid = false;
        if ((data !== "") && regex.test(data) && (data >= 5) && (data <= 100)) {
            setPercentageThresholdTable(parseInt(data));
            setDisableConfirm(false);
            setIsErrorPercThresholdTable(false);
            setIsMaxChar(false);
            isDataValid = true;
        } else if (data === "") {
            setPercentageThresholdTable("");
            setDisableConfirm(true);
            setIsErrorPercThresholdTable(true);
        } else if (!regex.test(data)) {
            data = data.slice(0, -1);
            setDisableConfirm(!data);
        } else if (data > 100) {
            data = Math.floor(data / 10);
            setDisableConfirm(false);
            isDataValid = true;
        } else if (data < 5) {
            setIsMaxChar(true);
            setDisableConfirm(true);
            setIsErrorPercThresholdTable(true);
            setPercentageThresholdTable(parseInt(data));
        }
        if (isDataValid) {
            if (vTableId) {
                const vOldData = tableThresholdsOld.filter(element => (element.id === vTableId))[0];
                let vTableThresholdsChanged = JSON.parse(JSON.stringify(tableThresholdsChanged));
                const tableIndex = vTableThresholdsChanged.findIndex(element => element.includes(vTableId));
                if (
                    (parseInt(vOldData.tablePercentageThreshold) !== parseInt(data)) ||
                    (
                        (tableIndex !== -1) &&
                        (parseInt(vOldData.tableAbsoluteThreshold) !== parseInt(vTableThresholdsChanged[tableIndex][1]))
                    )
                ) {
                    if (tableIndex !== -1) {
                        vTableThresholdsChanged[tableIndex][2] = parseInt(data);
                    } else {
                        vTableThresholdsChanged.push([
                            vTableId,
                            absoluteThresholdTable,
                            parseInt(data)
                        ]);
                    }
                    Object.preventExtensions(vTableThresholdsChanged);
                    setTableThresholdsChanged(vTableThresholdsChanged);
                } else if (tableIndex !== -1) {
                    vTableThresholdsChanged.splice(tableIndex, 1);
                    Object.preventExtensions(vTableThresholdsChanged);
                    setTableThresholdsChanged(vTableThresholdsChanged);
                }
            }
        }
    }

    /**
     * Function to update global threshold settings by calling API
     */
    const updateGlobalThreshold = async () => {
        const vData = {
            projectId: projectId,
            globalAbsoluteThresholdupdated: parseInt(absoluteThresholdGlobal),
            globalPercentageThresholdupdate: parseInt(percentageThresholdGlobal)
        };
        setDisableConfirm(true);
        const response = await thresholdService.updateGlobalThreshold('Global', vData);
        if (response && response.message && (response.message === '200')) {
            setDisplaySuccess(true);
            setMsgSuccess('Global settings applied. You can review the Table settings for your tables.');
            setAbsoluteThresholdGlobalOld(vData.globalAbsoluteThresholdupdated);
            setPercentageThresholdGlobalOld(vData.globalPercentageThresholdupdate);
            if (projectId) {
                getTables(projectId);
            }
        } else {
            setDisplayFailure(true);
            setMsgFailure('Failed to update Global threshold settings.');
        }
    }

    /**
     * Function to update table threshold settings by calling API
     */
    const updateTableThreshold = async () => {
        const vData = {
            "update_table": tableThresholdsChanged
        };
        setDisableConfirm(true);
        const response = await thresholdService.updateGlobalThreshold('Table', vData);
        if (response && response[0] && response[0][0] && (response[0][0] === '200')) {
            setDisplaySuccess(true);
            setMsgSuccess('Threshold table settings updated successfully');
            updateTableThresholds(tableThresholdsChanged);
        } else {
            setDisplayFailure(true);
            setMsgFailure('Failed to update table threshold settings.');
        }
    }

    const onSearchChange = (e) => {
        setSearchValue(e.target.value);
        const vSearchedValue = e.target.value.trim();
        if (vSearchedValue && (vSearchedValue !== '')) {
            const vProjectTables = tables.filter(element => (element.tableName.toLowerCase().indexOf(vSearchedValue.toLowerCase()) !== -1));
            setProjectTables(vProjectTables);
        } else {
            setProjectTables(tables);
        }
    };

    /**
     * Function while opening an Accordion
     * @param {*} vTable 
     */
    const onOpenAccordion = (vTable) => {
        if (!vTable.open) {
            const vProjectTables = JSON.parse(JSON.stringify(projectTables));
            for (let element of vProjectTables) {
                if (element.id === vTable.id) {
                    element['open'] = true;
                } else {
                    element['open'] = false;
                }
            }
            Object.preventExtensions(vProjectTables);
            setProjectTables(vProjectTables);
            setSelectedAccordion(vTable.id);
            const tableIndex = tableThresholdsChanged.findIndex(element => element.includes(vTable.id));
            if (tableIndex === -1) {
                onAbsoluteThresholdTableChange(null, vTable?.tableAbsoluteThreshold, false);
                onPercentageThresholdTableChange(null, vTable?.tablePercentageThreshold, false);
                const vTableThresholdsOld = tableThresholdsOld;
                vTableThresholdsOld.push(vTable);
                setTableThresholdsOld(vTableThresholdsOld);
            } else {
                onAbsoluteThresholdTableChange(null, tableThresholdsChanged[tableIndex][1], false);
                onPercentageThresholdTableChange(null, tableThresholdsChanged[tableIndex][2], false);
            }
        }
    };

    /**
     * Function while closing an accordion
     * @param {*} vTable 
     */
    const onCloseAccordion = (vTable) => {
        if (vTable.open) {
            const vProjectTables = JSON.parse(JSON.stringify(projectTables));
            for (let element of vProjectTables) {
                if (element.id === vTable.id) {
                    element['open'] = false;
                    break;
                }
            }
            Object.preventExtensions(vProjectTables);
            setProjectTables(vProjectTables);
            setSelectedAccordion(null);
            onAbsoluteThresholdTableChange(null, '', false);
            onPercentageThresholdTableChange(null, '', false);
            setIsMaxChar(false);
        }
    };

    const updateTableThresholds = (vThresholds) => {
        const vProjectTables = JSON.parse(JSON.stringify(tables));
        vThresholds.forEach(element => {
            const tableIndex = vProjectTables.findIndex(item => (item.id === element[0]));
            if (tableIndex !== -1) {
                vProjectTables[tableIndex].tableAbsoluteThreshold = element[1];
                vProjectTables[tableIndex].tablePercentageThreshold = element[2];
            }
        });
        Object.preventExtensions(vProjectTables);
        setProjectTables(vProjectTables);
        dispatch(setTables({
            tables: vProjectTables
        }));
    };

    /**
     * Function to get tables from backend
     * @param {*} vData 
     */
    const getTables = async (vData) => {
        const vProjectId = vData || projectId;
        const vTables = await tableService.getTables(vProjectId);
        if (vTables[0]?.table_details) {
            setProjectTables(vTables[0].table_details);
            dispatch(setTables({
                tables: vTables[0].table_details
            }));
        }
    }

    return (
        <MotifModal
            show={openModal}
            onClose={() => setOpenModal(!openModal)}
            focusTrapOptions={{
              tabbableOptions: {
                displayCheck: "none",
              },
            }}
            className='thesholdsModal genericModal modalMotifFooterModal'
            size='xl'
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
                className='thresholdsHeader'
            >
                <div className='thresholdsHead' data-testid='testIdTitle'>Thresholds</div>
            </MotifModalHeader>
            <MotifModalBody>
                <MotifTabNavigation defaultActiveKey={0} variant="alt" className='thresholdsTab'>
                    {
                        vTabs.map((vTab) => 
                            <MotifTabControl
                                key={vTab.id}
                                onClick={() => handleTabClick(vTab)}
                                className='btnThresholdsTab'
                                data-testid={'testIdTab' + vTab.id}
                                disabled={isMaxChar && (vTab.elementId !== selectedTab)}
                            >
                                {vTab.title}
                            </MotifTabControl>
                        )
                    }
                </MotifTabNavigation>
                <div className='tabContentGlobal' id='idTabContentGlobal'>
                    <div className='infoText'>
                        The thresholds input will be applied to all tables, but can be individually amended for specific tables.
                    </div>
                    <div className='lblAbsThreshold'>
                        Absolute threshold
                    </div>
                    <div className={vProjectAdminRoles.includes(activeProjectUserRole) ? 'fldAbsThreshold' : 'fldAbsThresholdNonAdmin'}>
                        <MotifFormField className='field-group'>
                            <MotifInput
                                className='inputThreshold'
                                onChange={(e) => onAbsoluteThresholdGlobalChange(e.target.value, true)}
                                type='text'
                                placeholder="Enter Threshold"
                                value={absoluteThresholdGlobal}
                                hideClearButton={true}
                                disabled={!vProjectAdminRoles.includes(activeProjectUserRole)}
                                data-testid='testIdFldAbsThresholdGlobal'
                            />
                            {vProjectAdminRoles.includes(activeProjectUserRole) &&
                                <MotifTooltip
                                    trigger={
                                        <img src={iconHelp} alt='Help' className='helpTooltipIcon' />
                                    }
                                    hideCloseButton={true}
                                    allowClick={false}
                                >
                                    <div className='tooltipAbsThresholdTitle'>Absolute threshold</div>
                                    <div className='tooltipAbsThrehsoldBody'>
                                        The number should be written as a whole value <br />
                                        and will apply in absolute terms, e.g. if <br />
                                        100,000 is set as the threshold, this will apply <br />
                                        to both positive and negative values in a table. <br /><br />
                                        The threshold will be set across all periods <br />
                                        presented in a table, e.g. if 100,000 is set as <br />
                                        the threshold, then any rows in the table where <br />
                                        the value in each period is less than 100,000 <br />
                                        will be excluded.
                                    </div>
                                </MotifTooltip>
                            }
                            {!vProjectAdminRoles.includes(activeProjectUserRole) &&
                                <MotifTooltip
                                    trigger={
                                        <img src={iconHelp} alt='Help' />
                                    }
                                    hideCloseButton={true}
                                    allowClick={false}
                                    contentClassName='tooltipWithoutCloseCenterText'
                                >
                                    Global settings can only be edited by a Project Admin
                                </MotifTooltip>
                            }
                        </MotifFormField>
                    </div>
                    <div className='lblPercThreshold'>
                        Percentage threshold
                    </div>
                    <div className={vProjectAdminRoles.includes(activeProjectUserRole) ? 'fldPercThreshold' : 'fldPercThresholdNonAdmin'}>
                        <MotifFormField className={isMaxChar ? 'field-group motif-input-invalid' : 'field-group'}>
                            <MotifInput
                                className="inputThreshold"
                                onChange={(e) => onPercentageThresholdGlobalChange(e.target.value, true)}
                                type='text'
                                placeholder="Enter Percentage"
                                value={percentageThresholdGlobal}
                                hideClearButton={true}
                                disabled={!vProjectAdminRoles.includes(activeProjectUserRole)}
                                data-testid='testIdFldPercThresholdGlobal'
                            />
                            {isMaxChar &&
                                <img src={iconError} alt='Error' className='iconError topPosThreshold' />
                            }
                            {!isMaxChar && (vProjectAdminRoles.includes(activeProjectUserRole)) &&
                                <MotifTooltip
                                    trigger={
                                        <img src={iconHelp} alt='Help' className='helpTooltipIcon' />
                                    }
                                    hideCloseButton={true}
                                    allowClick={false}
                                >
                                    <div className='tooltipAbsThresholdTitle'>Percentage threshold</div>
                                    <div className='tooltipAbsThrehsoldBody'>Minimum 5%<br /><br />
                                    The value may reflect an increase or <br />
                                    decrease in value presentation. This can be <br />
                                    adjusted for specific tables at a later stage in <br />
                                    the project.</div>
                                </MotifTooltip>
                            }
                            {!isMaxChar && (!vProjectAdminRoles.includes(activeProjectUserRole)) &&
                                <MotifTooltip
                                    trigger={
                                        <img src={iconHelp} alt='Help' />
                                    }
                                    hideCloseButton={true}
                                    allowClick={false}
                                    contentClassName='tooltipWithoutCloseCenterText'
                                >
                                    Global settings can only be edited by a Project Admin
                                </MotifTooltip>
                            }
                        </MotifFormField>
                        {isMaxChar &&
                            <MotifErrorMessage className='errorText'>
                                Threshold setting too low, please enter minimum 5%
                            </MotifErrorMessage>
                        }
                    </div>
                    <MotifInlineMessage
                        icons={{ message: <img src={iconInfo} alt='Message' />}}
                    >
                        The global settings above will be applied to all tables where the thresholds have not been individually amended.
                    </MotifInlineMessage>
                </div>
                <div className='tabContentTable' id='idTabContentTable'>
                    <div className='fldSearch'>
                        <MotifFormField>
                            <MotifInput
                                onChange={onSearchChange}
                                type='text'
                                placeholder="Search"
                                value={searchValue}
                                data-testid='testIdFldSearch'
                            />
                            <img src={iconSearch} alt='Search' className='iconSearch' />
                        </MotifFormField>
                    </div>
                    {projectTables?.map((vTable) => (
                        <MotifAccordion
                            onClose={() => onCloseAccordion(vTable)}
                            onOpen={() => onOpenAccordion(vTable)}
                            style={{
                                flex: "1",
                            }}
                            useChevronIcon={true}
                            className={selectedAccordion === vTable.id ? "accordionTable blue-highlight" : "accordionTable"}
                            key={vTable.id}
                            open={vTable.open}
                        >
                            <MotifAccordionTrigger
                                triggerButtonProps={{
                                    "disabled": isMaxChar
                                }}
                            >
                                <div className="accordionTitle">{vTable.tableName}</div>
                            </MotifAccordionTrigger>
                            <MotifAccordionContent className="accordionContent">
                                <div className='lblAbsThreshold'>
                                    Absolute threshold
                                </div>
                                <div className='fldAbsThreshold'>
                                    <MotifFormField className='field-group'>
                                        <MotifInput
                                            className="inputThreshold"
                                            onChange={(e) => onAbsoluteThresholdTableChange(vTable.id, e.target.value, true)}
                                            type='text'
                                            placeholder="Enter Threshold"
                                            value={absoluteThresholdTable}
                                            hideClearButton={true}
                                        />
                                        <MotifTooltip
                                            contentClassName="absolutetoolhelp"
                                            trigger={
                                                <img src={iconHelp} alt='Help' className='helpTooltipIcon' />
                                            }
                                            hideCloseButton={true}
                                            allowClick={false}
                                        >
                                            <div className='tooltipAbsThresholdTitle'>Absolute threshold</div>
                                            <div className='tooltipAbsThrehsoldBody'>
                                                The number should be written as a whole value <br />
                                                and will apply in absolute terms, e.g. if <br />
                                                100,000 is set as the threshold, this will apply <br />
                                                to both positive and negative values in a table. <br /><br />
                                                The threshold will be set across all periods <br />
                                                presented in a table, e.g. if 100,000 is set as <br />
                                                the threshold, then any rows in the table where <br />
                                                the value in each period is less than 100,000 <br />
                                                will be excluded.
                                            </div>
                                        </MotifTooltip>
                                    </MotifFormField>
                                </div>
                                <div className='lblPercThreshold'>
                                    Percentage threshold
                                </div>
                                <div className='fldPercThreshold'>
                                    <MotifFormField className={isMaxChar ? 'field-group motif-input-invalid' : 'field-group'}>
                                        <MotifInput
                                            className='inputThreshold'
                                            onChange={(e) => onPercentageThresholdTableChange(vTable.id, e.target.value, true)}
                                            type='text'
                                            placeholder="Enter Percentage"
                                            value={percentageThresholdTable}
                                            hideClearButton={true}
                                        />
                                        {isMaxChar &&
                                            <img src={iconError} alt='Error' className='iconError topPosThreshold' />
                                        }
                                        {!isMaxChar &&
                                            <MotifTooltip
                                                contentClassName="absolutetoolhelp"
                                                trigger={
                                                    <img src={iconHelp} alt='Help' className='helpTooltipIcon' />
                                                }
                                                hideCloseButton={true}
                                                allowClick={false}
                                            >
                                                <div className='tooltipAbsThresholdTitle'>Percentage threshold</div>
                                                <div className='tooltipAbsThrehsoldBody'>
                                                    Minimum 5%
                                                    <br /><br />
                                                    The value may reflect an increase or <br />
                                                    decrease in value presentation. This can be <br />
                                                    adjusted for specific tables at a later stage in <br />
                                                    the project.
                                                </div>
                                            </MotifTooltip>
                                        }
                                    </MotifFormField>
                                    {isMaxChar &&
                                        <MotifErrorMessage className='errorText'>
                                            Threshold setting too low, please enter minimum 5%
                                        </MotifErrorMessage>
                                    }
                                </div>
                            </MotifAccordionContent>
                        </MotifAccordion>
                    ))}
                </div>
            </MotifModalBody>
            {(displaySuccess || displayFailure) &&
                <MotifToast
                    actionName=""
                    onClose={() => {
                        if (displaySuccess ) {
                            setDisplaySuccess(false);
                        } else {
                            setDisplayFailure(false);
                        }
                    }}
                    variant={displaySuccess ? "success" : "error"}
                    className='notifyToast'
                    icons={{toast: <img src={displaySuccess ? iconSuccess : iconError} alt="" />}}
                >
                    {displaySuccess ? msgSuccess : msgFailure}
                </MotifToast>
            }
            <MotifModalFooter className='thresholdsFooter'>
                <MotifButton
                    onClick={() => setOpenModal(!openModal)}
                    type="button"
                    variant="secondary"
                    className='btnCancel'
                    data-testid='testIdCancel'
                >
                    Cancel
                </MotifButton>
                <MotifTooltip trigger=
                    {
                        <MotifButton
                            onClick={() => (selectedTab === 'idTabContentGlobal') ? updateGlobalThreshold() : updateTableThreshold()}
                            type="button"
                            className='btnConfirm'
                            disabled={
                                disableConfirm ||
                                (
                                    (selectedTab === 'idTabContentGlobal') && 
                                    (
                                        isErrorAbsThresholdGlobal || 
                                        isErrorPercThresholdGlobal || 
                                        !onChange
                                    )
                                ) ||
                                (
                                    (selectedTab === 'idTabContentTable') && 
                                    (
                                        isErrorAbsThresholdTable || 
                                        isErrorPercThresholdTable ||
                                        !tableThresholdsChanged.length
                                    )
                                )
                            }
                            data-testid='testIdConfirm'
                        >
                            Confirm
                        </MotifButton>
                    }
                    contentClassName='tooltipWithoutCloseCenterText'
                    allowHover={isMaxChar}
                    allowClick={false}
                    hideCloseButton={true}
                 >
                    Resolve errors to continue 
                </MotifTooltip>
            </MotifModalFooter>
        </MotifModal>
    );
};

Thresholds.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    closeModal: PropTypes.func
};

export default Thresholds;