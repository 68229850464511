import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MotifTable from '@ey-xd/motif-react/Table';
import { MotifIconButton } from '@ey-xd/motif-react/Button';
import { MotifButton } from '@ey-xd/motif-react';
import MotifToast from '@ey-xd/motif-react/Toast';
import iconDelete from '../../../assets/images/delete-table.svg';
import iconAddCircleWhite from '../../../assets/images/add-circle-white.svg';
import iconCheckCircleSuccess from '../../../assets/images/check-circle.svg';
import iconCheckCircleInfo from '../../../assets/images/check-circle-info.svg';
import PromptLibraryService from '../../../services/promptLibraryService'; 
import LoaderComponent from '../../molecules/loader/LoaderComponent';
import DeletePrompt from '../../molecules/modals/deletePrompt/DeletePrompt';
import './PersonalPromptList.scss';
import { resetNewPersonalPrompt, setPersonalPrompts as setPersonalPromptsToStore } from "../../../auth/promptSlice";
import AddPersonalPrompt from '../../molecules/modals/addPersonalPrompt/AddPersonalPrompt';

function PersonalPromptList() {
    const [personalPrompts, setPersonalPrompts] = useState([]);
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPromptDeleteSuccessToast, setShowPromptDeleteSuccessToast] = useState(false);
    const [showPromptAddSuccessToast, setShowPromptAddSuccessToast] = useState(false);
    const [showAddPersonalPromptModal, setShowAddPersonalPromptModal] = useState(false);
    const { user } = useSelector(state => state.user);
    const { defaultNewPersonalPrompt, newPersonalPrompt } = useSelector(state => state.prompt);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const colDefs = [
        {
            field: "tableType",
            headerName: "Table Type",
            wrapText: true,
            autoHeight: true,
            flex:1,
            minWidth:120
        },
        {
            field: "promptName",
            headerName: "Prompt Name",
            wrapText: true,
            autoHeight: true,
            flex:1,
            minWidth:120
        },
        {
            field: "",
            headerName: "Actions",
            wrapText: true,
            autoHeight: true,
            flex:1,
            sortable:false,
            minWidth:100,
            maxWidth:120,
            colId:"actions",
            cellRenderer: (params) => (
                <MotifIconButton
                    aria-label="Delete prompt"
                    className="deleteIcon"
                    id={params.data.id}
                    onClick={() => handlePromptDelete(params.data)} >
                        <img src={iconDelete} alt="Delete prompt" />
                </MotifIconButton>
            )
        }
    ];
    const promptLibraryService = new PromptLibraryService();

    useEffect(() => {
        if(user) {
            getPersonalPrompts(user.username)
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Function to get personal prompts associated with user
     */
    const getPersonalPrompts = async (userId) => {
        setIsLoadingInProgress(true);
        const vPersonalPrompts = await promptLibraryService.getPersonalPrompts(userId);
        setPersonalPrompts(vPersonalPrompts);
        dispatch(setPersonalPromptsToStore({
            personalPrompts: vPersonalPrompts
        }));
        setIsLoadingInProgress(false);
    }

    const handlePromptDelete = (prompt) => {
        setSelectedPrompt(prompt);
        setShowDeleteModal(true);
    }

    const handleCloseDeletePromptModal = () => {
        setSelectedPrompt({});
        setShowDeleteModal(false);
    }

    const handleConfirmDeletePrompt = async() => {
        setIsLoadingInProgress(true);
        const response = await promptLibraryService.deletePersonalPrompt(selectedPrompt.id);
        setSelectedPrompt({});
        setShowDeleteModal(false);
        setIsLoadingInProgress(false);
        if(user) {
            getPersonalPrompts(user.username);
        }
        if(response?.status === 200) {
            setShowPromptDeleteSuccessToast(true);
        }
    }

    const handleCloseAddPersonalPromptModal = () => {
        dispatch(resetNewPersonalPrompt({ newPersonalPrompt: defaultNewPersonalPrompt }));
        setShowAddPersonalPromptModal(false);
    }

    const handleConfirmAddPersonalPromptModal = async () => {
        let newPersonalPromptToAdd = JSON.parse(JSON.stringify(newPersonalPrompt));
        newPersonalPromptToAdd.createdBy = user.username;
        newPersonalPromptToAdd.prompts.map((p) => { delete p.promptMethodName; return null; });
        setIsLoadingInProgress(true);
        const response = await promptLibraryService.addPersonalPrompt(newPersonalPromptToAdd);
        dispatch(resetNewPersonalPrompt({ newPersonalPrompt: defaultNewPersonalPrompt }));
        setShowAddPersonalPromptModal(false);
        setIsLoadingInProgress(false);
        if(user) {
            getPersonalPrompts(user.username);
        }
        if(response?.status === 200) {
            setShowPromptAddSuccessToast(true);
        }
    }

    const handleCellClick = (e) => {
        if (e.column.colId !== "actions") {
            navigate(generatePath(":id", {id: e.data.id}));            
        }
    }

    return (
        <React.Fragment>
            <LoaderComponent show={isLoadingInProgress}/>
            <div className="personalPromptContainer">
                <div className="view-prompt-table">
                    <MotifTable
                        id="personalPromptTable"
                        rowData={personalPrompts}
                        columnDefs={colDefs}
                        suppressCellFocus
                        suppressRowClickSelection
                        suppressRowHoverHighlight
                        suppressNoRowsOverlay
                        onCellClicked={handleCellClick}
                        className='personalPromptTable' >
                    </MotifTable>
                    {
                        showPromptDeleteSuccessToast &&
                        <MotifToast
                            variant="info"
                            onClose={() => {
                                setShowPromptDeleteSuccessToast(false);
                            }}
                            icons={{toast:<img src={iconCheckCircleInfo} alt="Success" />}}
                            className='notifyToast'
                        >
                            Prompt deleted successfully
                        </MotifToast>
                    }
                    {
                        showPromptAddSuccessToast &&
                        <MotifToast
                            variant="success"
                            onClose={() => {
                                setShowPromptAddSuccessToast(false);
                            }}
                            icons={{toast:<img src={iconCheckCircleSuccess} alt='Success' />}}
                            className='notifyToast'
                        >
                            Prompt added successfully
                        </MotifToast>
                    }
                </div>
            </div>
            <div className='genericFooter'>
                <div className='footerInner alignEnd'>
                    <MotifButton type="button" className="btnNewPrompt" onClick={() => {dispatch(resetNewPersonalPrompt({ newPersonalPrompt: defaultNewPersonalPrompt })); setShowAddPersonalPromptModal(true)}}>
                        <img src={iconAddCircleWhite} alt="Add new prompt" className="imgAddNewPrompt"/>
                        Add New Prompt
                    </MotifButton>
                </div>
            </div>

            <DeletePrompt 
                prompt={selectedPrompt} 
                showDeleteModal={showDeleteModal}
                handleCloseDeletePromptModal={handleCloseDeletePromptModal}
                handleConfirmDeletePrompt={handleConfirmDeletePrompt} />

            <AddPersonalPrompt
                showAddPersonalModal={showAddPersonalPromptModal}
                handleCloseAddPersonalPromptModal={handleCloseAddPersonalPromptModal}
                handleConfirmAddPersonalPromptModal={handleConfirmAddPersonalPromptModal} />
        </React.Fragment>
    );
}

export default PersonalPromptList;