import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { MotifInput, MotifLabel, MotifErrorMessage, MotifTextArea, MotifButton, MotifCheckbox } from "@ey-xd/motif-react";
import MotifFormField from '@ey-xd/motif-react/FormField';
import iconError from "../../../../assets/images/error.svg"
import "./EditCentralPrompt.scss";
import { setIsValidationErrorOnAddNewPrompt, setNewCentralPromptName, setNewCentralPrompts, setNewCentralTableType, setNewCentralFullTableContext } from "../../../../auth/promptSlice";

function EditCentralPrompt ({
        centralPrompt, 
        setShowEditCentralPrompt, 
        handlePromptSave, 
        useAsAddCentralPrompt=false,
        tableTypeRefs=[]
    }) {
    const [isErrorOnPromptName, setIsErrorOnPromptName] = useState(false);
    const [isErrorOnTableType, setIsErrorOnTableType] = useState(false);
    const [errorMsgPromptName, setErrorMsgPromptName] = useState("");
    const [errorMsgTableType, setErrorMsgTableType] = useState("");
    const [promptMethods, setPromptMethods] = useState(centralPrompt?.prompts);
    const [promptName, setPromptName] = useState(centralPrompt.promptName);
    const [disableFullTableContext, setDisableFullTableContext] = useState(useAsAddCentralPrompt ? !(centralPrompt?.advancedSetting?.additionalContext[0]?.disable) : centralPrompt.fullTableContextIndicator);
    const {user} = useSelector((state) => state.user);
    const {centralPrompts} = useSelector((state) => state.prompt);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [tableType, setTableType] = useState(centralPrompt?.tableType);
    const dispatch = useDispatch();
    //  Error Messages
    const errMsgAlphaNumWithMax50Chars = "Please enter alphanumeric name, up to 50 characters.";
    const errMsgDuplicateTable = "The name is already in use. Please choose another.";

    useEffect(() => {
        dispatch(setIsValidationErrorOnAddNewPrompt({isValidationErrorOnAddNewPrompt:(isErrorOnPromptName || isErrorOnTableType)}))
        // eslint-disable-next-line
    }, [isErrorOnPromptName, isErrorOnTableType])

    const onPromptMethodChange = (event) => {
        const promptMethod = event.target.name;
        const updatedPromptBody = event.target.value;
        let updatedPromptMethods = JSON.parse(JSON.stringify(promptMethods));
        updatedPromptMethods = updatedPromptMethods.map((pm) => {
            if(pm.promptMethod === promptMethod) {
                pm.promptBody = updatedPromptBody
            }
            return pm;
        });
        setPromptMethods(updatedPromptMethods);
        dispatch(setNewCentralPrompts({prompts: updatedPromptMethods}));
    }

    const onSaveClick = () => {
        let updatedPrompt = Object.assign({}, centralPrompt);
        if(!useAsAddCentralPrompt){
            updatedPrompt.tableId = centralPrompt.id;
            updatedPrompt.tableType = tableType;
            updatedPrompt.fullTableContextIndicator = disableFullTableContext;
        };
        updatedPrompt.promptName = promptName;
        updatedPrompt.prompts = promptMethods.map((pm) => {
            return {
                promptMethod: pm.promptMethod,
                promptBody: pm.promptBody
            }
        });
        updatedPrompt.metadata = {
            "createdAt": new Date().toJSON(),
            "createdBy": user?.username
        }

        handlePromptSave(updatedPrompt);
    }

    /**
     * Function to validate prompt name field
     * @param {*} promptName 
     */
    const onPromptNameChange = (promptName) => {
        dispatch(setNewCentralPromptName({promptName: promptName}));
        setPromptName(promptName);
        promptName = promptName ? promptName.trim() : '';
        //  Check for Alphanumeric with space & maximum 50 characters validation
        const vValid = /^[a-zA-Z0-9- ]*$/.test(promptName);
        if (
            (promptName === '') ||
            !vValid ||
            (promptName.length > 50)
        ) {
            setIsErrorOnPromptName(true);
            setErrorMsgPromptName(errMsgAlphaNumWithMax50Chars);
            setSaveDisabled(true);
            return;
        }
        const filteredTable = centralPrompts?.length > 0 ? 
            (centralPrompts?.filter(prompt => (prompt.promptName.trim().toLowerCase() === promptName.trim().toLowerCase()))) : null;
        if (useAsAddCentralPrompt ? (filteredTable && filteredTable.length > 0 ) : ((promptName.trim().toLowerCase() !== centralPrompt.promptName.trim().toLowerCase()) && (filteredTable && filteredTable.length > 0))) {
            setIsErrorOnPromptName(true);
            setErrorMsgPromptName(errMsgDuplicateTable);
            setSaveDisabled(true);
            return;
        }
        setIsErrorOnPromptName(false);
        setErrorMsgPromptName("");
        setSaveDisabled(false);
    }

    /**
     * Function to validate table type field
     * @param {*} tableType 
     */
    const onTableTypeChange = (tableType) => {
        setTableType(tableType);
        dispatch(setNewCentralTableType({tableType: tableType}));
        //  Check for Alphanumeric with space & maximum 50 characters validation
        const vValid = /^[a-zA-Z0-9-/ ]*$/.test(tableType);
        if (
            (tableType === '') ||
            !vValid ||
            (tableType.length > 50)
        ) {
            setIsErrorOnTableType(true);
            setErrorMsgTableType(errMsgAlphaNumWithMax50Chars);
            setSaveDisabled(true);
            return;
        }
        const filteredTable = tableTypeRefs?.length > 0 ? 
            (tableTypeRefs?.filter(ttr => (ttr.ReferenceKey.trim().toLowerCase() === tableType.trim().toLowerCase()))) : null;
            if (useAsAddCentralPrompt? (filteredTable && filteredTable.length > 0) : ((tableType.trim().toLowerCase() !== centralPrompt.tableType.trim().toLowerCase()) && (filteredTable && filteredTable.length > 0))) {
            setIsErrorOnTableType(true);
            setErrorMsgTableType(errMsgDuplicateTable);
            setSaveDisabled(true);
            return;
        }
        setIsErrorOnTableType(false);
        setErrorMsgTableType("");
        setSaveDisabled(false);
    }

    const handleFullTableContextChange = (e) => {
        if(useAsAddCentralPrompt){
            const invertedFullTableContext = !disableFullTableContext;
            let additionalContext = JSON.parse(JSON.stringify(centralPrompt.advancedSetting.additionalContext));
            // eslint-disable-next-line
            additionalContext = additionalContext.map((context) => {
                if (context.name === "fullTable") {       
                    return {...context, disable: !e.target.checked};
                }
            });
            dispatch(setNewCentralFullTableContext({additionalContext: additionalContext}))
            setDisableFullTableContext(invertedFullTableContext);
        } else {
            setDisableFullTableContext(e.target.checked);
        }
    }

    return (
        <div className="editCentralPrompt">
            
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel'>
                        Table Type
                    </MotifLabel>
                    <MotifInput
                        onChange={(e) => onTableTypeChange(e.target.value)}
                        name="tableType"
                        type='text'
                        value={tableType}
                        className={isErrorOnTableType ? 'motif-input-invalid' : ''}
                        hideClearButton={!!isErrorOnTableType}
                    />
                    {(isErrorOnTableType) &&
                        <>
                            <img src={iconError} alt='Error' className='iconError topPosPromptName' />
                            <MotifErrorMessage className='errorText'>
                                <div>{errorMsgTableType}</div>
                            </MotifErrorMessage>
                        </>
                    }
                    {(!isErrorOnTableType) &&
                        <div className='fldDetails'>
                            Please enter alphanumeric name, up to 50 characters.
                        </div>
                    }
                </MotifFormField>
            </div>
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel'>
                        Prompt Name
                    </MotifLabel>
                    <MotifInput
                        onChange={(e) => onPromptNameChange(e.target.value)}
                        name="promptName"
                        type='text'
                        value={promptName}
                        className={isErrorOnPromptName ? 'motif-input-invalid' : ''}
                        hideClearButton={!!isErrorOnPromptName}
                    />
                    {(isErrorOnPromptName) &&
                        <>
                            <img src={iconError} alt='Error' className='iconError topPosPromptName' />
                            <MotifErrorMessage className='errorText'>
                                <div>{errorMsgPromptName}</div>
                            </MotifErrorMessage>
                        </>
                    }
                    {(!isErrorOnPromptName) &&
                        <div className='fldDetails'>
                            Please enter alphanumeric name, up to 50 characters.
                        </div>
                    }
                </MotifFormField>
            </div>
            {
                promptMethods?.map((prompt) => (
                    <div className='fldName'>
                        <MotifFormField>
                            <MotifLabel className='fldLabel'>
                                {prompt.promptMethodName}
                            </MotifLabel>
                            <MotifTextArea
                                name={prompt.promptMethod}
                                value={prompt.promptBody}   
                                onChange={(e) => onPromptMethodChange(e)} 
                            />
                            
                            <div className='fldDetails'>
                                Please enter alphanumeric text
                            </div>
                        </MotifFormField>  
                    </div>
                ))
            }
            <div className='fldName'>
                <MotifFormField>
                    <MotifLabel className='fldLabel fullTableContext'>
                        Full Table Context
                    </MotifLabel>
                    <MotifCheckbox
                        id="fullTableContext"
                        onChange={(e) => handleFullTableContextChange(e)}
                        checked={disableFullTableContext}
                    >
                        <span className="fullTableContext">Include full table as context</span>
                    </MotifCheckbox>
                </MotifFormField>
            </div>
            {
                !useAsAddCentralPrompt &&
                <div className='genericFooter'>
                    <div className='footerInner alignEnd'>
                        <MotifButton onClick={onSaveClick} className='' type="button" variant="primary" disabled={saveDisabled}>
                            Save
                        </MotifButton>
                        <MotifButton onClick={()=>{setShowEditCentralPrompt(false)}} className='' type="button" variant="secondary">
                            Cancel
                        </MotifButton>
                    </div>
                </div>
            }
        </div>
    ); 
}

EditCentralPrompt.propTypes = {
    centralPromptInput: PropTypes.object,
    setShowEditCentralPrompt: PropTypes.func,
    handlePromptSave: PropTypes.func,
    useAsAddCentralPrompt: PropTypes.bool,
    tableTypeRefs: PropTypes.array
}

export default EditCentralPrompt;