/**
 * The functional component assists in listing Documents 
 * 
 */

import React, { useEffect, useState } from "react";
import {
    MotifBadge,
    MotifToast,
    MotifButton,
    MotifCard,
    MotifCardHeader
} from "@ey-xd/motif-react";
import "./DocumentLists.scss";
import page from "../../../assets/images/page.svg";
import iconProgressLoaderCircle from "../../../assets/images/Loading_indicator_circles.svg";
import DocumentService from "../../../services/documentService";
import emptydoc from "../../../assets/images/emptydoc.svg";
import { useSelector } from "react-redux";
import refreshIcon from "../../../assets/images/refresh.svg";
import vector from "../../../assets/images/vector.svg";
import iconCheckCircle from "../../../assets/images/check-circle.svg";
export function DocumentLists() {
    let InititaData = [];
    const [documents, setDocuments] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { activeProject } = useSelector(state => state.user);
    const projectId = activeProject.project_UUID;
    const dataHostingLocation = activeProject?.dataHostingLocation;
    const documentService = new DocumentService();
    const filterDocuments = documents.filter(doc => doc.status === 'Complete' || doc.status === 'Deleted' || doc.status === 'Delete InProgress')
    const activeDocuments = documents.filter(doc => doc.status === 'Complete').length;
    const totalDocuments = filterDocuments.length;

    useEffect(() => {
        getUploadedDocuments();
        // eslint-disable-next-line
    }, []);


    /**
  * This function helps to fetch Documents
  * @param {*} refreshFlag  : Boolean
  */
    const getUploadedDocuments = async (refreshFlag) => {
        setShowLoader(true);
        InititaData = await documentService.getDocuments(projectId);
        if(InititaData){
            setDocuments(InititaData);
        }
        setShowLoader(false);
        if (refreshFlag) {
            setShowMessage(true);
        }
    };

    const refreshList = () => {
        getUploadedDocuments(true);
    };

    const platformRedirectClick = () => {
        const base = process.env.REACT_APP_DOCUMENTS_PLATFORM_URL;
        const url = base + "?p_id=" + projectId + "&dataHostingLocation=" + dataHostingLocation;
        window.open(url, '_blank');
    };

    const oncloseclick = () => {
        setShowMessage(false);
    };
    return (
        <>
            {showLoader ? (
                <div className="loaderDiv">
                    <div>
                        <img src={iconProgressLoaderCircle} alt='Progress'
                            className="refreshIcon " />
                    </div>
                    <div className="refreshListText">
                        Refreshing List...
                    </div>
                </div>
            ) : (
                <>
                    {filterDocuments.length === 0 ? (
                        <div className="no-doc-info-container">
                            <img className="no-doc-info-image"
                                src={emptydoc} alt=""></img>
                            <div>
                                <div className="no-doc-text-info">
                                    No documents found</div>
                                <p className="no-doc-description">
                                    To add documents to your project,<br />
                                    click on <span className="no-doc-bold-description">Access Document Library
                                    </span></p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <MotifCard className="appHeader">
                                <MotifCardHeader
                                    variant="light">
                                    <span className="motif-h6 listHeader">Document Library</span>
                                    <MotifBadge
                                        value={activeDocuments + "/" + totalDocuments} variant="default"
                                        className="doc-badge" />
                                </MotifCardHeader>
                            </MotifCard>
                            <div className="documentList ">
                                {filterDocuments.map((doc, i) => (
                                    <div className="doc-list" key={doc.documentId}>
                                        <div className={`document-item ${doc.status === "Deleted" ? "deleted" : "active"}`}>
                                            <div className="document-name"><img src={page} alt="Document" />{doc.documentName}</div>
                                            <div className="document-status" >
                                                {doc.status === "Complete" ? "Active" : "Deleted"}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="genericFooter">
                        <div className="footerInner alignEnd">
                            <MotifButton className="btnAccessDocument "
                                variant="primary" onClick={() => platformRedirectClick()}>
                                <img src={vector} alt="Document" className="iconAccessDocument" /> {/*
                                */}Access Document Library
                            </MotifButton>
                            <MotifButton className="btnRefreshDocument" variant="secondary"
                                onClick={() => refreshList()}>
                                <img src={refreshIcon} alt="Refresh" className="iconRefresh" />Refresh List
                            </MotifButton>

                        </div>
                    </div>

                    {showMessage && (
                        <MotifToast
                        className="notifyToast"
                        variant="success"
                        icons={{toast: <img src={iconCheckCircle} alt="Success" />}}
                        actionName=""
                        position=""
                        onClose={() => {
                          oncloseclick();
                        }}
                      >
                        <div>Refresh Complete</div>
                      </MotifToast>
                    )}
                </>
            )};
        </>
    );
}

export default DocumentLists;









