import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import msalInstance from '../../../auth/msalInstance';
import { setUser } from '../../../auth/userSlice';

const AuthCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleAuthResponse = () => {
            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                msalInstance.setActiveAccount(account);
                dispatch(setUser({
                    user: account
                }));
                navigate('/'); // Redirect to the home page or the original requested page
            } else {
                navigate('/signin-oidc'); // Redirect to sign-in page if no account is found
            }
        };

        handleAuthResponse();
    }, [dispatch, navigate]);
};

export default AuthCallback;
