/**
 * AI Guidance modal
* Created On: 23-10-2024
 */
import React from 'react';
import PropTypes from 'prop-types';

//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifTextLink } from "@ey-xd/motif-react";

import iconAIGuidance from '../../../../assets/images/ai_guidance.svg';

import './AIGuidance.scss';

const AIGuidance = ({
    openModal,
    setOpenModal,
    closeModal
}) => {

    return (
        <MotifModal
            show={openModal}
            onClose={() => setOpenModal(!openModal)}
            focusTrapOptions={{
              tabbableOptions: {
                displayCheck: "none",
              },
            }}
            className='aiGuidanceModal genericModal modalNoHeaderBorder startIconClose'
            size='sm'
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
                className='aiGuidanceHeader'
            >
                <img src={iconAIGuidance} alt='AI Guidance' />
                <div className='aiGuidanceHead' data-testid='testIdTitle'>AI Guidance</div>
            </MotifModalHeader>
            <MotifModalBody className='aiGuidanceBody'>
                Please always be mindful of your obligations as an EY Professional and use Digital Diligence Assistant responsibly in line with the <MotifTextLink
                    href="https://www.ey.com/en_gl/insights/ai/principles-for-ethical-and-responsible-ai"
                    variant="alt"
                    className='linkAIGuidance linkInline'
                >
                    EY's commitment to ethical and responsible AI principles | EY - Global
                </MotifTextLink>, <MotifTextLink
                    href="https://www.microsoft.com/licensing/terms/product/ForOnlineServices/all"
                    variant="alt"
                    className='linkAIGuidance linkInline'
                >
                    Microsoft Acceptable Use Policy
                </MotifTextLink>, <MotifTextLink
                    href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/code-of-conduct"
                    variant="alt"
                    className='linkAIGuidance linkInline'
                >
                    Microsoft Code of Conduct for OpenAI Services
                </MotifTextLink> and the <MotifTextLink
                    href='https://discovercontent.ey.net/pod24022wgsjejjr'
                    variant="alt"
                    className='linkAIGuidance linkInline'
                >
                    Engagement Management Guidance
                </MotifTextLink>. Always make sure that you (a) have the required rights and permissions to use data with Digital Diligence Assistant and (b) validate any outputs prior to its use. Use of Digital Diligence Assistant must be in line with SaT Service Line requirements as outlined in the <MotifTextLink
                    href='https://discovercontent.ey.net/pod24022wgsjejjr'
                    variant="alt"
                    className='linkAIGuidance linkInline'
                >
                    Engagement Management Guidance
                </MotifTextLink> and any EY work product using such output must be reviewed for accuracy and validity and follow all country and/or SaT Service Line specific quality processes and guidelines.
            </MotifModalBody>
        </MotifModal>
    );
};

AIGuidance.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    closeModal: PropTypes.func
};

export default AIGuidance;